/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedReviewRequest
 */
export interface PatchedReviewRequest {
  /**
   *
   * @type {number}
   * @memberof PatchedReviewRequest
   */
  rating?: number
  /**
   *
   * @type {string}
   * @memberof PatchedReviewRequest
   */
  author?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedReviewRequest
   */
  publishedAt?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedReviewRequest
   */
  isPublished?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedReviewRequest
   */
  text?: string | null
  /**
   *
   * @type {number}
   * @memberof PatchedReviewRequest
   */
  serviceId?: number | null
}

/**
 * Check if a given object implements the PatchedReviewRequest interface.
 */
export function instanceOfPatchedReviewRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedReviewRequestFromJSON(json: any): PatchedReviewRequest {
  return PatchedReviewRequestFromJSONTyped(json, false)
}

export function PatchedReviewRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedReviewRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    rating: !exists(json, 'rating') ? undefined : json['rating'],
    author: !exists(json, 'author') ? undefined : json['author'],
    publishedAt: !exists(json, 'publishedAt') ? undefined : json['publishedAt'],
    isPublished: !exists(json, 'isPublished') ? undefined : json['isPublished'],
    text: !exists(json, 'text') ? undefined : json['text'],
    serviceId: !exists(json, 'serviceId') ? undefined : json['serviceId'],
  }
}

export function PatchedReviewRequestToJSON(value?: PatchedReviewRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    rating: value.rating,
    author: value.author,
    publishedAt: value.publishedAt,
    isPublished: value.isPublished,
    text: value.text,
    serviceId: value.serviceId,
  }
}
