/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ContentAdmin } from './ContentAdmin'
import {
  ContentAdminFromJSON,
  ContentAdminFromJSONTyped,
  ContentAdminToJSON,
} from './ContentAdmin'

/**
 *
 * @export
 * @interface PaginatedContentAdmin
 */
export interface PaginatedContentAdmin {
  /**
   *
   * @type {Array<ContentAdmin>}
   * @memberof PaginatedContentAdmin
   */
  results: Array<ContentAdmin>
  /**
   *
   * @type {number}
   * @memberof PaginatedContentAdmin
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedContentAdmin
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedContentAdmin
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedContentAdmin interface.
 */
export function instanceOfPaginatedContentAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedContentAdminFromJSON(json: any): PaginatedContentAdmin {
  return PaginatedContentAdminFromJSONTyped(json, false)
}

export function PaginatedContentAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedContentAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(ContentAdminFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedContentAdminToJSON(value?: PaginatedContentAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(ContentAdminToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
