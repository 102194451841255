/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FAQUpdateRequest } from './FAQUpdateRequest'
import {
  FAQUpdateRequestFromJSON,
  FAQUpdateRequestFromJSONTyped,
  FAQUpdateRequestToJSON,
} from './FAQUpdateRequest'

/**
 *
 * @export
 * @interface PatchedFAQUpdateListRequest
 */
export interface PatchedFAQUpdateListRequest {
  /**
   *
   * @type {Array<FAQUpdateRequest>}
   * @memberof PatchedFAQUpdateListRequest
   */
  faqs?: Array<FAQUpdateRequest>
  /**
   * * `global` - Global
   * * `local` - Local
   * * `both` - Both
   * @type {string}
   * @memberof PatchedFAQUpdateListRequest
   */
  faqUsageType?: PatchedFAQUpdateListRequestFaqUsageTypeEnum
  /**
   *
   * @type {number}
   * @memberof PatchedFAQUpdateListRequest
   */
  entityId?: number | null
  /**
   * * `category` - Category
   * * `object` - Object
   * * `service` - Service
   * * `static_page` - Static Page
   * @type {string}
   * @memberof PatchedFAQUpdateListRequest
   */
  entityType?: PatchedFAQUpdateListRequestEntityTypeEnum
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof PatchedFAQUpdateListRequest
   */
  serviceTemplateType?: PatchedFAQUpdateListRequestServiceTemplateTypeEnum
  /**
   *
   * @type {number}
   * @memberof PatchedFAQUpdateListRequest
   */
  regionId?: number
}

/**
 * @export
 */
export const PatchedFAQUpdateListRequestFaqUsageTypeEnum = {
  Global: 'global',
  Local: 'local',
  Both: 'both',
} as const
export type PatchedFAQUpdateListRequestFaqUsageTypeEnum =
  (typeof PatchedFAQUpdateListRequestFaqUsageTypeEnum)[keyof typeof PatchedFAQUpdateListRequestFaqUsageTypeEnum]

/**
 * @export
 */
export const PatchedFAQUpdateListRequestEntityTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type PatchedFAQUpdateListRequestEntityTypeEnum =
  (typeof PatchedFAQUpdateListRequestEntityTypeEnum)[keyof typeof PatchedFAQUpdateListRequestEntityTypeEnum]

/**
 * @export
 */
export const PatchedFAQUpdateListRequestServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type PatchedFAQUpdateListRequestServiceTemplateTypeEnum =
  (typeof PatchedFAQUpdateListRequestServiceTemplateTypeEnum)[keyof typeof PatchedFAQUpdateListRequestServiceTemplateTypeEnum]

/**
 * Check if a given object implements the PatchedFAQUpdateListRequest interface.
 */
export function instanceOfPatchedFAQUpdateListRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedFAQUpdateListRequestFromJSON(json: any): PatchedFAQUpdateListRequest {
  return PatchedFAQUpdateListRequestFromJSONTyped(json, false)
}

export function PatchedFAQUpdateListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedFAQUpdateListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    faqs: !exists(json, 'faqs')
      ? undefined
      : (json['faqs'] as Array<any>).map(FAQUpdateRequestFromJSON),
    faqUsageType: !exists(json, 'faqUsageType') ? undefined : json['faqUsageType'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    entityType: !exists(json, 'entityType') ? undefined : json['entityType'],
    serviceTemplateType: !exists(json, 'serviceTemplateType')
      ? undefined
      : json['serviceTemplateType'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
  }
}

export function PatchedFAQUpdateListRequestToJSON(
  value?: PatchedFAQUpdateListRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    faqs:
      value.faqs === undefined
        ? undefined
        : (value.faqs as Array<any>).map(FAQUpdateRequestToJSON),
    faqUsageType: value.faqUsageType,
    entityId: value.entityId,
    entityType: value.entityType,
    serviceTemplateType: value.serviceTemplateType,
    regionId: value.regionId,
  }
}
