/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  EnvelopedDocumentDocumentDetailPublic,
  EnvelopedDocumentListDocumentPublic,
} from '../models'
import {
  EnvelopedDocumentDocumentDetailPublicFromJSON,
  EnvelopedDocumentDocumentDetailPublicToJSON,
  EnvelopedDocumentListDocumentPublicFromJSON,
  EnvelopedDocumentListDocumentPublicToJSON,
} from '../models'

export interface DocumentListRequest {
  page?: number
  size?: number
}

export interface DocumentRetrieveRequest {
  slug: string
}

/**
 *
 */
export class DocumentApi extends runtime.BaseAPI {
  /**
   */
  async documentListRaw(
    requestParameters: DocumentListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentListDocumentPublic>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/document/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentListDocumentPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async documentList(
    requestParameters: DocumentListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentListDocumentPublic> {
    const response = await this.documentListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async documentRetrieveRaw(
    requestParameters: DocumentRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentDocumentDetailPublic>> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
      throw new runtime.RequiredError(
        'slug',
        'Required parameter requestParameters.slug was null or undefined when calling documentRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/document/{slug}/`.replace(
          `{${'slug'}}`,
          encodeURIComponent(String(requestParameters.slug)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentDocumentDetailPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async documentRetrieve(
    requestParameters: DocumentRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentDocumentDetailPublic> {
    const response = await this.documentRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
