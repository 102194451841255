/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { CatalogLocationSeoFieldsAdmin } from './CatalogLocationSeoFieldsAdmin'
import {
  CatalogLocationSeoFieldsAdminFromJSON,
  CatalogLocationSeoFieldsAdminFromJSONTyped,
  CatalogLocationSeoFieldsAdminToJSON,
} from './CatalogLocationSeoFieldsAdmin'

/**
 *
 * @export
 * @interface EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin
 */
export interface EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin {
  /**
   *
   * @type {CatalogLocationSeoFieldsAdmin}
   * @memberof EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin
   */
  body: CatalogLocationSeoFieldsAdmin
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin interface.
 */
export function instanceOfEnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminFromJSON(
  json: any,
): EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin {
  return EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminFromJSONTyped(json, false)
}

export function EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: CatalogLocationSeoFieldsAdminFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminToJSON(
  value?: EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: CatalogLocationSeoFieldsAdminToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
