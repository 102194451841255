/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Status
 */
export interface Status {
  /**
   *
   * @type {string}
   * @memberof Status
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof Status
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof Status
   */
  doneAt: string
}

/**
 * Check if a given object implements the Status interface.
 */
export function instanceOfStatus(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'createdAt' in value
  isInstance = isInstance && 'doneAt' in value

  return isInstance
}

export function StatusFromJSON(json: any): Status {
  return StatusFromJSONTyped(json, false)
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    createdAt: json['createdAt'],
    doneAt: json['doneAt'],
  }
}

export function StatusToJSON(value?: Status | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    createdAt: value.createdAt,
    doneAt: value.doneAt,
  }
}
