/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PortfolioWorkRequest } from './PortfolioWorkRequest'
import {
  PortfolioWorkRequestFromJSON,
  PortfolioWorkRequestFromJSONTyped,
  PortfolioWorkRequestToJSON,
} from './PortfolioWorkRequest'

/**
 *
 * @export
 * @interface CustomContentJSONRequest
 */
export interface CustomContentJSONRequest {
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  block1?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  block2?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  block3?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  subheader?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  inn?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  phone?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  ogrn?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  address?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  email?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  subtitle?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  tabs?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  text?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  comment?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  link?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  channelLink?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  formSubtitle?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  formTitle?: string | null
  /**
   *
   * @type {string}
   * @memberof CustomContentJSONRequest
   */
  portfolioIds?: string | null
  /**
   *
   * @type {Array<PortfolioWorkRequest>}
   * @memberof CustomContentJSONRequest
   */
  portfolios?: Array<PortfolioWorkRequest> | null
}

/**
 * Check if a given object implements the CustomContentJSONRequest interface.
 */
export function instanceOfCustomContentJSONRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CustomContentJSONRequestFromJSON(json: any): CustomContentJSONRequest {
  return CustomContentJSONRequestFromJSONTyped(json, false)
}

export function CustomContentJSONRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CustomContentJSONRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    block1: !exists(json, 'block1') ? undefined : json['block1'],
    block2: !exists(json, 'block2') ? undefined : json['block2'],
    block3: !exists(json, 'block3') ? undefined : json['block3'],
    header: !exists(json, 'header') ? undefined : json['header'],
    subheader: !exists(json, 'subheader') ? undefined : json['subheader'],
    name: !exists(json, 'name') ? undefined : json['name'],
    inn: !exists(json, 'inn') ? undefined : json['inn'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    ogrn: !exists(json, 'ogrn') ? undefined : json['ogrn'],
    address: !exists(json, 'address') ? undefined : json['address'],
    email: !exists(json, 'email') ? undefined : json['email'],
    title: !exists(json, 'title') ? undefined : json['title'],
    subtitle: !exists(json, 'subtitle') ? undefined : json['subtitle'],
    tabs: !exists(json, 'tabs') ? undefined : json['tabs'],
    text: !exists(json, 'text') ? undefined : json['text'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    link: !exists(json, 'link') ? undefined : json['link'],
    channelLink: !exists(json, 'channelLink') ? undefined : json['channelLink'],
    formSubtitle: !exists(json, 'formSubtitle') ? undefined : json['formSubtitle'],
    formTitle: !exists(json, 'formTitle') ? undefined : json['formTitle'],
    portfolioIds: !exists(json, 'portfolioIds') ? undefined : json['portfolioIds'],
    portfolios: !exists(json, 'portfolios')
      ? undefined
      : json['portfolios'] === null
      ? null
      : (json['portfolios'] as Array<any>).map(PortfolioWorkRequestFromJSON),
  }
}

export function CustomContentJSONRequestToJSON(value?: CustomContentJSONRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    block1: value.block1,
    block2: value.block2,
    block3: value.block3,
    header: value.header,
    subheader: value.subheader,
    name: value.name,
    inn: value.inn,
    phone: value.phone,
    ogrn: value.ogrn,
    address: value.address,
    email: value.email,
    title: value.title,
    subtitle: value.subtitle,
    tabs: value.tabs,
    text: value.text,
    comment: value.comment,
    link: value.link,
    channelLink: value.channelLink,
    formSubtitle: value.formSubtitle,
    formTitle: value.formTitle,
    portfolioIds: value.portfolioIds,
    portfolios:
      value.portfolios === undefined
        ? undefined
        : value.portfolios === null
        ? null
        : (value.portfolios as Array<any>).map(PortfolioWorkRequestToJSON),
  }
}
