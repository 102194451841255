/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { TokenRefresh } from './TokenRefresh'
import {
  TokenRefreshFromJSON,
  TokenRefreshFromJSONTyped,
  TokenRefreshToJSON,
} from './TokenRefresh'

/**
 *
 * @export
 * @interface EnvelopedTokenRefresh
 */
export interface EnvelopedTokenRefresh {
  /**
   *
   * @type {TokenRefresh}
   * @memberof EnvelopedTokenRefresh
   */
  body: TokenRefresh
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedTokenRefresh
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedTokenRefresh interface.
 */
export function instanceOfEnvelopedTokenRefresh(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedTokenRefreshFromJSON(json: any): EnvelopedTokenRefresh {
  return EnvelopedTokenRefreshFromJSONTyped(json, false)
}

export function EnvelopedTokenRefreshFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedTokenRefresh {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: TokenRefreshFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedTokenRefreshToJSON(value?: EnvelopedTokenRefresh | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: TokenRefreshToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
