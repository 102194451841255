/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ObjectSeoFields
 */
export interface ObjectSeoFields {
  /**
   *
   * @type {string}
   * @memberof ObjectSeoFields
   */
  headerMain?: string | null
  /**
   *
   * @type {string}
   * @memberof ObjectSeoFields
   */
  titleTagMain?: string | null
  /**
   *
   * @type {string}
   * @memberof ObjectSeoFields
   */
  descriptionTagMain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ObjectSeoFields
   */
  useLocalMetaMain?: boolean
}

/**
 * Check if a given object implements the ObjectSeoFields interface.
 */
export function instanceOfObjectSeoFields(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ObjectSeoFieldsFromJSON(json: any): ObjectSeoFields {
  return ObjectSeoFieldsFromJSONTyped(json, false)
}

export function ObjectSeoFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ObjectSeoFields {
  if (json === undefined || json === null) {
    return json
  }
  return {
    headerMain: !exists(json, 'headerMain') ? undefined : json['headerMain'],
    titleTagMain: !exists(json, 'titleTagMain') ? undefined : json['titleTagMain'],
    descriptionTagMain: !exists(json, 'descriptionTagMain')
      ? undefined
      : json['descriptionTagMain'],
    useLocalMetaMain: !exists(json, 'useLocalMetaMain') ? undefined : json['useLocalMetaMain'],
  }
}

export function ObjectSeoFieldsToJSON(value?: ObjectSeoFields | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    headerMain: value.headerMain,
    titleTagMain: value.titleTagMain,
    descriptionTagMain: value.descriptionTagMain,
    useLocalMetaMain: value.useLocalMetaMain,
  }
}
