/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PaginatedPortfolioWorkGalleryListPublic } from './PaginatedPortfolioWorkGalleryListPublic'
import {
  PaginatedPortfolioWorkGalleryListPublicFromJSON,
  PaginatedPortfolioWorkGalleryListPublicFromJSONTyped,
  PaginatedPortfolioWorkGalleryListPublicToJSON,
} from './PaginatedPortfolioWorkGalleryListPublic'

/**
 *
 * @export
 * @interface EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic
 */
export interface EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic {
  /**
   *
   * @type {PaginatedPortfolioWorkGalleryListPublic}
   * @memberof EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic
   */
  body: PaginatedPortfolioWorkGalleryListPublic
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic interface.
 */
export function instanceOfEnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicFromJSON(
  json: any,
): EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic {
  return EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicFromJSONTyped(json, false)
}

export function EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PaginatedPortfolioWorkGalleryListPublicFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicToJSON(
  value?: EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PaginatedPortfolioWorkGalleryListPublicToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
