/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CategoryRequest
 */
export interface CategoryRequest {
  /**
   *
   * @type {string}
   * @memberof CategoryRequest
   */
  name?: string
  /**
   *
   * @type {Blob}
   * @memberof CategoryRequest
   */
  iconImg?: Blob | null
  /**
   *
   * @type {Blob}
   * @memberof CategoryRequest
   */
  backgroundImg?: Blob | null
  /**
   *
   * @type {number}
   * @memberof CategoryRequest
   */
  tagId?: number | null
}

/**
 * Check if a given object implements the CategoryRequest interface.
 */
export function instanceOfCategoryRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CategoryRequestFromJSON(json: any): CategoryRequest {
  return CategoryRequestFromJSONTyped(json, false)
}

export function CategoryRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategoryRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    backgroundImg: !exists(json, 'backgroundImg') ? undefined : json['backgroundImg'],
    tagId: !exists(json, 'tagId') ? undefined : json['tagId'],
  }
}

export function CategoryRequestToJSON(value?: CategoryRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    backgroundImg: value.backgroundImg,
    tagId: value.tagId,
  }
}
