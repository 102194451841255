/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedCategoryUpdateRequest
 */
export interface PatchedCategoryUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedCategoryUpdateRequest
   */
  name?: string
  /**
   *
   * @type {Blob}
   * @memberof PatchedCategoryUpdateRequest
   */
  iconImg?: Blob | null
  /**
   *
   * @type {number}
   * @memberof PatchedCategoryUpdateRequest
   */
  regionId?: number
  /**
   *
   * @type {Blob}
   * @memberof PatchedCategoryUpdateRequest
   */
  backgroundImg?: Blob | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedCategoryUpdateRequest
   */
  isVisible?: boolean | null
  /**
   *
   * @type {number}
   * @memberof PatchedCategoryUpdateRequest
   */
  tagId?: number | null
  /**
   *
   * @type {string}
   * @memberof PatchedCategoryUpdateRequest
   */
  slug?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedCategoryUpdateRequest
   */
  headerMain?: string
  /**
   *
   * @type {string}
   * @memberof PatchedCategoryUpdateRequest
   */
  titleTagMain?: string
  /**
   *
   * @type {string}
   * @memberof PatchedCategoryUpdateRequest
   */
  descriptionTagMain?: string
  /**
   *
   * @type {boolean}
   * @memberof PatchedCategoryUpdateRequest
   */
  useLocalMetaMain?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedCategoryUpdateRequest
   */
  descriptionMain?: string
  /**
   *
   * @type {boolean}
   * @memberof PatchedCategoryUpdateRequest
   */
  useLocalDescriptionMain?: boolean
}

/**
 * Check if a given object implements the PatchedCategoryUpdateRequest interface.
 */
export function instanceOfPatchedCategoryUpdateRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedCategoryUpdateRequestFromJSON(json: any): PatchedCategoryUpdateRequest {
  return PatchedCategoryUpdateRequestFromJSONTyped(json, false)
}

export function PatchedCategoryUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedCategoryUpdateRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    backgroundImg: !exists(json, 'backgroundImg') ? undefined : json['backgroundImg'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    tagId: !exists(json, 'tagId') ? undefined : json['tagId'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    headerMain: !exists(json, 'headerMain') ? undefined : json['headerMain'],
    titleTagMain: !exists(json, 'titleTagMain') ? undefined : json['titleTagMain'],
    descriptionTagMain: !exists(json, 'descriptionTagMain')
      ? undefined
      : json['descriptionTagMain'],
    useLocalMetaMain: !exists(json, 'useLocalMetaMain') ? undefined : json['useLocalMetaMain'],
    descriptionMain: !exists(json, 'descriptionMain') ? undefined : json['descriptionMain'],
    useLocalDescriptionMain: !exists(json, 'useLocalDescriptionMain')
      ? undefined
      : json['useLocalDescriptionMain'],
  }
}

export function PatchedCategoryUpdateRequestToJSON(
  value?: PatchedCategoryUpdateRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    regionId: value.regionId,
    backgroundImg: value.backgroundImg,
    isVisible: value.isVisible,
    tagId: value.tagId,
    slug: value.slug,
    headerMain: value.headerMain,
    titleTagMain: value.titleTagMain,
    descriptionTagMain: value.descriptionTagMain,
    useLocalMetaMain: value.useLocalMetaMain,
    descriptionMain: value.descriptionMain,
    useLocalDescriptionMain: value.useLocalDescriptionMain,
  }
}
