/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { ServiceDetailUser } from './ServiceDetailUser'
import {
  ServiceDetailUserFromJSON,
  ServiceDetailUserFromJSONTyped,
  ServiceDetailUserToJSON,
} from './ServiceDetailUser'

/**
 *
 * @export
 * @interface EnvelopedServiceServiceDetailUser
 */
export interface EnvelopedServiceServiceDetailUser {
  /**
   *
   * @type {ServiceDetailUser}
   * @memberof EnvelopedServiceServiceDetailUser
   */
  body: ServiceDetailUser
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedServiceServiceDetailUser
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedServiceServiceDetailUser interface.
 */
export function instanceOfEnvelopedServiceServiceDetailUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedServiceServiceDetailUserFromJSON(
  json: any,
): EnvelopedServiceServiceDetailUser {
  return EnvelopedServiceServiceDetailUserFromJSONTyped(json, false)
}

export function EnvelopedServiceServiceDetailUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedServiceServiceDetailUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: ServiceDetailUserFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedServiceServiceDetailUserToJSON(
  value?: EnvelopedServiceServiceDetailUser | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: ServiceDetailUserToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
