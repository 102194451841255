/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Calculator } from './Calculator'
import { CalculatorFromJSON, CalculatorFromJSONTyped, CalculatorToJSON } from './Calculator'
import type { Content } from './Content'
import { ContentFromJSON, ContentFromJSONTyped, ContentToJSON } from './Content'
import type { FAQ } from './FAQ'
import { FAQFromJSON, FAQFromJSONTyped, FAQToJSON } from './FAQ'
import type { ServiceSeoFields } from './ServiceSeoFields'
import {
  ServiceSeoFieldsFromJSON,
  ServiceSeoFieldsFromJSONTyped,
  ServiceSeoFieldsToJSON,
} from './ServiceSeoFields'
import type { SimpleReview } from './SimpleReview'
import {
  SimpleReviewFromJSON,
  SimpleReviewFromJSONTyped,
  SimpleReviewToJSON,
} from './SimpleReview'

/**
 *
 * @export
 * @interface StaticPageNestedService
 */
export interface StaticPageNestedService {
  /**
   *
   * @type {number}
   * @memberof StaticPageNestedService
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof StaticPageNestedService
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof StaticPageNestedService
   */
  price?: number | null
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof StaticPageNestedService
   */
  currentTemplate: StaticPageNestedServiceCurrentTemplateEnum
  /**
   *
   * @type {string}
   * @memberof StaticPageNestedService
   */
  measureUnit?: string | null
  /**
   *
   * @type {string}
   * @memberof StaticPageNestedService
   */
  slug?: string | null
  /**
   *
   * @type {Array<Content>}
   * @memberof StaticPageNestedService
   */
  readonly content: Array<Content> | null
  /**
   *
   * @type {ServiceSeoFields}
   * @memberof StaticPageNestedService
   */
  readonly seoData: ServiceSeoFields | null
  /**
   *
   * @type {Array<FAQ>}
   * @memberof StaticPageNestedService
   */
  readonly faq: Array<FAQ> | null
  /**
   *
   * @type {Calculator}
   * @memberof StaticPageNestedService
   */
  readonly calculator: Calculator | null
  /**
   *
   * @type {Array<SimpleReview>}
   * @memberof StaticPageNestedService
   */
  readonly review: Array<SimpleReview> | null
}

/**
 * @export
 */
export const StaticPageNestedServiceCurrentTemplateEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type StaticPageNestedServiceCurrentTemplateEnum =
  (typeof StaticPageNestedServiceCurrentTemplateEnum)[keyof typeof StaticPageNestedServiceCurrentTemplateEnum]

/**
 * Check if a given object implements the StaticPageNestedService interface.
 */
export function instanceOfStaticPageNestedService(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'currentTemplate' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'faq' in value
  isInstance = isInstance && 'calculator' in value
  isInstance = isInstance && 'review' in value

  return isInstance
}

export function StaticPageNestedServiceFromJSON(json: any): StaticPageNestedService {
  return StaticPageNestedServiceFromJSONTyped(json, false)
}

export function StaticPageNestedServiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StaticPageNestedService {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    currentTemplate: json['currentTemplate'],
    measureUnit: !exists(json, 'measureUnit') ? undefined : json['measureUnit'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    content:
      json['content'] === null ? null : (json['content'] as Array<any>).map(ContentFromJSON),
    seoData: ServiceSeoFieldsFromJSON(json['seoData']),
    faq: json['faq'] === null ? null : (json['faq'] as Array<any>).map(FAQFromJSON),
    calculator: CalculatorFromJSON(json['calculator']),
    review:
      json['review'] === null ? null : (json['review'] as Array<any>).map(SimpleReviewFromJSON),
  }
}

export function StaticPageNestedServiceToJSON(value?: StaticPageNestedService | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    price: value.price,
    currentTemplate: value.currentTemplate,
    measureUnit: value.measureUnit,
    slug: value.slug,
  }
}
