/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Calculator } from './Calculator'
import { CalculatorFromJSON, CalculatorFromJSONTyped, CalculatorToJSON } from './Calculator'

/**
 *
 * @export
 * @interface SeoDefaults
 */
export interface SeoDefaults {
  /**
   *
   * @type {number}
   * @memberof SeoDefaults
   */
  readonly id: number
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof SeoDefaults
   */
  readonly serviceTemplateType: SeoDefaultsServiceTemplateTypeEnum
  /**
   * * `category` - Category
   * * `object` - Object
   * * `service` - Service
   * @type {string}
   * @memberof SeoDefaults
   */
  readonly entityType: SeoDefaultsEntityTypeEnum
  /**
   * * `main` - Main
   * * `location` - Location
   * @type {string}
   * @memberof SeoDefaults
   */
  readonly seoType: SeoDefaultsSeoTypeEnum
  /**
   *
   * @type {string}
   * @memberof SeoDefaults
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof SeoDefaults
   */
  titleTag?: string | null
  /**
   *
   * @type {string}
   * @memberof SeoDefaults
   */
  descriptionTag?: string | null
  /**
   *
   * @type {string}
   * @memberof SeoDefaults
   */
  description?: string | null
  /**
   *
   * @type {Calculator}
   * @memberof SeoDefaults
   */
  readonly calculator: Calculator
}

/**
 * @export
 */
export const SeoDefaultsServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type SeoDefaultsServiceTemplateTypeEnum =
  (typeof SeoDefaultsServiceTemplateTypeEnum)[keyof typeof SeoDefaultsServiceTemplateTypeEnum]

/**
 * @export
 */
export const SeoDefaultsEntityTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
} as const
export type SeoDefaultsEntityTypeEnum =
  (typeof SeoDefaultsEntityTypeEnum)[keyof typeof SeoDefaultsEntityTypeEnum]

/**
 * @export
 */
export const SeoDefaultsSeoTypeEnum = {
  Main: 'main',
  Location: 'location',
} as const
export type SeoDefaultsSeoTypeEnum =
  (typeof SeoDefaultsSeoTypeEnum)[keyof typeof SeoDefaultsSeoTypeEnum]

/**
 * Check if a given object implements the SeoDefaults interface.
 */
export function instanceOfSeoDefaults(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'serviceTemplateType' in value
  isInstance = isInstance && 'entityType' in value
  isInstance = isInstance && 'seoType' in value
  isInstance = isInstance && 'calculator' in value

  return isInstance
}

export function SeoDefaultsFromJSON(json: any): SeoDefaults {
  return SeoDefaultsFromJSONTyped(json, false)
}

export function SeoDefaultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoDefaults {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    serviceTemplateType: json['serviceTemplateType'],
    entityType: json['entityType'],
    seoType: json['seoType'],
    header: !exists(json, 'header') ? undefined : json['header'],
    titleTag: !exists(json, 'titleTag') ? undefined : json['titleTag'],
    descriptionTag: !exists(json, 'descriptionTag') ? undefined : json['descriptionTag'],
    description: !exists(json, 'description') ? undefined : json['description'],
    calculator: CalculatorFromJSON(json['calculator']),
  }
}

export function SeoDefaultsToJSON(value?: SeoDefaults | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    header: value.header,
    titleTag: value.titleTag,
    descriptionTag: value.descriptionTag,
    description: value.description,
  }
}
