/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  executionDt?: string
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  preferredDtFrom?: string
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  preferredDtTo?: string
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  customerEmail?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  customerName?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  customerPhone: string
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  commentary?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  addressMain?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  addressAdditional?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  serviceId?: number
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  regionId?: number
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  masterId?: number
  /**
   *
   * @type {Array<Blob>}
   * @memberof OrderRequest
   */
  photos?: Array<Blob>
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  recaptchaToken: string
}

/**
 * Check if a given object implements the OrderRequest interface.
 */
export function instanceOfOrderRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'customerPhone' in value
  isInstance = isInstance && 'recaptchaToken' in value

  return isInstance
}

export function OrderRequestFromJSON(json: any): OrderRequest {
  return OrderRequestFromJSONTyped(json, false)
}

export function OrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    executionDt: !exists(json, 'executionDt') ? undefined : json['executionDt'],
    preferredDtFrom: !exists(json, 'preferredDtFrom') ? undefined : json['preferredDtFrom'],
    preferredDtTo: !exists(json, 'preferredDtTo') ? undefined : json['preferredDtTo'],
    customerEmail: !exists(json, 'customerEmail') ? undefined : json['customerEmail'],
    customerName: !exists(json, 'customerName') ? undefined : json['customerName'],
    customerPhone: json['customerPhone'],
    commentary: !exists(json, 'commentary') ? undefined : json['commentary'],
    addressMain: !exists(json, 'addressMain') ? undefined : json['addressMain'],
    addressAdditional: !exists(json, 'addressAdditional') ? undefined : json['addressAdditional'],
    serviceId: !exists(json, 'serviceId') ? undefined : json['serviceId'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    masterId: !exists(json, 'masterId') ? undefined : json['masterId'],
    photos: !exists(json, 'photos') ? undefined : json['photos'],
    recaptchaToken: json['recaptchaToken'],
  }
}

export function OrderRequestToJSON(value?: OrderRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    executionDt: value.executionDt,
    preferredDtFrom: value.preferredDtFrom,
    preferredDtTo: value.preferredDtTo,
    customerEmail: value.customerEmail,
    customerName: value.customerName,
    customerPhone: value.customerPhone,
    commentary: value.commentary,
    addressMain: value.addressMain,
    addressAdditional: value.addressAdditional,
    serviceId: value.serviceId,
    regionId: value.regionId,
    masterId: value.masterId,
    photos: value.photos,
    recaptchaToken: value.recaptchaToken,
  }
}
