/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Content } from './Content'
import { ContentFromJSON, ContentFromJSONTyped, ContentToJSON } from './Content'
import type { FAQ } from './FAQ'
import { FAQFromJSON, FAQFromJSONTyped, FAQToJSON } from './FAQ'
import type { ObjectCategory } from './ObjectCategory'
import {
  ObjectCategoryFromJSON,
  ObjectCategoryFromJSONTyped,
  ObjectCategoryToJSON,
} from './ObjectCategory'
import type { ObjectLocationSeoDataNested } from './ObjectLocationSeoDataNested'
import {
  ObjectLocationSeoDataNestedFromJSON,
  ObjectLocationSeoDataNestedFromJSONTyped,
  ObjectLocationSeoDataNestedToJSON,
} from './ObjectLocationSeoDataNested'
import type { ObjectSeoFields } from './ObjectSeoFields'
import {
  ObjectSeoFieldsFromJSON,
  ObjectSeoFieldsFromJSONTyped,
  ObjectSeoFieldsToJSON,
} from './ObjectSeoFields'
import type { ObjectServices } from './ObjectServices'
import {
  ObjectServicesFromJSON,
  ObjectServicesFromJSONTyped,
  ObjectServicesToJSON,
} from './ObjectServices'

/**
 *
 * @export
 * @interface ObjectDetailUser
 */
export interface ObjectDetailUser {
  /**
   *
   * @type {number}
   * @memberof ObjectDetailUser
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ObjectDetailUser
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ObjectDetailUser
   */
  iconImg?: string | null
  /**
   *
   * @type {string}
   * @memberof ObjectDetailUser
   */
  readonly pageType: string
  /**
   *
   * @type {boolean}
   * @memberof ObjectDetailUser
   */
  isVisible?: boolean | null
  /**
   *
   * @type {string}
   * @memberof ObjectDetailUser
   */
  slug?: string | null
  /**
   *
   * @type {ObjectSeoFields}
   * @memberof ObjectDetailUser
   */
  readonly seoData: ObjectSeoFields | null
  /**
   *
   * @type {ObjectLocationSeoDataNested}
   * @memberof ObjectDetailUser
   */
  readonly locationSeoData: ObjectLocationSeoDataNested | null
  /**
   *
   * @type {ObjectCategory}
   * @memberof ObjectDetailUser
   */
  readonly category: ObjectCategory
  /**
   *
   * @type {Array<ObjectServices>}
   * @memberof ObjectDetailUser
   */
  readonly services: Array<ObjectServices>
  /**
   *
   * @type {Array<Content>}
   * @memberof ObjectDetailUser
   */
  readonly content: Array<Content> | null
  /**
   *
   * @type {Array<FAQ>}
   * @memberof ObjectDetailUser
   */
  readonly faq: Array<FAQ> | null
}

/**
 * Check if a given object implements the ObjectDetailUser interface.
 */
export function instanceOfObjectDetailUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'pageType' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'locationSeoData' in value
  isInstance = isInstance && 'category' in value
  isInstance = isInstance && 'services' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'faq' in value

  return isInstance
}

export function ObjectDetailUserFromJSON(json: any): ObjectDetailUser {
  return ObjectDetailUserFromJSONTyped(json, false)
}

export function ObjectDetailUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ObjectDetailUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    pageType: json['pageType'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    seoData: ObjectSeoFieldsFromJSON(json['seoData']),
    locationSeoData: ObjectLocationSeoDataNestedFromJSON(json['locationSeoData']),
    category: ObjectCategoryFromJSON(json['category']),
    services: (json['services'] as Array<any>).map(ObjectServicesFromJSON),
    content:
      json['content'] === null ? null : (json['content'] as Array<any>).map(ContentFromJSON),
    faq: json['faq'] === null ? null : (json['faq'] as Array<any>).map(FAQFromJSON),
  }
}

export function ObjectDetailUserToJSON(value?: ObjectDetailUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    isVisible: value.isVisible,
    slug: value.slug,
  }
}
