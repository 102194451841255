/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Adds update nested feature
 * @export
 * @interface ContentAdmin
 */
export interface ContentAdmin {
  /**
   *
   * @type {number}
   * @memberof ContentAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ContentAdmin
   */
  readonly slug: string
  /**
   *
   * @type {string}
   * @memberof ContentAdmin
   */
  header?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContentAdmin
   */
  usedDefault?: boolean
}

/**
 * Check if a given object implements the ContentAdmin interface.
 */
export function instanceOfContentAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'slug' in value

  return isInstance
}

export function ContentAdminFromJSON(json: any): ContentAdmin {
  return ContentAdminFromJSONTyped(json, false)
}

export function ContentAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    slug: json['slug'],
    header: !exists(json, 'header') ? undefined : json['header'],
    usedDefault: !exists(json, 'usedDefault') ? undefined : json['usedDefault'],
  }
}

export function ContentAdminToJSON(value?: ContentAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    header: value.header,
    usedDefault: value.usedDefault,
  }
}
