/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LocationTagRequest
 */
export interface LocationTagRequest {
  /**
   *
   * @type {string}
   * @memberof LocationTagRequest
   */
  name: string
}

/**
 * Check if a given object implements the LocationTagRequest interface.
 */
export function instanceOfLocationTagRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function LocationTagRequestFromJSON(json: any): LocationTagRequest {
  return LocationTagRequestFromJSONTyped(json, false)
}

export function LocationTagRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LocationTagRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
  }
}

export function LocationTagRequestToJSON(value?: LocationTagRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
  }
}
