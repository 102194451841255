/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PaginatedPortfolioTagListAdmin } from './PaginatedPortfolioTagListAdmin'
import {
  PaginatedPortfolioTagListAdminFromJSON,
  PaginatedPortfolioTagListAdminFromJSONTyped,
  PaginatedPortfolioTagListAdminToJSON,
} from './PaginatedPortfolioTagListAdmin'

/**
 *
 * @export
 * @interface EnvelopedPortfolioTagListPortfolioTagListAdmin
 */
export interface EnvelopedPortfolioTagListPortfolioTagListAdmin {
  /**
   *
   * @type {PaginatedPortfolioTagListAdmin}
   * @memberof EnvelopedPortfolioTagListPortfolioTagListAdmin
   */
  body: PaginatedPortfolioTagListAdmin
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedPortfolioTagListPortfolioTagListAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedPortfolioTagListPortfolioTagListAdmin interface.
 */
export function instanceOfEnvelopedPortfolioTagListPortfolioTagListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedPortfolioTagListPortfolioTagListAdminFromJSON(
  json: any,
): EnvelopedPortfolioTagListPortfolioTagListAdmin {
  return EnvelopedPortfolioTagListPortfolioTagListAdminFromJSONTyped(json, false)
}

export function EnvelopedPortfolioTagListPortfolioTagListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedPortfolioTagListPortfolioTagListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PaginatedPortfolioTagListAdminFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedPortfolioTagListPortfolioTagListAdminToJSON(
  value?: EnvelopedPortfolioTagListPortfolioTagListAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PaginatedPortfolioTagListAdminToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
