/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ContentCardOtherData
 */
export interface ContentCardOtherData {
  /**
   *
   * @type {string}
   * @memberof ContentCardOtherData
   */
  subtitle?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCardOtherData
   */
  link?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCardOtherData
   */
  name?: string | null
  /**
   *
   * @type {number}
   * @memberof ContentCardOtherData
   */
  stars?: number | null
  /**
   *
   * @type {number}
   * @memberof ContentCardOtherData
   */
  reviewsAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof ContentCardOtherData
   */
  tag?: string | null
  /**
   *
   * @type {number}
   * @memberof ContentCardOtherData
   */
  serviceId?: number | null
  /**
   *
   * @type {string}
   * @memberof ContentCardOtherData
   */
  serviceSlug?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCardOtherData
   */
  measureUnit?: string | null
  /**
   *
   * @type {number}
   * @memberof ContentCardOtherData
   */
  price?: number | null
}

/**
 * Check if a given object implements the ContentCardOtherData interface.
 */
export function instanceOfContentCardOtherData(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ContentCardOtherDataFromJSON(json: any): ContentCardOtherData {
  return ContentCardOtherDataFromJSONTyped(json, false)
}

export function ContentCardOtherDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContentCardOtherData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    subtitle: !exists(json, 'subtitle') ? undefined : json['subtitle'],
    link: !exists(json, 'link') ? undefined : json['link'],
    name: !exists(json, 'name') ? undefined : json['name'],
    stars: !exists(json, 'stars') ? undefined : json['stars'],
    reviewsAmount: !exists(json, 'reviewsAmount') ? undefined : json['reviewsAmount'],
    tag: !exists(json, 'tag') ? undefined : json['tag'],
    serviceId: !exists(json, 'serviceId') ? undefined : json['serviceId'],
    serviceSlug: !exists(json, 'serviceSlug') ? undefined : json['serviceSlug'],
    measureUnit: !exists(json, 'measureUnit') ? undefined : json['measureUnit'],
    price: !exists(json, 'price') ? undefined : json['price'],
  }
}

export function ContentCardOtherDataToJSON(value?: ContentCardOtherData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    subtitle: value.subtitle,
    link: value.link,
    name: value.name,
    stars: value.stars,
    reviewsAmount: value.reviewsAmount,
    tag: value.tag,
    serviceId: value.serviceId,
    serviceSlug: value.serviceSlug,
    measureUnit: value.measureUnit,
    price: value.price,
  }
}
