/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RegionPublic
 */
export interface RegionPublic {
  /**
   *
   * @type {number}
   * @memberof RegionPublic
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof RegionPublic
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof RegionPublic
   */
  subdomain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof RegionPublic
   */
  isEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof RegionPublic
   */
  province?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof RegionPublic
   */
  cityList?: Array<string>
}

/**
 * Check if a given object implements the RegionPublic interface.
 */
export function instanceOfRegionPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function RegionPublicFromJSON(json: any): RegionPublic {
  return RegionPublicFromJSONTyped(json, false)
}

export function RegionPublicFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    subdomain: !exists(json, 'subdomain') ? undefined : json['subdomain'],
    isEnabled: !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
    province: !exists(json, 'province') ? undefined : json['province'],
    cityList: !exists(json, 'cityList') ? undefined : json['cityList'],
  }
}

export function RegionPublicToJSON(value?: RegionPublic | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    subdomain: value.subdomain,
    isEnabled: value.isEnabled,
    province: value.province,
    cityList: value.cityList,
  }
}
