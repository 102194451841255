/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { MenuRequest } from './MenuRequest'
import { MenuRequestFromJSON, MenuRequestFromJSONTyped, MenuRequestToJSON } from './MenuRequest'

/**
 *
 * @export
 * @interface PatchedApiMenuRequest
 */
export interface PatchedApiMenuRequest {
  /**
   *
   * @type {Array<MenuRequest>}
   * @memberof PatchedApiMenuRequest
   */
  menu?: Array<MenuRequest>
}

/**
 * Check if a given object implements the PatchedApiMenuRequest interface.
 */
export function instanceOfPatchedApiMenuRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedApiMenuRequestFromJSON(json: any): PatchedApiMenuRequest {
  return PatchedApiMenuRequestFromJSONTyped(json, false)
}

export function PatchedApiMenuRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedApiMenuRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    menu: !exists(json, 'menu')
      ? undefined
      : (json['menu'] as Array<any>).map(MenuRequestFromJSON),
  }
}

export function PatchedApiMenuRequestToJSON(value?: PatchedApiMenuRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    menu:
      value.menu === undefined ? undefined : (value.menu as Array<any>).map(MenuRequestToJSON),
  }
}
