/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AuthTokenResponse
 */
export interface AuthTokenResponse {
  /**
   *
   * @type {string}
   * @memberof AuthTokenResponse
   */
  access: string
  /**
   *
   * @type {string}
   * @memberof AuthTokenResponse
   */
  refresh: string
}

/**
 * Check if a given object implements the AuthTokenResponse interface.
 */
export function instanceOfAuthTokenResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'access' in value
  isInstance = isInstance && 'refresh' in value

  return isInstance
}

export function AuthTokenResponseFromJSON(json: any): AuthTokenResponse {
  return AuthTokenResponseFromJSONTyped(json, false)
}

export function AuthTokenResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthTokenResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    access: json['access'],
    refresh: json['refresh'],
  }
}

export function AuthTokenResponseToJSON(value?: AuthTokenResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    access: value.access,
    refresh: value.refresh,
  }
}
