/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { EnvelopedOrderNumberOrderCreatePublic } from '../models'
import {
  EnvelopedOrderNumberOrderCreatePublicFromJSON,
  EnvelopedOrderNumberOrderCreatePublicToJSON,
} from '../models'

export interface OrderCreateRequest {
  customerPhone: string
  recaptchaToken: string
  executionDt?: string
  preferredDtFrom?: string
  preferredDtTo?: string
  customerEmail?: string | null
  customerName?: string | null
  commentary?: string | null
  addressMain?: string | null
  addressAdditional?: string | null
  serviceId?: number
  regionId?: number
  masterId?: number
  photos?: Array<Blob>
}

/**
 *
 */
export class OrderApi extends runtime.BaseAPI {
  /**
   */
  async orderCreateRaw(
    requestParameters: OrderCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedOrderNumberOrderCreatePublic>> {
    if (
      requestParameters.customerPhone === null ||
      requestParameters.customerPhone === undefined
    ) {
      throw new runtime.RequiredError(
        'customerPhone',
        'Required parameter requestParameters.customerPhone was null or undefined when calling orderCreate.',
      )
    }

    if (
      requestParameters.recaptchaToken === null ||
      requestParameters.recaptchaToken === undefined
    ) {
      throw new runtime.RequiredError(
        'recaptchaToken',
        'Required parameter requestParameters.recaptchaToken was null or undefined when calling orderCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.executionDt !== undefined) {
      formParams.append('executionDt', requestParameters.executionDt as any)
    }

    if (requestParameters.preferredDtFrom !== undefined) {
      formParams.append('preferredDtFrom', requestParameters.preferredDtFrom as any)
    }

    if (requestParameters.preferredDtTo !== undefined) {
      formParams.append('preferredDtTo', requestParameters.preferredDtTo as any)
    }

    if (requestParameters.customerEmail !== undefined) {
      formParams.append('customerEmail', requestParameters.customerEmail as any)
    }

    if (requestParameters.customerName !== undefined) {
      formParams.append('customerName', requestParameters.customerName as any)
    }

    if (requestParameters.customerPhone !== undefined) {
      formParams.append('customerPhone', requestParameters.customerPhone as any)
    }

    if (requestParameters.commentary !== undefined) {
      formParams.append('commentary', requestParameters.commentary as any)
    }

    if (requestParameters.addressMain !== undefined) {
      formParams.append('addressMain', requestParameters.addressMain as any)
    }

    if (requestParameters.addressAdditional !== undefined) {
      formParams.append('addressAdditional', requestParameters.addressAdditional as any)
    }

    if (requestParameters.serviceId !== undefined) {
      formParams.append('serviceId', requestParameters.serviceId as any)
    }

    if (requestParameters.regionId !== undefined) {
      formParams.append('regionId', requestParameters.regionId as any)
    }

    if (requestParameters.masterId !== undefined) {
      formParams.append('masterId', requestParameters.masterId as any)
    }

    if (requestParameters.photos) {
      requestParameters.photos.forEach((element) => {
        formParams.append('photos', element as any)
      })
    }

    if (requestParameters.recaptchaToken !== undefined) {
      formParams.append('recaptchaToken', requestParameters.recaptchaToken as any)
    }

    const response = await this.request(
      {
        path: `/api/order/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedOrderNumberOrderCreatePublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async orderCreate(
    requestParameters: OrderCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedOrderNumberOrderCreatePublic> {
    const response = await this.orderCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
