/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface FAQUpdateRequest
 */
export interface FAQUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof FAQUpdateRequest
   */
  answer: string
  /**
   *
   * @type {string}
   * @memberof FAQUpdateRequest
   */
  question?: string
  /**
   *
   * @type {number}
   * @memberof FAQUpdateRequest
   */
  score?: number | null
}

/**
 * Check if a given object implements the FAQUpdateRequest interface.
 */
export function instanceOfFAQUpdateRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'answer' in value

  return isInstance
}

export function FAQUpdateRequestFromJSON(json: any): FAQUpdateRequest {
  return FAQUpdateRequestFromJSONTyped(json, false)
}

export function FAQUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FAQUpdateRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    answer: json['answer'],
    question: !exists(json, 'question') ? undefined : json['question'],
    score: !exists(json, 'score') ? undefined : json['score'],
  }
}

export function FAQUpdateRequestToJSON(value?: FAQUpdateRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    answer: value.answer,
    question: value.question,
    score: value.score,
  }
}
