/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  AuthTokenRequestRequest,
  EnvelopedAuthTokenResponse,
  EnvelopedTokenRefresh,
  TokenRefreshRequest,
} from '../models'
import {
  AuthTokenRequestRequestFromJSON,
  AuthTokenRequestRequestToJSON,
  EnvelopedAuthTokenResponseFromJSON,
  EnvelopedAuthTokenResponseToJSON,
  EnvelopedTokenRefreshFromJSON,
  EnvelopedTokenRefreshToJSON,
  TokenRefreshRequestFromJSON,
  TokenRefreshRequestToJSON,
} from '../models'

export interface AuthTokenAuthCreateRequest {
  authTokenRequestRequest: AuthTokenRequestRequest
}

export interface AuthTokenRefreshCreateRequest {
  tokenRefreshRequest: TokenRefreshRequest
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
   */
  async authTokenAuthCreateRaw(
    requestParameters: AuthTokenAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedAuthTokenResponse>> {
    if (
      requestParameters.authTokenRequestRequest === null ||
      requestParameters.authTokenRequestRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'authTokenRequestRequest',
        'Required parameter requestParameters.authTokenRequestRequest was null or undefined when calling authTokenAuthCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/api/auth/token-auth/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthTokenRequestRequestToJSON(requestParameters.authTokenRequestRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedAuthTokenResponseFromJSON(jsonValue),
    )
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
   */
  async authTokenAuthCreate(
    requestParameters: AuthTokenAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedAuthTokenResponse> {
    const response = await this.authTokenAuthCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   */
  async authTokenRefreshCreateRaw(
    requestParameters: AuthTokenRefreshCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedTokenRefresh>> {
    if (
      requestParameters.tokenRefreshRequest === null ||
      requestParameters.tokenRefreshRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'tokenRefreshRequest',
        'Required parameter requestParameters.tokenRefreshRequest was null or undefined when calling authTokenRefreshCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/api/auth/token-refresh/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TokenRefreshRequestToJSON(requestParameters.tokenRefreshRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedTokenRefreshFromJSON(jsonValue),
    )
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   */
  async authTokenRefreshCreate(
    requestParameters: AuthTokenRefreshCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedTokenRefresh> {
    const response = await this.authTokenRefreshCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
