/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ServiceSeoFields
 */
export interface ServiceSeoFields {
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  headerMain?: string | null
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  titleTagMain?: string | null
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  descriptionTagMain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ServiceSeoFields
   */
  useLocalMetaMain?: boolean
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  descriptionMain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ServiceSeoFields
   */
  useLocalDescriptionMain?: boolean
  /**
   *
   * @type {number}
   * @memberof ServiceSeoFields
   */
  serviceTemplateType: number | null
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  headerListing?: string | null
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  titleTagListing?: string | null
  /**
   *
   * @type {string}
   * @memberof ServiceSeoFields
   */
  descriptionTagListing?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ServiceSeoFields
   */
  useLocalMetaListing?: boolean
}

/**
 * Check if a given object implements the ServiceSeoFields interface.
 */
export function instanceOfServiceSeoFields(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'serviceTemplateType' in value

  return isInstance
}

export function ServiceSeoFieldsFromJSON(json: any): ServiceSeoFields {
  return ServiceSeoFieldsFromJSONTyped(json, false)
}

export function ServiceSeoFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ServiceSeoFields {
  if (json === undefined || json === null) {
    return json
  }
  return {
    headerMain: !exists(json, 'headerMain') ? undefined : json['headerMain'],
    titleTagMain: !exists(json, 'titleTagMain') ? undefined : json['titleTagMain'],
    descriptionTagMain: !exists(json, 'descriptionTagMain')
      ? undefined
      : json['descriptionTagMain'],
    useLocalMetaMain: !exists(json, 'useLocalMetaMain') ? undefined : json['useLocalMetaMain'],
    descriptionMain: !exists(json, 'descriptionMain') ? undefined : json['descriptionMain'],
    useLocalDescriptionMain: !exists(json, 'useLocalDescriptionMain')
      ? undefined
      : json['useLocalDescriptionMain'],
    serviceTemplateType: json['serviceTemplateType'],
    headerListing: !exists(json, 'headerListing') ? undefined : json['headerListing'],
    titleTagListing: !exists(json, 'titleTagListing') ? undefined : json['titleTagListing'],
    descriptionTagListing: !exists(json, 'descriptionTagListing')
      ? undefined
      : json['descriptionTagListing'],
    useLocalMetaListing: !exists(json, 'useLocalMetaListing')
      ? undefined
      : json['useLocalMetaListing'],
  }
}

export function ServiceSeoFieldsToJSON(value?: ServiceSeoFields | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    headerMain: value.headerMain,
    titleTagMain: value.titleTagMain,
    descriptionTagMain: value.descriptionTagMain,
    useLocalMetaMain: value.useLocalMetaMain,
    descriptionMain: value.descriptionMain,
    useLocalDescriptionMain: value.useLocalDescriptionMain,
    serviceTemplateType: value.serviceTemplateType,
    headerListing: value.headerListing,
    titleTagListing: value.titleTagListing,
    descriptionTagListing: value.descriptionTagListing,
    useLocalMetaListing: value.useLocalMetaListing,
  }
}
