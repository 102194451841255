/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RegionRequest
 */
export interface RegionRequest {
  /**
   *
   * @type {string}
   * @memberof RegionRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof RegionRequest
   */
  subdomain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof RegionRequest
   */
  isEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof RegionRequest
   */
  province?: string | null
  /**
   *
   * @type {string}
   * @memberof RegionRequest
   */
  declination?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof RegionRequest
   */
  cityList?: Array<string>
}

/**
 * Check if a given object implements the RegionRequest interface.
 */
export function instanceOfRegionRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function RegionRequestFromJSON(json: any): RegionRequest {
  return RegionRequestFromJSONTyped(json, false)
}

export function RegionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RegionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    subdomain: !exists(json, 'subdomain') ? undefined : json['subdomain'],
    isEnabled: !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
    province: !exists(json, 'province') ? undefined : json['province'],
    declination: !exists(json, 'declination') ? undefined : json['declination'],
    cityList: !exists(json, 'cityList') ? undefined : json['cityList'],
  }
}

export function RegionRequestToJSON(value?: RegionRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    subdomain: value.subdomain,
    isEnabled: value.isEnabled,
    province: value.province,
    declination: value.declination,
    cityList: value.cityList,
  }
}
