/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { Master } from './Master'
import { MasterFromJSON, MasterFromJSONTyped, MasterToJSON } from './Master'

/**
 *
 * @export
 * @interface EnvelopedMasterListMasterPublic
 */
export interface EnvelopedMasterListMasterPublic {
  /**
   *
   * @type {Array<Master>}
   * @memberof EnvelopedMasterListMasterPublic
   */
  body: Array<Master>
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedMasterListMasterPublic
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedMasterListMasterPublic interface.
 */
export function instanceOfEnvelopedMasterListMasterPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedMasterListMasterPublicFromJSON(
  json: any,
): EnvelopedMasterListMasterPublic {
  return EnvelopedMasterListMasterPublicFromJSONTyped(json, false)
}

export function EnvelopedMasterListMasterPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedMasterListMasterPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: (json['body'] as Array<any>).map(MasterFromJSON),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedMasterListMasterPublicToJSON(
  value?: EnvelopedMasterListMasterPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: (value.body as Array<any>).map(MasterToJSON),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
