/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Menu
 */
export interface Menu {
  /**
   *
   * @type {string}
   * @memberof Menu
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof Menu
   */
  key: number
  /**
   *
   * @type {boolean}
   * @memberof Menu
   */
  isEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof Menu
   */
  slug?: string
  /**
   *
   * @type {Array<Menu>}
   * @memberof Menu
   */
  children?: Array<Menu> | null
}

/**
 * Check if a given object implements the Menu interface.
 */
export function instanceOfMenu(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'title' in value
  isInstance = isInstance && 'key' in value

  return isInstance
}

export function MenuFromJSON(json: any): Menu {
  return MenuFromJSONTyped(json, false)
}

export function MenuFromJSONTyped(json: any, ignoreDiscriminator: boolean): Menu {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    key: json['key'],
    isEnabled: !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    children: !exists(json, 'children')
      ? undefined
      : json['children'] === null
      ? null
      : (json['children'] as Array<any>).map(MenuFromJSON),
  }
}

export function MenuToJSON(value?: Menu | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    key: value.key,
    isEnabled: value.isEnabled,
    slug: value.slug,
    children:
      value.children === undefined
        ? undefined
        : value.children === null
        ? null
        : (value.children as Array<any>).map(MenuToJSON),
  }
}
