/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ObjectListAdmin } from './ObjectListAdmin'
import {
  ObjectListAdminFromJSON,
  ObjectListAdminFromJSONTyped,
  ObjectListAdminToJSON,
} from './ObjectListAdmin'

/**
 *
 * @export
 * @interface PaginatedObjectListAdmin
 */
export interface PaginatedObjectListAdmin {
  /**
   *
   * @type {Array<ObjectListAdmin>}
   * @memberof PaginatedObjectListAdmin
   */
  results: Array<ObjectListAdmin>
  /**
   *
   * @type {number}
   * @memberof PaginatedObjectListAdmin
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedObjectListAdmin
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedObjectListAdmin
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedObjectListAdmin interface.
 */
export function instanceOfPaginatedObjectListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedObjectListAdminFromJSON(json: any): PaginatedObjectListAdmin {
  return PaginatedObjectListAdminFromJSONTyped(json, false)
}

export function PaginatedObjectListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedObjectListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(ObjectListAdminFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedObjectListAdminToJSON(value?: PaginatedObjectListAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(ObjectListAdminToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
