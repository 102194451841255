import { Configuration, ResponseError } from '@/api'
import { config } from '@/constants/config'
import * as Sentry from '@sentry/nextjs'

export const configuration = new Configuration({
  // basePath: 'https://api.remasterr.ru',
  basePath: config.backendUrl ? config.backendUrl : `https://api.${config.siteUrl}`,
  fetchApi: global.fetch.bind(global),
})

export const handleApiError = async (error: unknown) => {
  if (error instanceof Response) {
    const { body, errors } = await error.json()
    const textErrror = new Error(
      `Response, code: ${error.status}; url: ${error.url}; errors: ${JSON.stringify(
        errors,
      )}; body: ${JSON.stringify(body)}`,
    )
    Sentry.captureException(textErrror)
    throw errors
  } else if (error instanceof ResponseError) {
    if (error.response.status < 500) {
      const { body, errors } = await error.response.json()
      const textErrror = new Error(
        `ResponseError, code: ${error.response.status}; url: ${
          error.response.url
        }; errors: ${JSON.stringify(errors)}; body: ${JSON.stringify(body)}`,
      )
      if (error.response.status !== 307) {
        Sentry.captureException(textErrror)
      }

      throw {
        errors,
        code: error.response.status,
      }
    } else {
      const { body, errors } = await error.response.json()

      const textErrror = new Error(
        `code: 500; url: ${error.response.url}; errors: ${JSON.stringify(
          errors,
        )}; body: ${JSON.stringify(body)}`,
      )
      Sentry.captureException(textErrror)
      throw {
        errors: '500 error',
        code: 500,
      }
    }
  }

  Sentry.captureException(error, {
    tags: {
      some: 'Some Error, body: null',
    },
    level: 'warning',
  })

  return {
    body: null,
    errors: [],
  }
}
