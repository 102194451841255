/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { Review } from './Review'
import { ReviewFromJSON, ReviewFromJSONTyped, ReviewToJSON } from './Review'

/**
 *
 * @export
 * @interface EnvelopedReviewReviewAdminDetail
 */
export interface EnvelopedReviewReviewAdminDetail {
  /**
   *
   * @type {Review}
   * @memberof EnvelopedReviewReviewAdminDetail
   */
  body: Review
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedReviewReviewAdminDetail
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedReviewReviewAdminDetail interface.
 */
export function instanceOfEnvelopedReviewReviewAdminDetail(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedReviewReviewAdminDetailFromJSON(
  json: any,
): EnvelopedReviewReviewAdminDetail {
  return EnvelopedReviewReviewAdminDetailFromJSONTyped(json, false)
}

export function EnvelopedReviewReviewAdminDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedReviewReviewAdminDetail {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: ReviewFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedReviewReviewAdminDetailToJSON(
  value?: EnvelopedReviewReviewAdminDetail | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: ReviewToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
