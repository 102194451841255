/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  EnvelopedPortfolioTagListPortfolioTagListPublic,
  EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic,
  EnvelopedPortfolioWorkListPortfolioWorkListPublic,
} from '../models'
import {
  EnvelopedPortfolioTagListPortfolioTagListPublicFromJSON,
  EnvelopedPortfolioTagListPortfolioTagListPublicToJSON,
  EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicFromJSON,
  EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicToJSON,
  EnvelopedPortfolioWorkListPortfolioWorkListPublicFromJSON,
  EnvelopedPortfolioWorkListPortfolioWorkListPublicToJSON,
} from '../models'

export interface PortfolioListRequest {
  gallerySize?: number
  page?: number
  regionId?: number
  size?: number
  tagId?: number
}

export interface PortfolioPortfolioGalleryListRequest {
  portfolioid: number
  page?: number
  size?: number
}

export interface PortfolioTagListRequest {
  page?: number
  size?: number
}

/**
 *
 */
export class PortfolioApi extends runtime.BaseAPI {
  /**
   */
  async portfolioListRaw(
    requestParameters: PortfolioListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioWorkListPortfolioWorkListPublic>> {
    const queryParameters: any = {}

    if (requestParameters.gallerySize !== undefined) {
      queryParameters['gallerySize'] = requestParameters.gallerySize
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.tagId !== undefined) {
      queryParameters['tagId'] = requestParameters.tagId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/portfolio/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkListPortfolioWorkListPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async portfolioList(
    requestParameters: PortfolioListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkListPortfolioWorkListPublic> {
    const response = await this.portfolioListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async portfolioPortfolioGalleryListRaw(
    requestParameters: PortfolioPortfolioGalleryListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic>
  > {
    if (requestParameters.portfolioid === null || requestParameters.portfolioid === undefined) {
      throw new runtime.RequiredError(
        'portfolioid',
        'Required parameter requestParameters.portfolioid was null or undefined when calling portfolioPortfolioGalleryList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/portfolio/{portfolioid}/portfolio_gallery/`.replace(
          `{${'portfolioid'}}`,
          encodeURIComponent(String(requestParameters.portfolioid)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async portfolioPortfolioGalleryList(
    requestParameters: PortfolioPortfolioGalleryListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkGalleryListPortfolioWorkGalleryListPublic> {
    const response = await this.portfolioPortfolioGalleryListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async portfolioTagListRaw(
    requestParameters: PortfolioTagListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioTagListPortfolioTagListPublic>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/portfolio/tag/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioTagListPortfolioTagListPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async portfolioTagList(
    requestParameters: PortfolioTagListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioTagListPortfolioTagListPublic> {
    const response = await this.portfolioTagListRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
