/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  AdditionalWorkCreateRequest,
  ContentCardRequest,
  CustomContentJSONRequest,
  DocumentRequest,
  Enveloped,
  EnvelopedAdditinalWorkCreate,
  EnvelopedAdditionalWorkDestroyAdmin,
  EnvelopedApiMenuApiMenuAdmin,
  EnvelopedApiMenuUpdateAdmin,
  EnvelopedArchivedCatalogCatalogArchivedAdmin,
  EnvelopedCalculatorUpdateAdmin,
  EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin,
  EnvelopedCatalogLocationSeoFieldsDestroyAdmin,
  EnvelopedCatalogLocationSeoFieldsListCatalogLocationSeoFieldsAdmin,
  EnvelopedCatalogLocationSeoFieldsUpdateAdmin,
  EnvelopedCatalogSetVisibilityAdmin,
  EnvelopedCategoryCategoryDetailAdmin,
  EnvelopedCategoryCreateAdmin,
  EnvelopedCategoryDestroyAdmin,
  EnvelopedCategoryListCategoryAdmin,
  EnvelopedCategoryUpdateAdmin,
  EnvelopedContentContentAdminRetrieve,
  EnvelopedContentListContentAdmin,
  EnvelopedContentUpdateAdmin,
  EnvelopedDocumentCreateAdmin,
  EnvelopedDocumentDestroyAdmin,
  EnvelopedDocumentDocumentAdminDetail,
  EnvelopedDocumentListDocumentAdmin,
  EnvelopedDocumentUpdateAdmin,
  EnvelopedFAQCreateAdmin,
  EnvelopedFAQDestroyAdmin,
  EnvelopedFAQFullUpdateAdmin,
  EnvelopedFAQListFAQAdmin,
  EnvelopedFAQUpdateAdmin,
  EnvelopedLocationCreateAdmin,
  EnvelopedLocationDestroyAdmin,
  EnvelopedLocationListLocationListAdmin,
  EnvelopedLocationTagCreateAdmin,
  EnvelopedLocationTagDestroyAdmin,
  EnvelopedLocationTagListLocationTagListAdmin,
  EnvelopedLocationTagUpdateAdmin,
  EnvelopedLocationUpdateAdmin,
  EnvelopedObjectListObjectListAdmin,
  EnvelopedObjectObjectDetailAdmin,
  EnvelopedObjectUpdateAdmin,
  EnvelopedOpenApiCatalogList,
  EnvelopedPortfolioTagCreateAdmin,
  EnvelopedPortfolioTagDestroyAdmin,
  EnvelopedPortfolioTagListPortfolioTagListAdmin,
  EnvelopedPortfolioTagPortfolioTagAdmin,
  EnvelopedPortfolioTagUpdateAdmin,
  EnvelopedPortfolioWorkCreate123Admin,
  EnvelopedPortfolioWorkDestroyAdmin,
  EnvelopedPortfolioWorkListPortfolioWorkListAdmin,
  EnvelopedPortfolioWorkPortfolioWorkAdmin,
  EnvelopedPortfolioWorkUpdateAdmin,
  EnvelopedRegionCreateAdmin,
  EnvelopedRegionListRegionListAdmin,
  EnvelopedRegionUpdateAdmin,
  EnvelopedReviewAdminCreate,
  EnvelopedReviewAdminDestroy,
  EnvelopedReviewAdminUpdate,
  EnvelopedReviewListReviewAdmin,
  EnvelopedReviewReviewAdminDetail,
  EnvelopedSeoDefaultsSeoDefaultsAdmin,
  EnvelopedSeoDefaultsUpdateAdmin,
  EnvelopedServiceListAdditionalWorkListAdmin,
  EnvelopedServiceListServiceListAdmin,
  EnvelopedServiceLocationSeoFieldsDestroyAdmin,
  EnvelopedServiceLocationSeoFieldsGenerate,
  EnvelopedServiceLocationSeoFieldsListServiceLocationSeoFieldsAdmin,
  EnvelopedServiceLocationSeoFieldsServiceLocationSeoFieldsAdmin,
  EnvelopedServiceLocationSeoFieldsUpdateAdmin,
  EnvelopedServiceServiceDetailAdmin,
  EnvelopedStaticPageListStaticPageAdmin,
  EnvelopedStaticPageStaticPageDetailAdmin,
  EnvelopedStaticPageUpdateAdmin,
  EnvelopedStatusSyncStatusRetreive,
  EnvelopedSyncData,
  FAQRequest,
  GenLocationsServiceRequest,
  LocationRequest,
  LocationTagRequest,
  PatchedApiMenuRequest,
  PatchedCalculatorRequest,
  PatchedCatalogLocationSeoFieldsRequest,
  PatchedCatalogVisibilityRequest,
  PatchedDocumentRequest,
  PatchedFAQRequest,
  PatchedFAQUpdateListRequest,
  PatchedLocationRequest,
  PatchedLocationTagRequest,
  PatchedPortfolioTagRequest,
  PatchedRegionRequest,
  PatchedReviewRequest,
  PatchedSeoDefaultsRequest,
  PatchedServiceLocationSeoFieldsRequest,
  PatchedServiceUpdateRequest,
  PatchedStaticPageRequest,
  PortfolioTagRequest,
  PortfolioWorkGalleryRequest,
  RegionRequest,
  ReviewRequest,
} from '../models'
import {
  AdditionalWorkCreateRequestFromJSON,
  AdditionalWorkCreateRequestToJSON,
  ContentCardRequestFromJSON,
  ContentCardRequestToJSON,
  CustomContentJSONRequestFromJSON,
  CustomContentJSONRequestToJSON,
  DocumentRequestFromJSON,
  DocumentRequestToJSON,
  EnvelopedFromJSON,
  EnvelopedToJSON,
  EnvelopedAdditinalWorkCreateFromJSON,
  EnvelopedAdditinalWorkCreateToJSON,
  EnvelopedAdditionalWorkDestroyAdminFromJSON,
  EnvelopedAdditionalWorkDestroyAdminToJSON,
  EnvelopedApiMenuApiMenuAdminFromJSON,
  EnvelopedApiMenuApiMenuAdminToJSON,
  EnvelopedApiMenuUpdateAdminFromJSON,
  EnvelopedApiMenuUpdateAdminToJSON,
  EnvelopedArchivedCatalogCatalogArchivedAdminFromJSON,
  EnvelopedArchivedCatalogCatalogArchivedAdminToJSON,
  EnvelopedCalculatorUpdateAdminFromJSON,
  EnvelopedCalculatorUpdateAdminToJSON,
  EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminFromJSON,
  EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminToJSON,
  EnvelopedCatalogLocationSeoFieldsDestroyAdminFromJSON,
  EnvelopedCatalogLocationSeoFieldsDestroyAdminToJSON,
  EnvelopedCatalogLocationSeoFieldsListCatalogLocationSeoFieldsAdminFromJSON,
  EnvelopedCatalogLocationSeoFieldsListCatalogLocationSeoFieldsAdminToJSON,
  EnvelopedCatalogLocationSeoFieldsUpdateAdminFromJSON,
  EnvelopedCatalogLocationSeoFieldsUpdateAdminToJSON,
  EnvelopedCatalogSetVisibilityAdminFromJSON,
  EnvelopedCatalogSetVisibilityAdminToJSON,
  EnvelopedCategoryCategoryDetailAdminFromJSON,
  EnvelopedCategoryCategoryDetailAdminToJSON,
  EnvelopedCategoryCreateAdminFromJSON,
  EnvelopedCategoryCreateAdminToJSON,
  EnvelopedCategoryDestroyAdminFromJSON,
  EnvelopedCategoryDestroyAdminToJSON,
  EnvelopedCategoryListCategoryAdminFromJSON,
  EnvelopedCategoryListCategoryAdminToJSON,
  EnvelopedCategoryUpdateAdminFromJSON,
  EnvelopedCategoryUpdateAdminToJSON,
  EnvelopedContentContentAdminRetrieveFromJSON,
  EnvelopedContentContentAdminRetrieveToJSON,
  EnvelopedContentListContentAdminFromJSON,
  EnvelopedContentListContentAdminToJSON,
  EnvelopedContentUpdateAdminFromJSON,
  EnvelopedContentUpdateAdminToJSON,
  EnvelopedDocumentCreateAdminFromJSON,
  EnvelopedDocumentCreateAdminToJSON,
  EnvelopedDocumentDestroyAdminFromJSON,
  EnvelopedDocumentDestroyAdminToJSON,
  EnvelopedDocumentDocumentAdminDetailFromJSON,
  EnvelopedDocumentDocumentAdminDetailToJSON,
  EnvelopedDocumentListDocumentAdminFromJSON,
  EnvelopedDocumentListDocumentAdminToJSON,
  EnvelopedDocumentUpdateAdminFromJSON,
  EnvelopedDocumentUpdateAdminToJSON,
  EnvelopedFAQCreateAdminFromJSON,
  EnvelopedFAQCreateAdminToJSON,
  EnvelopedFAQDestroyAdminFromJSON,
  EnvelopedFAQDestroyAdminToJSON,
  EnvelopedFAQFullUpdateAdminFromJSON,
  EnvelopedFAQFullUpdateAdminToJSON,
  EnvelopedFAQListFAQAdminFromJSON,
  EnvelopedFAQListFAQAdminToJSON,
  EnvelopedFAQUpdateAdminFromJSON,
  EnvelopedFAQUpdateAdminToJSON,
  EnvelopedLocationCreateAdminFromJSON,
  EnvelopedLocationCreateAdminToJSON,
  EnvelopedLocationDestroyAdminFromJSON,
  EnvelopedLocationDestroyAdminToJSON,
  EnvelopedLocationListLocationListAdminFromJSON,
  EnvelopedLocationListLocationListAdminToJSON,
  EnvelopedLocationTagCreateAdminFromJSON,
  EnvelopedLocationTagCreateAdminToJSON,
  EnvelopedLocationTagDestroyAdminFromJSON,
  EnvelopedLocationTagDestroyAdminToJSON,
  EnvelopedLocationTagListLocationTagListAdminFromJSON,
  EnvelopedLocationTagListLocationTagListAdminToJSON,
  EnvelopedLocationTagUpdateAdminFromJSON,
  EnvelopedLocationTagUpdateAdminToJSON,
  EnvelopedLocationUpdateAdminFromJSON,
  EnvelopedLocationUpdateAdminToJSON,
  EnvelopedObjectListObjectListAdminFromJSON,
  EnvelopedObjectListObjectListAdminToJSON,
  EnvelopedObjectObjectDetailAdminFromJSON,
  EnvelopedObjectObjectDetailAdminToJSON,
  EnvelopedObjectUpdateAdminFromJSON,
  EnvelopedObjectUpdateAdminToJSON,
  EnvelopedOpenApiCatalogListFromJSON,
  EnvelopedOpenApiCatalogListToJSON,
  EnvelopedPortfolioTagCreateAdminFromJSON,
  EnvelopedPortfolioTagCreateAdminToJSON,
  EnvelopedPortfolioTagDestroyAdminFromJSON,
  EnvelopedPortfolioTagDestroyAdminToJSON,
  EnvelopedPortfolioTagListPortfolioTagListAdminFromJSON,
  EnvelopedPortfolioTagListPortfolioTagListAdminToJSON,
  EnvelopedPortfolioTagPortfolioTagAdminFromJSON,
  EnvelopedPortfolioTagPortfolioTagAdminToJSON,
  EnvelopedPortfolioTagUpdateAdminFromJSON,
  EnvelopedPortfolioTagUpdateAdminToJSON,
  EnvelopedPortfolioWorkCreate123AdminFromJSON,
  EnvelopedPortfolioWorkCreate123AdminToJSON,
  EnvelopedPortfolioWorkDestroyAdminFromJSON,
  EnvelopedPortfolioWorkDestroyAdminToJSON,
  EnvelopedPortfolioWorkListPortfolioWorkListAdminFromJSON,
  EnvelopedPortfolioWorkListPortfolioWorkListAdminToJSON,
  EnvelopedPortfolioWorkPortfolioWorkAdminFromJSON,
  EnvelopedPortfolioWorkPortfolioWorkAdminToJSON,
  EnvelopedPortfolioWorkUpdateAdminFromJSON,
  EnvelopedPortfolioWorkUpdateAdminToJSON,
  EnvelopedRegionCreateAdminFromJSON,
  EnvelopedRegionCreateAdminToJSON,
  EnvelopedRegionListRegionListAdminFromJSON,
  EnvelopedRegionListRegionListAdminToJSON,
  EnvelopedRegionUpdateAdminFromJSON,
  EnvelopedRegionUpdateAdminToJSON,
  EnvelopedReviewAdminCreateFromJSON,
  EnvelopedReviewAdminCreateToJSON,
  EnvelopedReviewAdminDestroyFromJSON,
  EnvelopedReviewAdminDestroyToJSON,
  EnvelopedReviewAdminUpdateFromJSON,
  EnvelopedReviewAdminUpdateToJSON,
  EnvelopedReviewListReviewAdminFromJSON,
  EnvelopedReviewListReviewAdminToJSON,
  EnvelopedReviewReviewAdminDetailFromJSON,
  EnvelopedReviewReviewAdminDetailToJSON,
  EnvelopedSeoDefaultsSeoDefaultsAdminFromJSON,
  EnvelopedSeoDefaultsSeoDefaultsAdminToJSON,
  EnvelopedSeoDefaultsUpdateAdminFromJSON,
  EnvelopedSeoDefaultsUpdateAdminToJSON,
  EnvelopedServiceListAdditionalWorkListAdminFromJSON,
  EnvelopedServiceListAdditionalWorkListAdminToJSON,
  EnvelopedServiceListServiceListAdminFromJSON,
  EnvelopedServiceListServiceListAdminToJSON,
  EnvelopedServiceLocationSeoFieldsDestroyAdminFromJSON,
  EnvelopedServiceLocationSeoFieldsDestroyAdminToJSON,
  EnvelopedServiceLocationSeoFieldsGenerateFromJSON,
  EnvelopedServiceLocationSeoFieldsGenerateToJSON,
  EnvelopedServiceLocationSeoFieldsListServiceLocationSeoFieldsAdminFromJSON,
  EnvelopedServiceLocationSeoFieldsListServiceLocationSeoFieldsAdminToJSON,
  EnvelopedServiceLocationSeoFieldsServiceLocationSeoFieldsAdminFromJSON,
  EnvelopedServiceLocationSeoFieldsServiceLocationSeoFieldsAdminToJSON,
  EnvelopedServiceLocationSeoFieldsUpdateAdminFromJSON,
  EnvelopedServiceLocationSeoFieldsUpdateAdminToJSON,
  EnvelopedServiceServiceDetailAdminFromJSON,
  EnvelopedServiceServiceDetailAdminToJSON,
  EnvelopedStaticPageListStaticPageAdminFromJSON,
  EnvelopedStaticPageListStaticPageAdminToJSON,
  EnvelopedStaticPageStaticPageDetailAdminFromJSON,
  EnvelopedStaticPageStaticPageDetailAdminToJSON,
  EnvelopedStaticPageUpdateAdminFromJSON,
  EnvelopedStaticPageUpdateAdminToJSON,
  EnvelopedStatusSyncStatusRetreiveFromJSON,
  EnvelopedStatusSyncStatusRetreiveToJSON,
  EnvelopedSyncDataFromJSON,
  EnvelopedSyncDataToJSON,
  FAQRequestFromJSON,
  FAQRequestToJSON,
  GenLocationsServiceRequestFromJSON,
  GenLocationsServiceRequestToJSON,
  LocationRequestFromJSON,
  LocationRequestToJSON,
  LocationTagRequestFromJSON,
  LocationTagRequestToJSON,
  PatchedApiMenuRequestFromJSON,
  PatchedApiMenuRequestToJSON,
  PatchedCalculatorRequestFromJSON,
  PatchedCalculatorRequestToJSON,
  PatchedCatalogLocationSeoFieldsRequestFromJSON,
  PatchedCatalogLocationSeoFieldsRequestToJSON,
  PatchedCatalogVisibilityRequestFromJSON,
  PatchedCatalogVisibilityRequestToJSON,
  PatchedDocumentRequestFromJSON,
  PatchedDocumentRequestToJSON,
  PatchedFAQRequestFromJSON,
  PatchedFAQRequestToJSON,
  PatchedFAQUpdateListRequestFromJSON,
  PatchedFAQUpdateListRequestToJSON,
  PatchedLocationRequestFromJSON,
  PatchedLocationRequestToJSON,
  PatchedLocationTagRequestFromJSON,
  PatchedLocationTagRequestToJSON,
  PatchedPortfolioTagRequestFromJSON,
  PatchedPortfolioTagRequestToJSON,
  PatchedRegionRequestFromJSON,
  PatchedRegionRequestToJSON,
  PatchedReviewRequestFromJSON,
  PatchedReviewRequestToJSON,
  PatchedSeoDefaultsRequestFromJSON,
  PatchedSeoDefaultsRequestToJSON,
  PatchedServiceLocationSeoFieldsRequestFromJSON,
  PatchedServiceLocationSeoFieldsRequestToJSON,
  PatchedServiceUpdateRequestFromJSON,
  PatchedServiceUpdateRequestToJSON,
  PatchedStaticPageRequestFromJSON,
  PatchedStaticPageRequestToJSON,
  PortfolioTagRequestFromJSON,
  PortfolioTagRequestToJSON,
  PortfolioWorkGalleryRequestFromJSON,
  PortfolioWorkGalleryRequestToJSON,
  RegionRequestFromJSON,
  RegionRequestToJSON,
  ReviewRequestFromJSON,
  ReviewRequestToJSON,
} from '../models'

export interface AdminApiApiMenuPartialUpdateRequest {
  patchedApiMenuRequest?: PatchedApiMenuRequest
}

export interface AdminApiCalculatorPartialUpdateRequest {
  id: number
  patchedCalculatorRequest?: PatchedCalculatorRequest
}

export interface AdminApiContentListRequest {
  type: AdminApiContentListTypeEnum
  entityId?: number
  isGlobal?: boolean
  page?: number
  regionId?: number
  serviceTemplateType?: AdminApiContentListServiceTemplateTypeEnum
  size?: number
}

export interface AdminApiContentPartialUpdateRequest {
  id: number
  header?: string | null
  otherData?: CustomContentJSONRequest | null
  entityId?: number | null
  usedDefault?: boolean
  cards?: Array<ContentCardRequest>
  iconImg?: Blob | null
}

export interface AdminApiContentRetrieveRequest {
  id: number
}

export interface AdminApiDocumentCreateRequest {
  documentRequest?: DocumentRequest
}

export interface AdminApiDocumentDestroyRequest {
  id: number
}

export interface AdminApiDocumentListRequest {
  page?: number
  size?: number
}

export interface AdminApiDocumentPartialUpdateRequest {
  id: number
  patchedDocumentRequest?: PatchedDocumentRequest
}

export interface AdminApiDocumentRetrieveRequest {
  id: number
}

export interface AdminApiFaqCreateRequest {
  fAQRequest: FAQRequest
}

export interface AdminApiFaqDestroyRequest {
  id: number
}

export interface AdminApiFaqListRequest {
  regionId: number
  entityId?: number
  isGlobal?: boolean
  page?: number
  serviceTemplateType?: AdminApiFaqListServiceTemplateTypeEnum
  size?: number
  type?: AdminApiFaqListTypeEnum
}

export interface AdminApiFaqPartialUpdateRequest {
  id: number
  patchedFAQRequest?: PatchedFAQRequest
}

export interface AdminApiFaqSaveFullListPartialUpdateRequest {
  patchedFAQUpdateListRequest?: PatchedFAQUpdateListRequest
}

export interface AdminApiLocationLocationCreateRequest {
  locationRequest: LocationRequest
}

export interface AdminApiLocationLocationDestroyRequest {
  id: number
}

export interface AdminApiLocationLocationListRequest {
  regionId: number
  name?: string
  page?: number
  size?: number
}

export interface AdminApiLocationLocationPartialUpdateRequest {
  id: number
  patchedLocationRequest?: PatchedLocationRequest
}

export interface AdminApiLocationLocationTagCreateRequest {
  locationTagRequest: LocationTagRequest
}

export interface AdminApiLocationLocationTagDestroyRequest {
  id: number
}

export interface AdminApiLocationLocationTagListRequest {
  page?: number
  size?: number
}

export interface AdminApiLocationLocationTagPartialUpdateRequest {
  id: number
  patchedLocationTagRequest?: PatchedLocationTagRequest
}

export interface AdminApiLocationRegionCreateRequest {
  regionRequest: RegionRequest
}

export interface AdminApiLocationRegionListRequest {
  page?: number
  size?: number
}

export interface AdminApiLocationRegionPartialUpdateRequest {
  id: number
  patchedRegionRequest?: PatchedRegionRequest
}

export interface AdminApiPortfolioCreateRequest {
  time?: string | null
  price?: string | null
  name?: string | null
  gallery?: Array<PortfolioWorkGalleryRequest>
  isPublished?: boolean
  regionId?: number
  tagId?: number | null
  dummyFieldToHackOpenapiGenerator?: Blob | null
}

export interface AdminApiPortfolioDestroyRequest {
  id: number
}

export interface AdminApiPortfolioListRequest {
  page?: number
  regionId?: number
  size?: number
  tagId?: number
}

export interface AdminApiPortfolioPartialUpdateRequest {
  id: number
  time?: string | null
  price?: string | null
  name?: string | null
  gallery?: Array<PortfolioWorkGalleryRequest>
  isPublished?: boolean
  regionId?: number
  tagId?: number | null
  dummyFieldToHackOpenapiGenerator?: Blob | null
}

export interface AdminApiPortfolioRetrieveRequest {
  id: number
}

export interface AdminApiPortfolioTagCreateRequest {
  portfolioTagRequest: PortfolioTagRequest
}

export interface AdminApiPortfolioTagDestroyRequest {
  id: number
}

export interface AdminApiPortfolioTagListRequest {
  page?: number
  size?: number
}

export interface AdminApiPortfolioTagPartialUpdateRequest {
  id: number
  patchedPortfolioTagRequest?: PatchedPortfolioTagRequest
}

export interface AdminApiPortfolioTagRetrieveRequest {
  id: number
}

export interface AdminApiReviewCreateRequest {
  reviewRequest: ReviewRequest
}

export interface AdminApiReviewDestroyRequest {
  id: number
}

export interface AdminApiReviewListRequest {
  page?: number
  size?: number
}

export interface AdminApiReviewPartialUpdateRequest {
  id: number
  patchedReviewRequest?: PatchedReviewRequest
}

export interface AdminApiReviewRetrieveRequest {
  id: number
}

export interface AdminApiSeoCatalogLocationSeoFieldsDestroyRequest {
  id: number
}

export interface AdminApiSeoCatalogLocationSeoFieldsListRequest {
  catalogId: number
  regionId: number
  description?: string
  descriptionTag?: string
  header?: string
  locationName?: string
  page?: number
  size?: number
  titleTag?: string
}

export interface AdminApiSeoCatalogLocationSeoFieldsPartialUpdateRequest {
  id: number
  patchedCatalogLocationSeoFieldsRequest?: PatchedCatalogLocationSeoFieldsRequest
}

export interface AdminApiSeoCatalogLocationSeoFieldsRetrieveRequest {
  id: number
}

export interface AdminApiSeoDefaultsPartialUpdateRequest {
  id: number
  patchedSeoDefaultsRequest?: PatchedSeoDefaultsRequest
}

export interface AdminApiSeoDefaultsRetrieveRequest {
  entityType: AdminApiSeoDefaultsRetrieveEntityTypeEnum
  regionId: number
  seoType: AdminApiSeoDefaultsRetrieveSeoTypeEnum
  serviceTemplateType?: AdminApiSeoDefaultsRetrieveServiceTemplateTypeEnum
}

export interface AdminApiSeoServiceLocationSeoFieldsDestroyRequest {
  id: number
}

export interface AdminApiSeoServiceLocationSeoFieldsGenerateCreateRequest {
  genLocationsServiceRequest: GenLocationsServiceRequest
}

export interface AdminApiSeoServiceLocationSeoFieldsListRequest {
  regionId: number
  serviceId: number
  description?: string
  descriptionTag?: string
  header?: string
  locationName?: string
  page?: number
  size?: number
  titleTag?: string
}

export interface AdminApiSeoServiceLocationSeoFieldsPartialUpdateRequest {
  id: number
  patchedServiceLocationSeoFieldsRequest?: PatchedServiceLocationSeoFieldsRequest
}

export interface AdminApiSeoServiceLocationSeoFieldsRetrieveRequest {
  id: number
}

export interface AdminApiServiceCatalogArchivedCatalogListRequest {
  type: AdminApiServiceCatalogArchivedCatalogListTypeEnum
  page?: number
  size?: number
}

export interface AdminApiServiceCatalogCatalogSetVisibilityPartialUpdateRequest {
  patchedCatalogVisibilityRequest?: PatchedCatalogVisibilityRequest
}

export interface AdminApiServiceCatalogCategoryCreateRequest {
  name?: string
  iconImg?: Blob | null
  backgroundImg?: Blob | null
  tagId?: number | null
}

export interface AdminApiServiceCatalogCategoryDestroyRequest {
  id: number
}

export interface AdminApiServiceCatalogCategoryListRequest {
  page?: number
  regionId?: number
  size?: number
}

export interface AdminApiServiceCatalogCategoryPartialUpdateRequest {
  id: number
  name?: string
  iconImg?: Blob | null
  regionId?: number
  backgroundImg?: Blob | null
  isVisible?: boolean | null
  tagId?: number | null
  slug?: string | null
  headerMain?: string
  titleTagMain?: string
  descriptionTagMain?: string
  useLocalMetaMain?: boolean
  descriptionMain?: string
  useLocalDescriptionMain?: boolean
}

export interface AdminApiServiceCatalogCategoryRetrieveRequest {
  id: number
  regionId: number
}

export interface AdminApiServiceCatalogObjectListRequest {
  categoryId?: number
  page?: number
  regionId?: number
  size?: number
}

export interface AdminApiServiceCatalogObjectPartialUpdateRequest {
  id: number
  name?: string
  categoryId?: number
  iconImg?: Blob | null
  regionId?: number
  backgroundImg?: Blob | null
  isVisible?: boolean | null
  slug?: string | null
  additionalCategoriesIds?: string | null
  headerMain?: string
  titleTagMain?: string
  descriptionTagMain?: string
  useLocalMetaMain?: boolean
}

export interface AdminApiServiceCatalogObjectRetrieveRequest {
  id: number
  regionId: number
}

export interface AdminApiServiceCatalogServiceAdditionalWorkCreateRequest {
  serviceid: number
  additionalWorkCreateRequest: AdditionalWorkCreateRequest
}

export interface AdminApiServiceCatalogServiceAdditionalWorkDestroyRequest {
  id: number
  serviceid: number
}

export interface AdminApiServiceCatalogServiceAdditionalWorkListRequest {
  serviceid: number
  page?: number
  size?: number
}

export interface AdminApiServiceCatalogServiceListRequest {
  regionId: number
  categoryId?: number
  name?: string
  objectId?: number
  page?: number
  size?: number
}

export interface AdminApiServiceCatalogServicePartialUpdateRequest {
  id: number
  patchedServiceUpdateRequest?: PatchedServiceUpdateRequest
}

export interface AdminApiServiceCatalogServiceRetrieveRequest {
  id: number
  regionId: number
}

export interface AdminApiStaticPageListRequest {
  page?: number
  regionId?: number
  size?: number
}

export interface AdminApiStaticPagePartialUpdateRequest {
  id: number
  patchedStaticPageRequest?: PatchedStaticPageRequest
}

export interface AdminApiStaticPageRetrieveRequest {
  id: number
}

/**
 *
 */
export class AdminApiApi extends runtime.BaseAPI {
  /**
   */
  async adminApiApiMenuPartialUpdateRaw(
    requestParameters: AdminApiApiMenuPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedApiMenuUpdateAdmin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/api_menu/`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedApiMenuRequestToJSON(requestParameters.patchedApiMenuRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedApiMenuUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiApiMenuPartialUpdate(
    requestParameters: AdminApiApiMenuPartialUpdateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedApiMenuUpdateAdmin> {
    const response = await this.adminApiApiMenuPartialUpdateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiApiMenuRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedApiMenuApiMenuAdmin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/api_menu/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedApiMenuApiMenuAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiApiMenuRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedApiMenuApiMenuAdmin> {
    const response = await this.adminApiApiMenuRetrieveRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiCalculatorPartialUpdateRaw(
    requestParameters: AdminApiCalculatorPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCalculatorUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiCalculatorPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/calculator/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedCalculatorRequestToJSON(requestParameters.patchedCalculatorRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCalculatorUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiCalculatorPartialUpdate(
    requestParameters: AdminApiCalculatorPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCalculatorUpdateAdmin> {
    const response = await this.adminApiCalculatorPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiContentListRaw(
    requestParameters: AdminApiContentListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedContentListContentAdmin>> {
    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling adminApiContentList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.entityId !== undefined) {
      queryParameters['entityId'] = requestParameters.entityId
    }

    if (requestParameters.isGlobal !== undefined) {
      queryParameters['isGlobal'] = requestParameters.isGlobal
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.serviceTemplateType !== undefined) {
      queryParameters['serviceTemplateType'] = requestParameters.serviceTemplateType
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/content/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedContentListContentAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiContentList(
    requestParameters: AdminApiContentListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedContentListContentAdmin> {
    const response = await this.adminApiContentListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiContentPartialUpdateRaw(
    requestParameters: AdminApiContentPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedContentUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiContentPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.header !== undefined) {
      formParams.append('header', requestParameters.header as any)
    }

    if (requestParameters.otherData !== undefined) {
      formParams.append(
        'otherData',
        new Blob([JSON.stringify(CustomContentJSONRequestToJSON(requestParameters.otherData))], {
          type: 'application/json',
        }),
      )
    }

    if (requestParameters.entityId !== undefined) {
      formParams.append('entityId', requestParameters.entityId as any)
    }

    if (requestParameters.usedDefault !== undefined) {
      formParams.append('usedDefault', requestParameters.usedDefault as any)
    }

    if (requestParameters.cards) {
      formParams.append('cards', requestParameters.cards.join(runtime.COLLECTION_FORMATS['csv']))
    }

    if (requestParameters.iconImg !== undefined) {
      formParams.append('iconImg', requestParameters.iconImg as any)
    }

    const response = await this.request(
      {
        path: `/admin_api/content/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedContentUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiContentPartialUpdate(
    requestParameters: AdminApiContentPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedContentUpdateAdmin> {
    const response = await this.adminApiContentPartialUpdateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiContentRetrieveRaw(
    requestParameters: AdminApiContentRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedContentContentAdminRetrieve>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiContentRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/content/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedContentContentAdminRetrieveFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiContentRetrieve(
    requestParameters: AdminApiContentRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedContentContentAdminRetrieve> {
    const response = await this.adminApiContentRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiDocumentCreateRaw(
    requestParameters: AdminApiDocumentCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentCreateAdmin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/document/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DocumentRequestToJSON(requestParameters.documentRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiDocumentCreate(
    requestParameters: AdminApiDocumentCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentCreateAdmin> {
    const response = await this.adminApiDocumentCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiDocumentDestroyRaw(
    requestParameters: AdminApiDocumentDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiDocumentDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/document/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiDocumentDestroy(
    requestParameters: AdminApiDocumentDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentDestroyAdmin> {
    const response = await this.adminApiDocumentDestroyRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiDocumentListRaw(
    requestParameters: AdminApiDocumentListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentListDocumentAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/document/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentListDocumentAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiDocumentList(
    requestParameters: AdminApiDocumentListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentListDocumentAdmin> {
    const response = await this.adminApiDocumentListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiDocumentPartialUpdateRaw(
    requestParameters: AdminApiDocumentPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiDocumentPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/document/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedDocumentRequestToJSON(requestParameters.patchedDocumentRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiDocumentPartialUpdate(
    requestParameters: AdminApiDocumentPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentUpdateAdmin> {
    const response = await this.adminApiDocumentPartialUpdateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiDocumentRetrieveRaw(
    requestParameters: AdminApiDocumentRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedDocumentDocumentAdminDetail>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiDocumentRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/document/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedDocumentDocumentAdminDetailFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiDocumentRetrieve(
    requestParameters: AdminApiDocumentRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedDocumentDocumentAdminDetail> {
    const response = await this.adminApiDocumentRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiFaqCreateRaw(
    requestParameters: AdminApiFaqCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedFAQCreateAdmin>> {
    if (requestParameters.fAQRequest === null || requestParameters.fAQRequest === undefined) {
      throw new runtime.RequiredError(
        'fAQRequest',
        'Required parameter requestParameters.fAQRequest was null or undefined when calling adminApiFaqCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/faq/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FAQRequestToJSON(requestParameters.fAQRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedFAQCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiFaqCreate(
    requestParameters: AdminApiFaqCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedFAQCreateAdmin> {
    const response = await this.adminApiFaqCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiFaqDestroyRaw(
    requestParameters: AdminApiFaqDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedFAQDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiFaqDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/faq/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedFAQDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiFaqDestroy(
    requestParameters: AdminApiFaqDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedFAQDestroyAdmin> {
    const response = await this.adminApiFaqDestroyRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiFaqListRaw(
    requestParameters: AdminApiFaqListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedFAQListFAQAdmin>> {
    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiFaqList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.entityId !== undefined) {
      queryParameters['entityId'] = requestParameters.entityId
    }

    if (requestParameters.isGlobal !== undefined) {
      queryParameters['isGlobal'] = requestParameters.isGlobal
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.serviceTemplateType !== undefined) {
      queryParameters['serviceTemplateType'] = requestParameters.serviceTemplateType
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/faq/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedFAQListFAQAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiFaqList(
    requestParameters: AdminApiFaqListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedFAQListFAQAdmin> {
    const response = await this.adminApiFaqListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiFaqPartialUpdateRaw(
    requestParameters: AdminApiFaqPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedFAQUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiFaqPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/faq/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedFAQRequestToJSON(requestParameters.patchedFAQRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedFAQUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiFaqPartialUpdate(
    requestParameters: AdminApiFaqPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedFAQUpdateAdmin> {
    const response = await this.adminApiFaqPartialUpdateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiFaqSaveFullListPartialUpdateRaw(
    requestParameters: AdminApiFaqSaveFullListPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedFAQFullUpdateAdmin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/faq/save_full_list/`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedFAQUpdateListRequestToJSON(requestParameters.patchedFAQUpdateListRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedFAQFullUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiFaqSaveFullListPartialUpdate(
    requestParameters: AdminApiFaqSaveFullListPartialUpdateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedFAQFullUpdateAdmin> {
    const response = await this.adminApiFaqSaveFullListPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationCreateRaw(
    requestParameters: AdminApiLocationLocationCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationCreateAdmin>> {
    if (
      requestParameters.locationRequest === null ||
      requestParameters.locationRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'locationRequest',
        'Required parameter requestParameters.locationRequest was null or undefined when calling adminApiLocationLocationCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LocationRequestToJSON(requestParameters.locationRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationCreate(
    requestParameters: AdminApiLocationLocationCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationCreateAdmin> {
    const response = await this.adminApiLocationLocationCreateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationDestroyRaw(
    requestParameters: AdminApiLocationLocationDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiLocationLocationDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationDestroy(
    requestParameters: AdminApiLocationLocationDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationDestroyAdmin> {
    const response = await this.adminApiLocationLocationDestroyRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationListRaw(
    requestParameters: AdminApiLocationLocationListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationListLocationListAdmin>> {
    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiLocationLocationList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationListLocationListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationList(
    requestParameters: AdminApiLocationLocationListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationListLocationListAdmin> {
    const response = await this.adminApiLocationLocationListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationPartialUpdateRaw(
    requestParameters: AdminApiLocationLocationPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiLocationLocationPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedLocationRequestToJSON(requestParameters.patchedLocationRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationPartialUpdate(
    requestParameters: AdminApiLocationLocationPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationUpdateAdmin> {
    const response = await this.adminApiLocationLocationPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationTagCreateRaw(
    requestParameters: AdminApiLocationLocationTagCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationTagCreateAdmin>> {
    if (
      requestParameters.locationTagRequest === null ||
      requestParameters.locationTagRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'locationTagRequest',
        'Required parameter requestParameters.locationTagRequest was null or undefined when calling adminApiLocationLocationTagCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location_tag/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LocationTagRequestToJSON(requestParameters.locationTagRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationTagCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationTagCreate(
    requestParameters: AdminApiLocationLocationTagCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationTagCreateAdmin> {
    const response = await this.adminApiLocationLocationTagCreateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationTagDestroyRaw(
    requestParameters: AdminApiLocationLocationTagDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationTagDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiLocationLocationTagDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location_tag/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationTagDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationTagDestroy(
    requestParameters: AdminApiLocationLocationTagDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationTagDestroyAdmin> {
    const response = await this.adminApiLocationLocationTagDestroyRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationTagListRaw(
    requestParameters: AdminApiLocationLocationTagListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationTagListLocationTagListAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location_tag/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationTagListLocationTagListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationTagList(
    requestParameters: AdminApiLocationLocationTagListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationTagListLocationTagListAdmin> {
    const response = await this.adminApiLocationLocationTagListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationLocationTagPartialUpdateRaw(
    requestParameters: AdminApiLocationLocationTagPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationTagUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiLocationLocationTagPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/location_tag/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedLocationTagRequestToJSON(requestParameters.patchedLocationTagRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationTagUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationLocationTagPartialUpdate(
    requestParameters: AdminApiLocationLocationTagPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationTagUpdateAdmin> {
    const response = await this.adminApiLocationLocationTagPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiLocationRegionCreateRaw(
    requestParameters: AdminApiLocationRegionCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedRegionCreateAdmin>> {
    if (
      requestParameters.regionRequest === null ||
      requestParameters.regionRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'regionRequest',
        'Required parameter requestParameters.regionRequest was null or undefined when calling adminApiLocationRegionCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/region/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RegionRequestToJSON(requestParameters.regionRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedRegionCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationRegionCreate(
    requestParameters: AdminApiLocationRegionCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedRegionCreateAdmin> {
    const response = await this.adminApiLocationRegionCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiLocationRegionListRaw(
    requestParameters: AdminApiLocationRegionListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedRegionListRegionListAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/region/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedRegionListRegionListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationRegionList(
    requestParameters: AdminApiLocationRegionListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedRegionListRegionListAdmin> {
    const response = await this.adminApiLocationRegionListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiLocationRegionPartialUpdateRaw(
    requestParameters: AdminApiLocationRegionPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedRegionUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiLocationRegionPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/location/region/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedRegionRequestToJSON(requestParameters.patchedRegionRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedRegionUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiLocationRegionPartialUpdate(
    requestParameters: AdminApiLocationRegionPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedRegionUpdateAdmin> {
    const response = await this.adminApiLocationRegionPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioCreateRaw(
    requestParameters: AdminApiPortfolioCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioWorkCreate123Admin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.time !== undefined) {
      formParams.append('time', requestParameters.time as any)
    }

    if (requestParameters.price !== undefined) {
      formParams.append('price', requestParameters.price as any)
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any)
    }

    if (requestParameters.gallery) {
      formParams.append(
        'gallery',
        requestParameters.gallery.join(runtime.COLLECTION_FORMATS['csv']),
      )
    }

    if (requestParameters.isPublished !== undefined) {
      formParams.append('isPublished', requestParameters.isPublished as any)
    }

    if (requestParameters.regionId !== undefined) {
      formParams.append('regionId', requestParameters.regionId as any)
    }

    if (requestParameters.tagId !== undefined) {
      formParams.append('tagId', requestParameters.tagId as any)
    }

    if (requestParameters.dummyFieldToHackOpenapiGenerator !== undefined) {
      formParams.append(
        'dummyFieldToHackOpenapiGenerator',
        requestParameters.dummyFieldToHackOpenapiGenerator as any,
      )
    }

    const response = await this.request(
      {
        path: `/admin_api/portfolio/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkCreate123AdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioCreate(
    requestParameters: AdminApiPortfolioCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkCreate123Admin> {
    const response = await this.adminApiPortfolioCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioDestroyRaw(
    requestParameters: AdminApiPortfolioDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioWorkDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiPortfolioDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioDestroy(
    requestParameters: AdminApiPortfolioDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkDestroyAdmin> {
    const response = await this.adminApiPortfolioDestroyRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioListRaw(
    requestParameters: AdminApiPortfolioListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioWorkListPortfolioWorkListAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.tagId !== undefined) {
      queryParameters['tagId'] = requestParameters.tagId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkListPortfolioWorkListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioList(
    requestParameters: AdminApiPortfolioListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkListPortfolioWorkListAdmin> {
    const response = await this.adminApiPortfolioListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioPartialUpdateRaw(
    requestParameters: AdminApiPortfolioPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioWorkUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiPortfolioPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.time !== undefined) {
      formParams.append('time', requestParameters.time as any)
    }

    if (requestParameters.price !== undefined) {
      formParams.append('price', requestParameters.price as any)
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any)
    }

    if (requestParameters.gallery) {
      formParams.append(
        'gallery',
        requestParameters.gallery.join(runtime.COLLECTION_FORMATS['csv']),
      )
    }

    if (requestParameters.isPublished !== undefined) {
      formParams.append('isPublished', requestParameters.isPublished as any)
    }

    if (requestParameters.regionId !== undefined) {
      formParams.append('regionId', requestParameters.regionId as any)
    }

    if (requestParameters.tagId !== undefined) {
      formParams.append('tagId', requestParameters.tagId as any)
    }

    if (requestParameters.dummyFieldToHackOpenapiGenerator !== undefined) {
      formParams.append(
        'dummyFieldToHackOpenapiGenerator',
        requestParameters.dummyFieldToHackOpenapiGenerator as any,
      )
    }

    const response = await this.request(
      {
        path: `/admin_api/portfolio/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioPartialUpdate(
    requestParameters: AdminApiPortfolioPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkUpdateAdmin> {
    const response = await this.adminApiPortfolioPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioRetrieveRaw(
    requestParameters: AdminApiPortfolioRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioWorkPortfolioWorkAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiPortfolioRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioWorkPortfolioWorkAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioRetrieve(
    requestParameters: AdminApiPortfolioRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioWorkPortfolioWorkAdmin> {
    const response = await this.adminApiPortfolioRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioTagCreateRaw(
    requestParameters: AdminApiPortfolioTagCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioTagCreateAdmin>> {
    if (
      requestParameters.portfolioTagRequest === null ||
      requestParameters.portfolioTagRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'portfolioTagRequest',
        'Required parameter requestParameters.portfolioTagRequest was null or undefined when calling adminApiPortfolioTagCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/tag/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PortfolioTagRequestToJSON(requestParameters.portfolioTagRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioTagCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioTagCreate(
    requestParameters: AdminApiPortfolioTagCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioTagCreateAdmin> {
    const response = await this.adminApiPortfolioTagCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioTagDestroyRaw(
    requestParameters: AdminApiPortfolioTagDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioTagDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiPortfolioTagDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/tag/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioTagDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioTagDestroy(
    requestParameters: AdminApiPortfolioTagDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioTagDestroyAdmin> {
    const response = await this.adminApiPortfolioTagDestroyRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioTagListRaw(
    requestParameters: AdminApiPortfolioTagListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioTagListPortfolioTagListAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/tag/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioTagListPortfolioTagListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioTagList(
    requestParameters: AdminApiPortfolioTagListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioTagListPortfolioTagListAdmin> {
    const response = await this.adminApiPortfolioTagListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioTagPartialUpdateRaw(
    requestParameters: AdminApiPortfolioTagPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioTagUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiPortfolioTagPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/tag/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedPortfolioTagRequestToJSON(requestParameters.patchedPortfolioTagRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioTagUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioTagPartialUpdate(
    requestParameters: AdminApiPortfolioTagPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioTagUpdateAdmin> {
    const response = await this.adminApiPortfolioTagPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiPortfolioTagRetrieveRaw(
    requestParameters: AdminApiPortfolioTagRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedPortfolioTagPortfolioTagAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiPortfolioTagRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/portfolio/tag/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedPortfolioTagPortfolioTagAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiPortfolioTagRetrieve(
    requestParameters: AdminApiPortfolioTagRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedPortfolioTagPortfolioTagAdmin> {
    const response = await this.adminApiPortfolioTagRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiReviewCreateRaw(
    requestParameters: AdminApiReviewCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedReviewAdminCreate>> {
    if (
      requestParameters.reviewRequest === null ||
      requestParameters.reviewRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'reviewRequest',
        'Required parameter requestParameters.reviewRequest was null or undefined when calling adminApiReviewCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/review/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ReviewRequestToJSON(requestParameters.reviewRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedReviewAdminCreateFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiReviewCreate(
    requestParameters: AdminApiReviewCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedReviewAdminCreate> {
    const response = await this.adminApiReviewCreateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiReviewDestroyRaw(
    requestParameters: AdminApiReviewDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedReviewAdminDestroy>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiReviewDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/review/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedReviewAdminDestroyFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiReviewDestroy(
    requestParameters: AdminApiReviewDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedReviewAdminDestroy> {
    const response = await this.adminApiReviewDestroyRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiReviewListRaw(
    requestParameters: AdminApiReviewListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedReviewListReviewAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/review/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedReviewListReviewAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiReviewList(
    requestParameters: AdminApiReviewListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedReviewListReviewAdmin> {
    const response = await this.adminApiReviewListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiReviewPartialUpdateRaw(
    requestParameters: AdminApiReviewPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedReviewAdminUpdate>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiReviewPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/review/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedReviewRequestToJSON(requestParameters.patchedReviewRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedReviewAdminUpdateFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiReviewPartialUpdate(
    requestParameters: AdminApiReviewPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedReviewAdminUpdate> {
    const response = await this.adminApiReviewPartialUpdateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiReviewRetrieveRaw(
    requestParameters: AdminApiReviewRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedReviewReviewAdminDetail>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiReviewRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/review/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedReviewReviewAdminDetailFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiReviewRetrieve(
    requestParameters: AdminApiReviewRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedReviewReviewAdminDetail> {
    const response = await this.adminApiReviewRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsDestroyRaw(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCatalogLocationSeoFieldsDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoCatalogLocationSeoFieldsDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/catalog_location_seo_fields/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCatalogLocationSeoFieldsDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsDestroy(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCatalogLocationSeoFieldsDestroyAdmin> {
    const response = await this.adminApiSeoCatalogLocationSeoFieldsDestroyRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsListRaw(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<EnvelopedCatalogLocationSeoFieldsListCatalogLocationSeoFieldsAdmin>
  > {
    if (requestParameters.catalogId === null || requestParameters.catalogId === undefined) {
      throw new runtime.RequiredError(
        'catalogId',
        'Required parameter requestParameters.catalogId was null or undefined when calling adminApiSeoCatalogLocationSeoFieldsList.',
      )
    }

    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiSeoCatalogLocationSeoFieldsList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.catalogId !== undefined) {
      queryParameters['catalogId'] = requestParameters.catalogId
    }

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description
    }

    if (requestParameters.descriptionTag !== undefined) {
      queryParameters['descriptionTag'] = requestParameters.descriptionTag
    }

    if (requestParameters.header !== undefined) {
      queryParameters['header'] = requestParameters.header
    }

    if (requestParameters.locationName !== undefined) {
      queryParameters['locationName'] = requestParameters.locationName
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.titleTag !== undefined) {
      queryParameters['titleTag'] = requestParameters.titleTag
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/catalog_location_seo_fields/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCatalogLocationSeoFieldsListCatalogLocationSeoFieldsAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsList(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCatalogLocationSeoFieldsListCatalogLocationSeoFieldsAdmin> {
    const response = await this.adminApiSeoCatalogLocationSeoFieldsListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsPartialUpdateRaw(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCatalogLocationSeoFieldsUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoCatalogLocationSeoFieldsPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/catalog_location_seo_fields/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedCatalogLocationSeoFieldsRequestToJSON(
          requestParameters.patchedCatalogLocationSeoFieldsRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCatalogLocationSeoFieldsUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsPartialUpdate(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCatalogLocationSeoFieldsUpdateAdmin> {
    const response = await this.adminApiSeoCatalogLocationSeoFieldsPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsRetrieveRaw(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin>
  > {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoCatalogLocationSeoFieldsRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/catalog_location_seo_fields/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoCatalogLocationSeoFieldsRetrieve(
    requestParameters: AdminApiSeoCatalogLocationSeoFieldsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCatalogLocationSeoFieldsCatalogLocationSeoFieldsAdmin> {
    const response = await this.adminApiSeoCatalogLocationSeoFieldsRetrieveRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoDefaultsPartialUpdateRaw(
    requestParameters: AdminApiSeoDefaultsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedSeoDefaultsUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoDefaultsPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/defaults/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedSeoDefaultsRequestToJSON(requestParameters.patchedSeoDefaultsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedSeoDefaultsUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoDefaultsPartialUpdate(
    requestParameters: AdminApiSeoDefaultsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedSeoDefaultsUpdateAdmin> {
    const response = await this.adminApiSeoDefaultsPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoDefaultsRetrieveRaw(
    requestParameters: AdminApiSeoDefaultsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedSeoDefaultsSeoDefaultsAdmin>> {
    if (requestParameters.entityType === null || requestParameters.entityType === undefined) {
      throw new runtime.RequiredError(
        'entityType',
        'Required parameter requestParameters.entityType was null or undefined when calling adminApiSeoDefaultsRetrieve.',
      )
    }

    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiSeoDefaultsRetrieve.',
      )
    }

    if (requestParameters.seoType === null || requestParameters.seoType === undefined) {
      throw new runtime.RequiredError(
        'seoType',
        'Required parameter requestParameters.seoType was null or undefined when calling adminApiSeoDefaultsRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.entityType !== undefined) {
      queryParameters['entityType'] = requestParameters.entityType
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.seoType !== undefined) {
      queryParameters['seoType'] = requestParameters.seoType
    }

    if (requestParameters.serviceTemplateType !== undefined) {
      queryParameters['serviceTemplateType'] = requestParameters.serviceTemplateType
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/defaults/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedSeoDefaultsSeoDefaultsAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoDefaultsRetrieve(
    requestParameters: AdminApiSeoDefaultsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedSeoDefaultsSeoDefaultsAdmin> {
    const response = await this.adminApiSeoDefaultsRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsDestroyRaw(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceLocationSeoFieldsDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoServiceLocationSeoFieldsDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/service_location_seo_fields/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceLocationSeoFieldsDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsDestroy(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceLocationSeoFieldsDestroyAdmin> {
    const response = await this.adminApiSeoServiceLocationSeoFieldsDestroyRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsGenerateCreateRaw(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsGenerateCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceLocationSeoFieldsGenerate>> {
    if (
      requestParameters.genLocationsServiceRequest === null ||
      requestParameters.genLocationsServiceRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'genLocationsServiceRequest',
        'Required parameter requestParameters.genLocationsServiceRequest was null or undefined when calling adminApiSeoServiceLocationSeoFieldsGenerateCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/service_location_seo_fields/generate/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GenLocationsServiceRequestToJSON(requestParameters.genLocationsServiceRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceLocationSeoFieldsGenerateFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsGenerateCreate(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsGenerateCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceLocationSeoFieldsGenerate> {
    const response = await this.adminApiSeoServiceLocationSeoFieldsGenerateCreateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsListRaw(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<EnvelopedServiceLocationSeoFieldsListServiceLocationSeoFieldsAdmin>
  > {
    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiSeoServiceLocationSeoFieldsList.',
      )
    }

    if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
      throw new runtime.RequiredError(
        'serviceId',
        'Required parameter requestParameters.serviceId was null or undefined when calling adminApiSeoServiceLocationSeoFieldsList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description
    }

    if (requestParameters.descriptionTag !== undefined) {
      queryParameters['descriptionTag'] = requestParameters.descriptionTag
    }

    if (requestParameters.header !== undefined) {
      queryParameters['header'] = requestParameters.header
    }

    if (requestParameters.locationName !== undefined) {
      queryParameters['locationName'] = requestParameters.locationName
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.serviceId !== undefined) {
      queryParameters['serviceId'] = requestParameters.serviceId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.titleTag !== undefined) {
      queryParameters['titleTag'] = requestParameters.titleTag
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/service_location_seo_fields/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceLocationSeoFieldsListServiceLocationSeoFieldsAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsList(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceLocationSeoFieldsListServiceLocationSeoFieldsAdmin> {
    const response = await this.adminApiSeoServiceLocationSeoFieldsListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsPartialUpdateRaw(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceLocationSeoFieldsUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoServiceLocationSeoFieldsPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/service_location_seo_fields/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedServiceLocationSeoFieldsRequestToJSON(
          requestParameters.patchedServiceLocationSeoFieldsRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceLocationSeoFieldsUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsPartialUpdate(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceLocationSeoFieldsUpdateAdmin> {
    const response = await this.adminApiSeoServiceLocationSeoFieldsPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsRetrieveRaw(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<EnvelopedServiceLocationSeoFieldsServiceLocationSeoFieldsAdmin>
  > {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiSeoServiceLocationSeoFieldsRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/seo/service_location_seo_fields/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceLocationSeoFieldsServiceLocationSeoFieldsAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSeoServiceLocationSeoFieldsRetrieve(
    requestParameters: AdminApiSeoServiceLocationSeoFieldsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceLocationSeoFieldsServiceLocationSeoFieldsAdmin> {
    const response = await this.adminApiSeoServiceLocationSeoFieldsRetrieveRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogArchivedCatalogListRaw(
    requestParameters: AdminApiServiceCatalogArchivedCatalogListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedArchivedCatalogCatalogArchivedAdmin>> {
    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling adminApiServiceCatalogArchivedCatalogList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/archived_catalog/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedArchivedCatalogCatalogArchivedAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogArchivedCatalogList(
    requestParameters: AdminApiServiceCatalogArchivedCatalogListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedArchivedCatalogCatalogArchivedAdmin> {
    const response = await this.adminApiServiceCatalogArchivedCatalogListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCatalogListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedOpenApiCatalogList>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/catalog/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedOpenApiCatalogListFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCatalogList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedOpenApiCatalogList> {
    const response = await this.adminApiServiceCatalogCatalogListRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCatalogSetVisibilityPartialUpdateRaw(
    requestParameters: AdminApiServiceCatalogCatalogSetVisibilityPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCatalogSetVisibilityAdmin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/catalog/set_visibility/`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedCatalogVisibilityRequestToJSON(
          requestParameters.patchedCatalogVisibilityRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCatalogSetVisibilityAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCatalogSetVisibilityPartialUpdate(
    requestParameters: AdminApiServiceCatalogCatalogSetVisibilityPartialUpdateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCatalogSetVisibilityAdmin> {
    const response = await this.adminApiServiceCatalogCatalogSetVisibilityPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCategoryCreateRaw(
    requestParameters: AdminApiServiceCatalogCategoryCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryCreateAdmin>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any)
    }

    if (requestParameters.iconImg !== undefined) {
      formParams.append('iconImg', requestParameters.iconImg as any)
    }

    if (requestParameters.backgroundImg !== undefined) {
      formParams.append('backgroundImg', requestParameters.backgroundImg as any)
    }

    if (requestParameters.tagId !== undefined) {
      formParams.append('tagId', requestParameters.tagId as any)
    }

    const response = await this.request(
      {
        path: `/admin_api/service_catalog/category/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryCreateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCategoryCreate(
    requestParameters: AdminApiServiceCatalogCategoryCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryCreateAdmin> {
    const response = await this.adminApiServiceCatalogCategoryCreateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCategoryDestroyRaw(
    requestParameters: AdminApiServiceCatalogCategoryDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogCategoryDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/category/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCategoryDestroy(
    requestParameters: AdminApiServiceCatalogCategoryDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryDestroyAdmin> {
    const response = await this.adminApiServiceCatalogCategoryDestroyRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCategoryListRaw(
    requestParameters: AdminApiServiceCatalogCategoryListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryListCategoryAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/category/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryListCategoryAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCategoryList(
    requestParameters: AdminApiServiceCatalogCategoryListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryListCategoryAdmin> {
    const response = await this.adminApiServiceCatalogCategoryListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCategoryPartialUpdateRaw(
    requestParameters: AdminApiServiceCatalogCategoryPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogCategoryPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any)
    }

    if (requestParameters.iconImg !== undefined) {
      formParams.append('iconImg', requestParameters.iconImg as any)
    }

    if (requestParameters.regionId !== undefined) {
      formParams.append('regionId', requestParameters.regionId as any)
    }

    if (requestParameters.backgroundImg !== undefined) {
      formParams.append('backgroundImg', requestParameters.backgroundImg as any)
    }

    if (requestParameters.isVisible !== undefined) {
      formParams.append('isVisible', requestParameters.isVisible as any)
    }

    if (requestParameters.tagId !== undefined) {
      formParams.append('tagId', requestParameters.tagId as any)
    }

    if (requestParameters.slug !== undefined) {
      formParams.append('slug', requestParameters.slug as any)
    }

    if (requestParameters.headerMain !== undefined) {
      formParams.append('headerMain', requestParameters.headerMain as any)
    }

    if (requestParameters.titleTagMain !== undefined) {
      formParams.append('titleTagMain', requestParameters.titleTagMain as any)
    }

    if (requestParameters.descriptionTagMain !== undefined) {
      formParams.append('descriptionTagMain', requestParameters.descriptionTagMain as any)
    }

    if (requestParameters.useLocalMetaMain !== undefined) {
      formParams.append('useLocalMetaMain', requestParameters.useLocalMetaMain as any)
    }

    if (requestParameters.descriptionMain !== undefined) {
      formParams.append('descriptionMain', requestParameters.descriptionMain as any)
    }

    if (requestParameters.useLocalDescriptionMain !== undefined) {
      formParams.append(
        'useLocalDescriptionMain',
        requestParameters.useLocalDescriptionMain as any,
      )
    }

    const response = await this.request(
      {
        path: `/admin_api/service_catalog/category/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCategoryPartialUpdate(
    requestParameters: AdminApiServiceCatalogCategoryPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryUpdateAdmin> {
    const response = await this.adminApiServiceCatalogCategoryPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogCategoryRetrieveRaw(
    requestParameters: AdminApiServiceCatalogCategoryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryCategoryDetailAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogCategoryRetrieve.',
      )
    }

    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiServiceCatalogCategoryRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/category/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryCategoryDetailAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogCategoryRetrieve(
    requestParameters: AdminApiServiceCatalogCategoryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryCategoryDetailAdmin> {
    const response = await this.adminApiServiceCatalogCategoryRetrieveRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogObjectListRaw(
    requestParameters: AdminApiServiceCatalogObjectListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedObjectListObjectListAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.categoryId !== undefined) {
      queryParameters['categoryId'] = requestParameters.categoryId
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/object/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedObjectListObjectListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogObjectList(
    requestParameters: AdminApiServiceCatalogObjectListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedObjectListObjectListAdmin> {
    const response = await this.adminApiServiceCatalogObjectListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogObjectPartialUpdateRaw(
    requestParameters: AdminApiServiceCatalogObjectPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedObjectUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogObjectPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any)
    }

    if (requestParameters.categoryId !== undefined) {
      formParams.append('categoryId', requestParameters.categoryId as any)
    }

    if (requestParameters.iconImg !== undefined) {
      formParams.append('iconImg', requestParameters.iconImg as any)
    }

    if (requestParameters.regionId !== undefined) {
      formParams.append('regionId', requestParameters.regionId as any)
    }

    if (requestParameters.backgroundImg !== undefined) {
      formParams.append('backgroundImg', requestParameters.backgroundImg as any)
    }

    if (requestParameters.isVisible !== undefined) {
      formParams.append('isVisible', requestParameters.isVisible as any)
    }

    if (requestParameters.slug !== undefined) {
      formParams.append('slug', requestParameters.slug as any)
    }

    if (requestParameters.additionalCategoriesIds !== undefined) {
      formParams.append(
        'additionalCategoriesIds',
        requestParameters.additionalCategoriesIds as any,
      )
    }

    if (requestParameters.headerMain !== undefined) {
      formParams.append('headerMain', requestParameters.headerMain as any)
    }

    if (requestParameters.titleTagMain !== undefined) {
      formParams.append('titleTagMain', requestParameters.titleTagMain as any)
    }

    if (requestParameters.descriptionTagMain !== undefined) {
      formParams.append('descriptionTagMain', requestParameters.descriptionTagMain as any)
    }

    if (requestParameters.useLocalMetaMain !== undefined) {
      formParams.append('useLocalMetaMain', requestParameters.useLocalMetaMain as any)
    }

    const response = await this.request(
      {
        path: `/admin_api/service_catalog/object/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedObjectUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogObjectPartialUpdate(
    requestParameters: AdminApiServiceCatalogObjectPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedObjectUpdateAdmin> {
    const response = await this.adminApiServiceCatalogObjectPartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogObjectRetrieveRaw(
    requestParameters: AdminApiServiceCatalogObjectRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedObjectObjectDetailAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogObjectRetrieve.',
      )
    }

    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiServiceCatalogObjectRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/object/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedObjectObjectDetailAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogObjectRetrieve(
    requestParameters: AdminApiServiceCatalogObjectRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedObjectObjectDetailAdmin> {
    const response = await this.adminApiServiceCatalogObjectRetrieveRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogServiceAdditionalWorkCreateRaw(
    requestParameters: AdminApiServiceCatalogServiceAdditionalWorkCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedAdditinalWorkCreate>> {
    if (requestParameters.serviceid === null || requestParameters.serviceid === undefined) {
      throw new runtime.RequiredError(
        'serviceid',
        'Required parameter requestParameters.serviceid was null or undefined when calling adminApiServiceCatalogServiceAdditionalWorkCreate.',
      )
    }

    if (
      requestParameters.additionalWorkCreateRequest === null ||
      requestParameters.additionalWorkCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'additionalWorkCreateRequest',
        'Required parameter requestParameters.additionalWorkCreateRequest was null or undefined when calling adminApiServiceCatalogServiceAdditionalWorkCreate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/service/{serviceid}/additional_work/`.replace(
          `{${'serviceid'}}`,
          encodeURIComponent(String(requestParameters.serviceid)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AdditionalWorkCreateRequestToJSON(requestParameters.additionalWorkCreateRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedAdditinalWorkCreateFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogServiceAdditionalWorkCreate(
    requestParameters: AdminApiServiceCatalogServiceAdditionalWorkCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedAdditinalWorkCreate> {
    const response = await this.adminApiServiceCatalogServiceAdditionalWorkCreateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogServiceAdditionalWorkDestroyRaw(
    requestParameters: AdminApiServiceCatalogServiceAdditionalWorkDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedAdditionalWorkDestroyAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogServiceAdditionalWorkDestroy.',
      )
    }

    if (requestParameters.serviceid === null || requestParameters.serviceid === undefined) {
      throw new runtime.RequiredError(
        'serviceid',
        'Required parameter requestParameters.serviceid was null or undefined when calling adminApiServiceCatalogServiceAdditionalWorkDestroy.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/service/{serviceid}/additional_work/{id}/`
          .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${'serviceid'}}`, encodeURIComponent(String(requestParameters.serviceid))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedAdditionalWorkDestroyAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogServiceAdditionalWorkDestroy(
    requestParameters: AdminApiServiceCatalogServiceAdditionalWorkDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedAdditionalWorkDestroyAdmin> {
    const response = await this.adminApiServiceCatalogServiceAdditionalWorkDestroyRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogServiceAdditionalWorkListRaw(
    requestParameters: AdminApiServiceCatalogServiceAdditionalWorkListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceListAdditionalWorkListAdmin>> {
    if (requestParameters.serviceid === null || requestParameters.serviceid === undefined) {
      throw new runtime.RequiredError(
        'serviceid',
        'Required parameter requestParameters.serviceid was null or undefined when calling adminApiServiceCatalogServiceAdditionalWorkList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/service/{serviceid}/additional_work/`.replace(
          `{${'serviceid'}}`,
          encodeURIComponent(String(requestParameters.serviceid)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceListAdditionalWorkListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogServiceAdditionalWorkList(
    requestParameters: AdminApiServiceCatalogServiceAdditionalWorkListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceListAdditionalWorkListAdmin> {
    const response = await this.adminApiServiceCatalogServiceAdditionalWorkListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogServiceListRaw(
    requestParameters: AdminApiServiceCatalogServiceListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceListServiceListAdmin>> {
    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiServiceCatalogServiceList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.categoryId !== undefined) {
      queryParameters['categoryId'] = requestParameters.categoryId
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name
    }

    if (requestParameters.objectId !== undefined) {
      queryParameters['objectId'] = requestParameters.objectId
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/service/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceListServiceListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogServiceList(
    requestParameters: AdminApiServiceCatalogServiceListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceListServiceListAdmin> {
    const response = await this.adminApiServiceCatalogServiceListRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogServicePartialUpdateRaw(
    requestParameters: AdminApiServiceCatalogServicePartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Enveloped>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogServicePartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/service/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedServiceUpdateRequestToJSON(requestParameters.patchedServiceUpdateRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => EnvelopedFromJSON(jsonValue))
  }

  /**
   */
  async adminApiServiceCatalogServicePartialUpdate(
    requestParameters: AdminApiServiceCatalogServicePartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Enveloped> {
    const response = await this.adminApiServiceCatalogServicePartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiServiceCatalogServiceRetrieveRaw(
    requestParameters: AdminApiServiceCatalogServiceRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceServiceDetailAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiServiceCatalogServiceRetrieve.',
      )
    }

    if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling adminApiServiceCatalogServiceRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/service_catalog/service/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceServiceDetailAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiServiceCatalogServiceRetrieve(
    requestParameters: AdminApiServiceCatalogServiceRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceServiceDetailAdmin> {
    const response = await this.adminApiServiceCatalogServiceRetrieveRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiStaticPageListRaw(
    requestParameters: AdminApiStaticPageListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedStaticPageListStaticPageAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/static_page/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedStaticPageListStaticPageAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiStaticPageList(
    requestParameters: AdminApiStaticPageListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedStaticPageListStaticPageAdmin> {
    const response = await this.adminApiStaticPageListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiStaticPagePartialUpdateRaw(
    requestParameters: AdminApiStaticPagePartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedStaticPageUpdateAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiStaticPagePartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/static_page/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedStaticPageRequestToJSON(requestParameters.patchedStaticPageRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedStaticPageUpdateAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiStaticPagePartialUpdate(
    requestParameters: AdminApiStaticPagePartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedStaticPageUpdateAdmin> {
    const response = await this.adminApiStaticPagePartialUpdateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async adminApiStaticPageRetrieveRaw(
    requestParameters: AdminApiStaticPageRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedStaticPageStaticPageDetailAdmin>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling adminApiStaticPageRetrieve.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/static_page/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedStaticPageStaticPageDetailAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiStaticPageRetrieve(
    requestParameters: AdminApiStaticPageRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedStaticPageStaticPageDetailAdmin> {
    const response = await this.adminApiStaticPageRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiSyncCrmRunCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedSyncData>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/sync_crm/run/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedSyncDataFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSyncCrmRunCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedSyncData> {
    const response = await this.adminApiSyncCrmRunCreateRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  async adminApiSyncCrmStatusRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedStatusSyncStatusRetreive>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/admin_api/sync_crm/status/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedStatusSyncStatusRetreiveFromJSON(jsonValue),
    )
  }

  /**
   */
  async adminApiSyncCrmStatusRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedStatusSyncStatusRetreive> {
    const response = await this.adminApiSyncCrmStatusRetrieveRaw(initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const AdminApiContentListTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type AdminApiContentListTypeEnum =
  (typeof AdminApiContentListTypeEnum)[keyof typeof AdminApiContentListTypeEnum]
/**
 * @export
 */
export const AdminApiContentListServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type AdminApiContentListServiceTemplateTypeEnum =
  (typeof AdminApiContentListServiceTemplateTypeEnum)[keyof typeof AdminApiContentListServiceTemplateTypeEnum]
/**
 * @export
 */
export const AdminApiFaqListServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type AdminApiFaqListServiceTemplateTypeEnum =
  (typeof AdminApiFaqListServiceTemplateTypeEnum)[keyof typeof AdminApiFaqListServiceTemplateTypeEnum]
/**
 * @export
 */
export const AdminApiFaqListTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type AdminApiFaqListTypeEnum =
  (typeof AdminApiFaqListTypeEnum)[keyof typeof AdminApiFaqListTypeEnum]
/**
 * @export
 */
export const AdminApiSeoDefaultsRetrieveEntityTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
} as const
export type AdminApiSeoDefaultsRetrieveEntityTypeEnum =
  (typeof AdminApiSeoDefaultsRetrieveEntityTypeEnum)[keyof typeof AdminApiSeoDefaultsRetrieveEntityTypeEnum]
/**
 * @export
 */
export const AdminApiSeoDefaultsRetrieveSeoTypeEnum = {
  Main: 'main',
  Location: 'location',
} as const
export type AdminApiSeoDefaultsRetrieveSeoTypeEnum =
  (typeof AdminApiSeoDefaultsRetrieveSeoTypeEnum)[keyof typeof AdminApiSeoDefaultsRetrieveSeoTypeEnum]
/**
 * @export
 */
export const AdminApiSeoDefaultsRetrieveServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type AdminApiSeoDefaultsRetrieveServiceTemplateTypeEnum =
  (typeof AdminApiSeoDefaultsRetrieveServiceTemplateTypeEnum)[keyof typeof AdminApiSeoDefaultsRetrieveServiceTemplateTypeEnum]
/**
 * @export
 */
export const AdminApiServiceCatalogArchivedCatalogListTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
} as const
export type AdminApiServiceCatalogArchivedCatalogListTypeEnum =
  (typeof AdminApiServiceCatalogArchivedCatalogListTypeEnum)[keyof typeof AdminApiServiceCatalogArchivedCatalogListTypeEnum]
