/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MainPageTag
 */
export interface MainPageTag {
  /**
   *
   * @type {number}
   * @memberof MainPageTag
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof MainPageTag
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof MainPageTag
   */
  customId?: number | null
}

/**
 * Check if a given object implements the MainPageTag interface.
 */
export function instanceOfMainPageTag(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function MainPageTagFromJSON(json: any): MainPageTag {
  return MainPageTagFromJSONTyped(json, false)
}

export function MainPageTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): MainPageTag {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    customId: !exists(json, 'customId') ? undefined : json['customId'],
  }
}

export function MainPageTagToJSON(value?: MainPageTag | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    customId: value.customId,
  }
}
