/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrderNumber
 */
export interface OrderNumber {
  /**
   *
   * @type {number}
   * @memberof OrderNumber
   */
  orderNumber: number
}

/**
 * Check if a given object implements the OrderNumber interface.
 */
export function instanceOfOrderNumber(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'orderNumber' in value

  return isInstance
}

export function OrderNumberFromJSON(json: any): OrderNumber {
  return OrderNumberFromJSONTyped(json, false)
}

export function OrderNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderNumber {
  if (json === undefined || json === null) {
    return json
  }
  return {
    orderNumber: json['orderNumber'],
  }
}

export function OrderNumberToJSON(value?: OrderNumber | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    orderNumber: value.orderNumber,
  }
}
