/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PaginatedObjectListAdmin } from './PaginatedObjectListAdmin'
import {
  PaginatedObjectListAdminFromJSON,
  PaginatedObjectListAdminFromJSONTyped,
  PaginatedObjectListAdminToJSON,
} from './PaginatedObjectListAdmin'

/**
 *
 * @export
 * @interface EnvelopedObjectListObjectListAdmin
 */
export interface EnvelopedObjectListObjectListAdmin {
  /**
   *
   * @type {PaginatedObjectListAdmin}
   * @memberof EnvelopedObjectListObjectListAdmin
   */
  body: PaginatedObjectListAdmin
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedObjectListObjectListAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedObjectListObjectListAdmin interface.
 */
export function instanceOfEnvelopedObjectListObjectListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedObjectListObjectListAdminFromJSON(
  json: any,
): EnvelopedObjectListObjectListAdmin {
  return EnvelopedObjectListObjectListAdminFromJSONTyped(json, false)
}

export function EnvelopedObjectListObjectListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedObjectListObjectListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PaginatedObjectListAdminFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedObjectListObjectListAdminToJSON(
  value?: EnvelopedObjectListObjectListAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PaginatedObjectListAdminToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
