/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ContentCard } from './ContentCard'
import { ContentCardFromJSON, ContentCardFromJSONTyped, ContentCardToJSON } from './ContentCard'
import type { CustomContentJSON } from './CustomContentJSON'
import {
  CustomContentJSONFromJSON,
  CustomContentJSONFromJSONTyped,
  CustomContentJSONToJSON,
} from './CustomContentJSON'

/**
 * Adds update nested feature
 * @export
 * @interface ContentAdminRetrieve
 */
export interface ContentAdminRetrieve {
  /**
   *
   * @type {number}
   * @memberof ContentAdminRetrieve
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ContentAdminRetrieve
   */
  readonly slug: string
  /**
   *
   * @type {string}
   * @memberof ContentAdminRetrieve
   */
  header?: string | null
  /**
   *
   * @type {CustomContentJSON}
   * @memberof ContentAdminRetrieve
   */
  otherData?: CustomContentJSON | null
  /**
   * * `category` - Category
   * * `object` - Object
   * * `service` - Service
   * * `static_page` - Static Page
   * @type {string}
   * @memberof ContentAdminRetrieve
   */
  readonly type: ContentAdminRetrieveTypeEnum
  /**
   *
   * @type {number}
   * @memberof ContentAdminRetrieve
   */
  entityId?: number | null
  /**
   *
   * @type {number}
   * @memberof ContentAdminRetrieve
   */
  readonly regionId: number | null
  /**
   *
   * @type {boolean}
   * @memberof ContentAdminRetrieve
   */
  usedDefault?: boolean
  /**
   *
   * @type {Array<ContentCard>}
   * @memberof ContentAdminRetrieve
   */
  cards?: Array<ContentCard>
  /**
   *
   * @type {string}
   * @memberof ContentAdminRetrieve
   */
  iconImg?: string | null
}

/**
 * @export
 */
export const ContentAdminRetrieveTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type ContentAdminRetrieveTypeEnum =
  (typeof ContentAdminRetrieveTypeEnum)[keyof typeof ContentAdminRetrieveTypeEnum]

/**
 * Check if a given object implements the ContentAdminRetrieve interface.
 */
export function instanceOfContentAdminRetrieve(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'slug' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'regionId' in value

  return isInstance
}

export function ContentAdminRetrieveFromJSON(json: any): ContentAdminRetrieve {
  return ContentAdminRetrieveFromJSONTyped(json, false)
}

export function ContentAdminRetrieveFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContentAdminRetrieve {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    slug: json['slug'],
    header: !exists(json, 'header') ? undefined : json['header'],
    otherData: !exists(json, 'otherData')
      ? undefined
      : CustomContentJSONFromJSON(json['otherData']),
    type: json['type'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    regionId: json['regionId'],
    usedDefault: !exists(json, 'usedDefault') ? undefined : json['usedDefault'],
    cards: !exists(json, 'cards')
      ? undefined
      : (json['cards'] as Array<any>).map(ContentCardFromJSON),
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
  }
}

export function ContentAdminRetrieveToJSON(value?: ContentAdminRetrieve | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    header: value.header,
    otherData: CustomContentJSONToJSON(value.otherData),
    entityId: value.entityId,
    usedDefault: value.usedDefault,
    cards:
      value.cards === undefined ? undefined : (value.cards as Array<any>).map(ContentCardToJSON),
    iconImg: value.iconImg,
  }
}
