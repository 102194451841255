/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CategoryServiceDetailUser
 */
export interface CategoryServiceDetailUser {
  /**
   *
   * @type {number}
   * @memberof CategoryServiceDetailUser
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof CategoryServiceDetailUser
   */
  readonly slug: string
  /**
   *
   * @type {string}
   * @memberof CategoryServiceDetailUser
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CategoryServiceDetailUser
   */
  price?: number | null
  /**
   *
   * @type {string}
   * @memberof CategoryServiceDetailUser
   */
  measureUnit?: string | null
}

/**
 * Check if a given object implements the CategoryServiceDetailUser interface.
 */
export function instanceOfCategoryServiceDetailUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'slug' in value
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function CategoryServiceDetailUserFromJSON(json: any): CategoryServiceDetailUser {
  return CategoryServiceDetailUserFromJSONTyped(json, false)
}

export function CategoryServiceDetailUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategoryServiceDetailUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    slug: json['slug'],
    name: json['name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    measureUnit: !exists(json, 'measureUnit') ? undefined : json['measureUnit'],
  }
}

export function CategoryServiceDetailUserToJSON(value?: CategoryServiceDetailUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    price: value.price,
    measureUnit: value.measureUnit,
  }
}
