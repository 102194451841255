/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdditionalCategory } from './AdditionalCategory'
import {
  AdditionalCategoryFromJSON,
  AdditionalCategoryFromJSONTyped,
  AdditionalCategoryToJSON,
} from './AdditionalCategory'
import type { ObjectCategory } from './ObjectCategory'
import {
  ObjectCategoryFromJSON,
  ObjectCategoryFromJSONTyped,
  ObjectCategoryToJSON,
} from './ObjectCategory'

/**
 *
 * @export
 * @interface ObjectListAdmin
 */
export interface ObjectListAdmin {
  /**
   *
   * @type {number}
   * @memberof ObjectListAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ObjectListAdmin
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ObjectListAdmin
   */
  iconImg?: string | null
  /**
   *
   * @type {number}
   * @memberof ObjectListAdmin
   */
  readonly servicesAmount: number
  /**
   *
   * @type {boolean}
   * @memberof ObjectListAdmin
   */
  isVisible?: boolean | null
  /**
   *
   * @type {Array<AdditionalCategory>}
   * @memberof ObjectListAdmin
   */
  readonly additionalCategories: Array<AdditionalCategory>
  /**
   *
   * @type {ObjectCategory}
   * @memberof ObjectListAdmin
   */
  readonly category: ObjectCategory
}

/**
 * Check if a given object implements the ObjectListAdmin interface.
 */
export function instanceOfObjectListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'servicesAmount' in value
  isInstance = isInstance && 'additionalCategories' in value
  isInstance = isInstance && 'category' in value

  return isInstance
}

export function ObjectListAdminFromJSON(json: any): ObjectListAdmin {
  return ObjectListAdminFromJSONTyped(json, false)
}

export function ObjectListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ObjectListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    servicesAmount: json['servicesAmount'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    additionalCategories: (json['additionalCategories'] as Array<any>).map(
      AdditionalCategoryFromJSON,
    ),
    category: ObjectCategoryFromJSON(json['category']),
  }
}

export function ObjectListAdminToJSON(value?: ObjectListAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    isVisible: value.isVisible,
  }
}
