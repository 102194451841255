/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PortfolioTag } from './PortfolioTag'
import {
  PortfolioTagFromJSON,
  PortfolioTagFromJSONTyped,
  PortfolioTagToJSON,
} from './PortfolioTag'
import type { PortfolioWorkGallery } from './PortfolioWorkGallery'
import {
  PortfolioWorkGalleryFromJSON,
  PortfolioWorkGalleryFromJSONTyped,
  PortfolioWorkGalleryToJSON,
} from './PortfolioWorkGallery'

/**
 * Adds nested create feature
 * @export
 * @interface PortfolioWork
 */
export interface PortfolioWork {
  /**
   *
   * @type {number}
   * @memberof PortfolioWork
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof PortfolioWork
   */
  time?: string | null
  /**
   *
   * @type {string}
   * @memberof PortfolioWork
   */
  price?: string | null
  /**
   *
   * @type {string}
   * @memberof PortfolioWork
   */
  name?: string | null
  /**
   *
   * @type {Array<PortfolioWorkGallery>}
   * @memberof PortfolioWork
   */
  gallery?: Array<PortfolioWorkGallery>
  /**
   *
   * @type {boolean}
   * @memberof PortfolioWork
   */
  isPublished?: boolean
  /**
   *
   * @type {number}
   * @memberof PortfolioWork
   */
  regionId?: number
  /**
   *
   * @type {PortfolioTag}
   * @memberof PortfolioWork
   */
  readonly tag: PortfolioTag | null
}

/**
 * Check if a given object implements the PortfolioWork interface.
 */
export function instanceOfPortfolioWork(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'tag' in value

  return isInstance
}

export function PortfolioWorkFromJSON(json: any): PortfolioWork {
  return PortfolioWorkFromJSONTyped(json, false)
}

export function PortfolioWorkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PortfolioWork {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    time: !exists(json, 'time') ? undefined : json['time'],
    price: !exists(json, 'price') ? undefined : json['price'],
    name: !exists(json, 'name') ? undefined : json['name'],
    gallery: !exists(json, 'gallery')
      ? undefined
      : (json['gallery'] as Array<any>).map(PortfolioWorkGalleryFromJSON),
    isPublished: !exists(json, 'isPublished') ? undefined : json['isPublished'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    tag: PortfolioTagFromJSON(json['tag']),
  }
}

export function PortfolioWorkToJSON(value?: PortfolioWork | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    time: value.time,
    price: value.price,
    name: value.name,
    gallery:
      value.gallery === undefined
        ? undefined
        : (value.gallery as Array<any>).map(PortfolioWorkGalleryToJSON),
    isPublished: value.isPublished,
    regionId: value.regionId,
  }
}
