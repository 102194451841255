/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedCatalogVisibilityRequest
 */
export interface PatchedCatalogVisibilityRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedCatalogVisibilityRequest
   */
  catalogUniqueId?: string
}

/**
 * Check if a given object implements the PatchedCatalogVisibilityRequest interface.
 */
export function instanceOfPatchedCatalogVisibilityRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedCatalogVisibilityRequestFromJSON(
  json: any,
): PatchedCatalogVisibilityRequest {
  return PatchedCatalogVisibilityRequestFromJSONTyped(json, false)
}

export function PatchedCatalogVisibilityRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedCatalogVisibilityRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    catalogUniqueId: !exists(json, 'catalogUniqueId') ? undefined : json['catalogUniqueId'],
  }
}

export function PatchedCatalogVisibilityRequestToJSON(
  value?: PatchedCatalogVisibilityRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    catalogUniqueId: value.catalogUniqueId,
  }
}
