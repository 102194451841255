/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ArchivedCatalogElement } from './ArchivedCatalogElement'
import {
  ArchivedCatalogElementFromJSON,
  ArchivedCatalogElementFromJSONTyped,
  ArchivedCatalogElementToJSON,
} from './ArchivedCatalogElement'

/**
 *
 * @export
 * @interface ArchivedCatalog
 */
export interface ArchivedCatalog {
  /**
   *
   * @type {Array<ArchivedCatalogElement>}
   * @memberof ArchivedCatalog
   */
  data: Array<ArchivedCatalogElement>
  /**
   * * `category` - Category
   * * `object` - Object
   * * `service` - Service
   * @type {string}
   * @memberof ArchivedCatalog
   */
  type: ArchivedCatalogTypeEnum
}

/**
 * @export
 */
export const ArchivedCatalogTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
} as const
export type ArchivedCatalogTypeEnum =
  (typeof ArchivedCatalogTypeEnum)[keyof typeof ArchivedCatalogTypeEnum]

/**
 * Check if a given object implements the ArchivedCatalog interface.
 */
export function instanceOfArchivedCatalog(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function ArchivedCatalogFromJSON(json: any): ArchivedCatalog {
  return ArchivedCatalogFromJSONTyped(json, false)
}

export function ArchivedCatalogFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ArchivedCatalog {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(ArchivedCatalogElementFromJSON),
    type: json['type'],
  }
}

export function ArchivedCatalogToJSON(value?: ArchivedCatalog | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(ArchivedCatalogElementToJSON),
    type: value.type,
  }
}
