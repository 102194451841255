/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LocationRequest
 */
export interface LocationRequest {
  /**
   *
   * @type {string}
   * @memberof LocationRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LocationRequest
   */
  declination?: string
  /**
   *
   * @type {number}
   * @memberof LocationRequest
   */
  regionId?: number | null
  /**
   *
   * @type {number}
   * @memberof LocationRequest
   */
  tagId?: number | null
}

/**
 * Check if a given object implements the LocationRequest interface.
 */
export function instanceOfLocationRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function LocationRequestFromJSON(json: any): LocationRequest {
  return LocationRequestFromJSONTyped(json, false)
}

export function LocationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LocationRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    declination: !exists(json, 'declination') ? undefined : json['declination'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    tagId: !exists(json, 'tagId') ? undefined : json['tagId'],
  }
}

export function LocationRequestToJSON(value?: LocationRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    declination: value.declination,
    regionId: value.regionId,
    tagId: value.tagId,
  }
}
