/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ContentCardOtherData } from './ContentCardOtherData'
import {
  ContentCardOtherDataFromJSON,
  ContentCardOtherDataFromJSONTyped,
  ContentCardOtherDataToJSON,
} from './ContentCardOtherData'

/**
 *
 * @export
 * @interface ContentCardRequest
 */
export interface ContentCardRequest {
  /**
   *
   * @type {number}
   * @memberof ContentCardRequest
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ContentCardRequest
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCardRequest
   */
  text?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCardRequest
   */
  description?: string | null
  /**
   *
   * @type {Blob}
   * @memberof ContentCardRequest
   */
  iconImg?: Blob | null
  /**
   *
   * @type {number}
   * @memberof ContentCardRequest
   */
  contentId: number
  /**
   *
   * @type {ContentCardOtherData}
   * @memberof ContentCardRequest
   */
  otherData: ContentCardOtherData | null
}

/**
 * Check if a given object implements the ContentCardRequest interface.
 */
export function instanceOfContentCardRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'contentId' in value
  isInstance = isInstance && 'otherData' in value

  return isInstance
}

export function ContentCardRequestFromJSON(json: any): ContentCardRequest {
  return ContentCardRequestFromJSONTyped(json, false)
}

export function ContentCardRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContentCardRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    header: !exists(json, 'header') ? undefined : json['header'],
    text: !exists(json, 'text') ? undefined : json['text'],
    description: !exists(json, 'description') ? undefined : json['description'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    contentId: json['contentId'],
    otherData: ContentCardOtherDataFromJSON(json['otherData']),
  }
}

export function ContentCardRequestToJSON(value?: ContentCardRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    header: value.header,
    text: value.text,
    description: value.description,
    iconImg: value.iconImg,
    contentId: value.contentId,
    otherData: ContentCardOtherDataToJSON(value.otherData),
  }
}
