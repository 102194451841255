/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ReviewRequest
 */
export interface ReviewRequest {
  /**
   *
   * @type {number}
   * @memberof ReviewRequest
   */
  rating?: number
  /**
   *
   * @type {string}
   * @memberof ReviewRequest
   */
  author?: string | null
  /**
   *
   * @type {string}
   * @memberof ReviewRequest
   */
  publishedAt?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ReviewRequest
   */
  isPublished?: boolean
  /**
   *
   * @type {string}
   * @memberof ReviewRequest
   */
  text?: string | null
  /**
   *
   * @type {number}
   * @memberof ReviewRequest
   */
  serviceId: number | null
}

/**
 * Check if a given object implements the ReviewRequest interface.
 */
export function instanceOfReviewRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'serviceId' in value

  return isInstance
}

export function ReviewRequestFromJSON(json: any): ReviewRequest {
  return ReviewRequestFromJSONTyped(json, false)
}

export function ReviewRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReviewRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    rating: !exists(json, 'rating') ? undefined : json['rating'],
    author: !exists(json, 'author') ? undefined : json['author'],
    publishedAt: !exists(json, 'publishedAt') ? undefined : json['publishedAt'],
    isPublished: !exists(json, 'isPublished') ? undefined : json['isPublished'],
    text: !exists(json, 'text') ? undefined : json['text'],
    serviceId: json['serviceId'],
  }
}

export function ReviewRequestToJSON(value?: ReviewRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    rating: value.rating,
    author: value.author,
    publishedAt: value.publishedAt,
    isPublished: value.isPublished,
    text: value.text,
    serviceId: value.serviceId,
  }
}
