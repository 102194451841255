/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Calculator } from './Calculator'
import { CalculatorFromJSON, CalculatorFromJSONTyped, CalculatorToJSON } from './Calculator'
import type { ObjectCategory } from './ObjectCategory'
import {
  ObjectCategoryFromJSON,
  ObjectCategoryFromJSONTyped,
  ObjectCategoryToJSON,
} from './ObjectCategory'
import type { ServiceObject } from './ServiceObject'
import {
  ServiceObjectFromJSON,
  ServiceObjectFromJSONTyped,
  ServiceObjectToJSON,
} from './ServiceObject'
import type { ServiceSeoFields } from './ServiceSeoFields'
import {
  ServiceSeoFieldsFromJSON,
  ServiceSeoFieldsFromJSONTyped,
  ServiceSeoFieldsToJSON,
} from './ServiceSeoFields'

/**
 *
 * @export
 * @interface ServiceDetailAdmin
 */
export interface ServiceDetailAdmin {
  /**
   *
   * @type {number}
   * @memberof ServiceDetailAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ServiceDetailAdmin
   */
  readonly catalogUniqueId: string
  /**
   *
   * @type {string}
   * @memberof ServiceDetailAdmin
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof ServiceDetailAdmin
   */
  price?: number | null
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof ServiceDetailAdmin
   */
  currentTemplate: ServiceDetailAdminCurrentTemplateEnum
  /**
   *
   * @type {boolean}
   * @memberof ServiceDetailAdmin
   */
  isVisible?: boolean | null
  /**
   *
   * @type {string}
   * @memberof ServiceDetailAdmin
   */
  measureUnit?: string | null
  /**
   *
   * @type {string}
   * @memberof ServiceDetailAdmin
   */
  slug?: string | null
  /**
   *
   * @type {ObjectCategory}
   * @memberof ServiceDetailAdmin
   */
  readonly category: ObjectCategory
  /**
   *
   * @type {ServiceObject}
   * @memberof ServiceDetailAdmin
   */
  readonly object: ServiceObject
  /**
   *
   * @type {ServiceSeoFields}
   * @memberof ServiceDetailAdmin
   */
  readonly seoData: ServiceSeoFields | null
  /**
   * * `global` - Global
   * * `local` - Local
   * * `both` - Both
   * @type {string}
   * @memberof ServiceDetailAdmin
   */
  faqUsageType?: ServiceDetailAdminFaqUsageTypeEnum
  /**
   *
   * @type {Calculator}
   * @memberof ServiceDetailAdmin
   */
  readonly calculator: Calculator | null
  /**
   *
   * @type {number}
   * @memberof ServiceDetailAdmin
   */
  readonly locationsAmount: number | null
}

/**
 * @export
 */
export const ServiceDetailAdminCurrentTemplateEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type ServiceDetailAdminCurrentTemplateEnum =
  (typeof ServiceDetailAdminCurrentTemplateEnum)[keyof typeof ServiceDetailAdminCurrentTemplateEnum]

/**
 * @export
 */
export const ServiceDetailAdminFaqUsageTypeEnum = {
  Global: 'global',
  Local: 'local',
  Both: 'both',
} as const
export type ServiceDetailAdminFaqUsageTypeEnum =
  (typeof ServiceDetailAdminFaqUsageTypeEnum)[keyof typeof ServiceDetailAdminFaqUsageTypeEnum]

/**
 * Check if a given object implements the ServiceDetailAdmin interface.
 */
export function instanceOfServiceDetailAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'catalogUniqueId' in value
  isInstance = isInstance && 'currentTemplate' in value
  isInstance = isInstance && 'category' in value
  isInstance = isInstance && 'object' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'calculator' in value
  isInstance = isInstance && 'locationsAmount' in value

  return isInstance
}

export function ServiceDetailAdminFromJSON(json: any): ServiceDetailAdmin {
  return ServiceDetailAdminFromJSONTyped(json, false)
}

export function ServiceDetailAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ServiceDetailAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    catalogUniqueId: json['catalogUniqueId'],
    name: !exists(json, 'name') ? undefined : json['name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    currentTemplate: json['currentTemplate'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    measureUnit: !exists(json, 'measureUnit') ? undefined : json['measureUnit'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    category: ObjectCategoryFromJSON(json['category']),
    object: ServiceObjectFromJSON(json['object']),
    seoData: ServiceSeoFieldsFromJSON(json['seoData']),
    faqUsageType: !exists(json, 'faqUsageType') ? undefined : json['faqUsageType'],
    calculator: CalculatorFromJSON(json['calculator']),
    locationsAmount: json['locationsAmount'],
  }
}

export function ServiceDetailAdminToJSON(value?: ServiceDetailAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    price: value.price,
    currentTemplate: value.currentTemplate,
    isVisible: value.isVisible,
    measureUnit: value.measureUnit,
    slug: value.slug,
    faqUsageType: value.faqUsageType,
  }
}
