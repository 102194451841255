/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { MainPageTag } from './MainPageTag'
import { MainPageTagFromJSON, MainPageTagFromJSONTyped, MainPageTagToJSON } from './MainPageTag'

/**
 *
 * @export
 * @interface CategoryUser
 */
export interface CategoryUser {
  /**
   *
   * @type {number}
   * @memberof CategoryUser
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof CategoryUser
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CategoryUser
   */
  iconImg?: string | null
  /**
   *
   * @type {number}
   * @memberof CategoryUser
   */
  readonly servicesAmount: number
  /**
   *
   * @type {string}
   * @memberof CategoryUser
   */
  backgroundImg?: string | null
  /**
   *
   * @type {MainPageTag}
   * @memberof CategoryUser
   */
  readonly tag: MainPageTag | null
  /**
   *
   * @type {string}
   * @memberof CategoryUser
   */
  slug?: string | null
}

/**
 * Check if a given object implements the CategoryUser interface.
 */
export function instanceOfCategoryUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'servicesAmount' in value
  isInstance = isInstance && 'tag' in value

  return isInstance
}

export function CategoryUserFromJSON(json: any): CategoryUser {
  return CategoryUserFromJSONTyped(json, false)
}

export function CategoryUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    servicesAmount: json['servicesAmount'],
    backgroundImg: !exists(json, 'backgroundImg') ? undefined : json['backgroundImg'],
    tag: MainPageTagFromJSON(json['tag']),
    slug: !exists(json, 'slug') ? undefined : json['slug'],
  }
}

export function CategoryUserToJSON(value?: CategoryUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    backgroundImg: value.backgroundImg,
    slug: value.slug,
  }
}
