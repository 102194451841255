/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ContentCardOtherData } from './ContentCardOtherData'
import {
  ContentCardOtherDataFromJSON,
  ContentCardOtherDataFromJSONTyped,
  ContentCardOtherDataToJSON,
} from './ContentCardOtherData'

/**
 *
 * @export
 * @interface ContentCard
 */
export interface ContentCard {
  /**
   *
   * @type {number}
   * @memberof ContentCard
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ContentCard
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCard
   */
  text?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCard
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ContentCard
   */
  iconImg?: string | null
  /**
   *
   * @type {number}
   * @memberof ContentCard
   */
  contentId: number
  /**
   *
   * @type {ContentCardOtherData}
   * @memberof ContentCard
   */
  otherData: ContentCardOtherData | null
}

/**
 * Check if a given object implements the ContentCard interface.
 */
export function instanceOfContentCard(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'contentId' in value
  isInstance = isInstance && 'otherData' in value

  return isInstance
}

export function ContentCardFromJSON(json: any): ContentCard {
  return ContentCardFromJSONTyped(json, false)
}

export function ContentCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentCard {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    header: !exists(json, 'header') ? undefined : json['header'],
    text: !exists(json, 'text') ? undefined : json['text'],
    description: !exists(json, 'description') ? undefined : json['description'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    contentId: json['contentId'],
    otherData: ContentCardOtherDataFromJSON(json['otherData']),
  }
}

export function ContentCardToJSON(value?: ContentCard | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    header: value.header,
    text: value.text,
    description: value.description,
    iconImg: value.iconImg,
    contentId: value.contentId,
    otherData: ContentCardOtherDataToJSON(value.otherData),
  }
}
