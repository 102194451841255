/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { EnvelopedReviewListReviewPublic } from '../models'
import {
  EnvelopedReviewListReviewPublicFromJSON,
  EnvelopedReviewListReviewPublicToJSON,
} from '../models'

export interface ReviewListRequest {
  page?: number
  size?: number
}

/**
 *
 */
export class ReviewApi extends runtime.BaseAPI {
  /**
   */
  async reviewListRaw(
    requestParameters: ReviewListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedReviewListReviewPublic>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/review/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedReviewListReviewPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async reviewList(
    requestParameters: ReviewListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedReviewListReviewPublic> {
    const response = await this.reviewListRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
