/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface FAQ
 */
export interface FAQ {
  /**
   *
   * @type {number}
   * @memberof FAQ
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof FAQ
   */
  answer: string
  /**
   *
   * @type {string}
   * @memberof FAQ
   */
  question: string
  /**
   * * `category` - Category
   * * `object` - Object
   * * `service` - Service
   * * `static_page` - Static Page
   * @type {string}
   * @memberof FAQ
   */
  type?: FAQTypeEnum
  /**
   *
   * @type {number}
   * @memberof FAQ
   */
  entityId?: number | null
  /**
   *
   * @type {number}
   * @memberof FAQ
   */
  score?: number | null
  /**
   *
   * @type {number}
   * @memberof FAQ
   */
  regionId?: number
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof FAQ
   */
  serviceTemplateType?: FAQServiceTemplateTypeEnum
}

/**
 * @export
 */
export const FAQTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type FAQTypeEnum = (typeof FAQTypeEnum)[keyof typeof FAQTypeEnum]

/**
 * @export
 */
export const FAQServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type FAQServiceTemplateTypeEnum =
  (typeof FAQServiceTemplateTypeEnum)[keyof typeof FAQServiceTemplateTypeEnum]

/**
 * Check if a given object implements the FAQ interface.
 */
export function instanceOfFAQ(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'answer' in value
  isInstance = isInstance && 'question' in value

  return isInstance
}

export function FAQFromJSON(json: any): FAQ {
  return FAQFromJSONTyped(json, false)
}

export function FAQFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQ {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    answer: json['answer'],
    question: json['question'],
    type: !exists(json, 'type') ? undefined : json['type'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    score: !exists(json, 'score') ? undefined : json['score'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    serviceTemplateType: !exists(json, 'serviceTemplateType')
      ? undefined
      : json['serviceTemplateType'],
  }
}

export function FAQToJSON(value?: FAQ | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    answer: value.answer,
    question: value.question,
    type: value.type,
    entityId: value.entityId,
    score: value.score,
    regionId: value.regionId,
    serviceTemplateType: value.serviceTemplateType,
  }
}
