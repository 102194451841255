/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CatalogLocationSeoFieldsAdmin
 */
export interface CatalogLocationSeoFieldsAdmin {
  /**
   *
   * @type {number}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  locationSlug?: string | null
  /**
   *
   * @type {string}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  readonly locationName: string
  /**
   *
   * @type {string}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  titleTag?: string | null
  /**
   *
   * @type {string}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  descriptionTag?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  useLocalMeta?: boolean
  /**
   *
   * @type {string}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  description?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  useLocalDescription?: boolean
  /**
   *
   * @type {number}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  catalogId?: number
  /**
   *
   * @type {number}
   * @memberof CatalogLocationSeoFieldsAdmin
   */
  locationId?: number
}

/**
 * Check if a given object implements the CatalogLocationSeoFieldsAdmin interface.
 */
export function instanceOfCatalogLocationSeoFieldsAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'locationName' in value

  return isInstance
}

export function CatalogLocationSeoFieldsAdminFromJSON(json: any): CatalogLocationSeoFieldsAdmin {
  return CatalogLocationSeoFieldsAdminFromJSONTyped(json, false)
}

export function CatalogLocationSeoFieldsAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogLocationSeoFieldsAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    locationSlug: !exists(json, 'locationSlug') ? undefined : json['locationSlug'],
    locationName: json['locationName'],
    header: !exists(json, 'header') ? undefined : json['header'],
    titleTag: !exists(json, 'titleTag') ? undefined : json['titleTag'],
    descriptionTag: !exists(json, 'descriptionTag') ? undefined : json['descriptionTag'],
    useLocalMeta: !exists(json, 'useLocalMeta') ? undefined : json['useLocalMeta'],
    description: !exists(json, 'description') ? undefined : json['description'],
    useLocalDescription: !exists(json, 'useLocalDescription')
      ? undefined
      : json['useLocalDescription'],
    catalogId: !exists(json, 'catalogId') ? undefined : json['catalogId'],
    locationId: !exists(json, 'locationId') ? undefined : json['locationId'],
  }
}

export function CatalogLocationSeoFieldsAdminToJSON(
  value?: CatalogLocationSeoFieldsAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    locationSlug: value.locationSlug,
    header: value.header,
    titleTag: value.titleTag,
    descriptionTag: value.descriptionTag,
    useLocalMeta: value.useLocalMeta,
    description: value.description,
    useLocalDescription: value.useLocalDescription,
    catalogId: value.catalogId,
    locationId: value.locationId,
  }
}
