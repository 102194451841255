/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ListingFields } from './ListingFields'
import {
  ListingFieldsFromJSON,
  ListingFieldsFromJSONTyped,
  ListingFieldsToJSON,
} from './ListingFields'
import type { Location } from './Location'
import { LocationFromJSON, LocationFromJSONTyped, LocationToJSON } from './Location'
import type { LocationService } from './LocationService'
import {
  LocationServiceFromJSON,
  LocationServiceFromJSONTyped,
  LocationServiceToJSON,
} from './LocationService'

/**
 *
 * @export
 * @interface LocationWrapper
 */
export interface LocationWrapper {
  /**
   *
   * @type {Array<Location>}
   * @memberof LocationWrapper
   */
  locations: Array<Location>
  /**
   *
   * @type {ListingFields}
   * @memberof LocationWrapper
   */
  listingSeoData: ListingFields
  /**
   *
   * @type {LocationService}
   * @memberof LocationWrapper
   */
  service: LocationService
}

/**
 * Check if a given object implements the LocationWrapper interface.
 */
export function instanceOfLocationWrapper(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'locations' in value
  isInstance = isInstance && 'listingSeoData' in value
  isInstance = isInstance && 'service' in value

  return isInstance
}

export function LocationWrapperFromJSON(json: any): LocationWrapper {
  return LocationWrapperFromJSONTyped(json, false)
}

export function LocationWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LocationWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    locations: (json['locations'] as Array<any>).map(LocationFromJSON),
    listingSeoData: ListingFieldsFromJSON(json['listingSeoData']),
    service: LocationServiceFromJSON(json['service']),
  }
}

export function LocationWrapperToJSON(value?: LocationWrapper | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    locations: (value.locations as Array<any>).map(LocationToJSON),
    listingSeoData: ListingFieldsToJSON(value.listingSeoData),
    service: LocationServiceToJSON(value.service),
  }
}
