/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PortfolioWorkGalleryRequest
 */
export interface PortfolioWorkGalleryRequest {
  /**
   *
   * @type {Blob}
   * @memberof PortfolioWorkGalleryRequest
   */
  image?: Blob | null
  /**
   *
   * @type {string}
   * @memberof PortfolioWorkGalleryRequest
   */
  video?: string
  /**
   *
   * @type {number}
   * @memberof PortfolioWorkGalleryRequest
   */
  innerId: number
  /**
   *
   * @type {boolean}
   * @memberof PortfolioWorkGalleryRequest
   */
  isMain?: boolean
}

/**
 * Check if a given object implements the PortfolioWorkGalleryRequest interface.
 */
export function instanceOfPortfolioWorkGalleryRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'innerId' in value

  return isInstance
}

export function PortfolioWorkGalleryRequestFromJSON(json: any): PortfolioWorkGalleryRequest {
  return PortfolioWorkGalleryRequestFromJSONTyped(json, false)
}

export function PortfolioWorkGalleryRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PortfolioWorkGalleryRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    image: !exists(json, 'image') ? undefined : json['image'],
    video: !exists(json, 'video') ? undefined : json['video'],
    innerId: json['innerId'],
    isMain: !exists(json, 'isMain') ? undefined : json['isMain'],
  }
}

export function PortfolioWorkGalleryRequestToJSON(
  value?: PortfolioWorkGalleryRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    image: value.image,
    video: value.video,
    innerId: value.innerId,
    isMain: value.isMain,
  }
}
