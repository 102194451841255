/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CatalogObject } from './CatalogObject'
import {
  CatalogObjectFromJSON,
  CatalogObjectFromJSONTyped,
  CatalogObjectToJSON,
} from './CatalogObject'

/**
 *
 * @export
 * @interface OpenApiCatalog
 */
export interface OpenApiCatalog {
  /**
   *
   * @type {number}
   * @memberof OpenApiCatalog
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof OpenApiCatalog
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof OpenApiCatalog
   */
  isVisible: boolean
  /**
   *
   * @type {string}
   * @memberof OpenApiCatalog
   */
  catalogUniqueId: string
  /**
   *
   * @type {Array<CatalogObject>}
   * @memberof OpenApiCatalog
   */
  children: Array<CatalogObject> | null
}

/**
 * Check if a given object implements the OpenApiCatalog interface.
 */
export function instanceOfOpenApiCatalog(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'isVisible' in value
  isInstance = isInstance && 'catalogUniqueId' in value
  isInstance = isInstance && 'children' in value

  return isInstance
}

export function OpenApiCatalogFromJSON(json: any): OpenApiCatalog {
  return OpenApiCatalogFromJSONTyped(json, false)
}

export function OpenApiCatalogFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OpenApiCatalog {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    isVisible: json['isVisible'],
    catalogUniqueId: json['catalogUniqueId'],
    children:
      json['children'] === null
        ? null
        : (json['children'] as Array<any>).map(CatalogObjectFromJSON),
  }
}

export function OpenApiCatalogToJSON(value?: OpenApiCatalog | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    isVisible: value.isVisible,
    catalogUniqueId: value.catalogUniqueId,
    children:
      value.children === null ? null : (value.children as Array<any>).map(CatalogObjectToJSON),
  }
}
