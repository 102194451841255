/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { ServiceDetailAdmin } from './ServiceDetailAdmin'
import {
  ServiceDetailAdminFromJSON,
  ServiceDetailAdminFromJSONTyped,
  ServiceDetailAdminToJSON,
} from './ServiceDetailAdmin'

/**
 *
 * @export
 * @interface EnvelopedServiceServiceDetailAdmin
 */
export interface EnvelopedServiceServiceDetailAdmin {
  /**
   *
   * @type {ServiceDetailAdmin}
   * @memberof EnvelopedServiceServiceDetailAdmin
   */
  body: ServiceDetailAdmin
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedServiceServiceDetailAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedServiceServiceDetailAdmin interface.
 */
export function instanceOfEnvelopedServiceServiceDetailAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedServiceServiceDetailAdminFromJSON(
  json: any,
): EnvelopedServiceServiceDetailAdmin {
  return EnvelopedServiceServiceDetailAdminFromJSONTyped(json, false)
}

export function EnvelopedServiceServiceDetailAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedServiceServiceDetailAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: ServiceDetailAdminFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedServiceServiceDetailAdminToJSON(
  value?: EnvelopedServiceServiceDetailAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: ServiceDetailAdminToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
