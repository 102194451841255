/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Content } from './Content'
import { ContentFromJSON, ContentFromJSONTyped, ContentToJSON } from './Content'
import type { FAQ } from './FAQ'
import { FAQFromJSON, FAQFromJSONTyped, FAQToJSON } from './FAQ'
import type { StaticPageNestedCalculator } from './StaticPageNestedCalculator'
import {
  StaticPageNestedCalculatorFromJSON,
  StaticPageNestedCalculatorFromJSONTyped,
  StaticPageNestedCalculatorToJSON,
} from './StaticPageNestedCalculator'
import type { StaticPageNestedService } from './StaticPageNestedService'
import {
  StaticPageNestedServiceFromJSON,
  StaticPageNestedServiceFromJSONTyped,
  StaticPageNestedServiceToJSON,
} from './StaticPageNestedService'

/**
 *
 * @export
 * @interface StaticPageDetail
 */
export interface StaticPageDetail {
  /**
   *
   * @type {number}
   * @memberof StaticPageDetail
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof StaticPageDetail
   */
  slug: string
  /**
   *
   * @type {string}
   * @memberof StaticPageDetail
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof StaticPageDetail
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof StaticPageDetail
   */
  titleTag?: string | null
  /**
   *
   * @type {string}
   * @memberof StaticPageDetail
   */
  descriptionTag?: string | null
  /**
   *
   * @type {string}
   * @memberof StaticPageDetail
   */
  canonicalUrl?: string | null
  /**
   *
   * @type {Array<Content>}
   * @memberof StaticPageDetail
   */
  readonly content: Array<Content> | null
  /**
   *
   * @type {number}
   * @memberof StaticPageDetail
   */
  serviceId?: number | null
  /**
   *
   * @type {StaticPageNestedService}
   * @memberof StaticPageDetail
   */
  readonly service: StaticPageNestedService
  /**
   *
   * @type {Array<FAQ>}
   * @memberof StaticPageDetail
   */
  readonly faq: Array<FAQ>
  /**
   *
   * @type {StaticPageNestedCalculator}
   * @memberof StaticPageDetail
   */
  readonly calculator: StaticPageNestedCalculator | null
}

/**
 * Check if a given object implements the StaticPageDetail interface.
 */
export function instanceOfStaticPageDetail(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'slug' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'service' in value
  isInstance = isInstance && 'faq' in value
  isInstance = isInstance && 'calculator' in value

  return isInstance
}

export function StaticPageDetailFromJSON(json: any): StaticPageDetail {
  return StaticPageDetailFromJSONTyped(json, false)
}

export function StaticPageDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StaticPageDetail {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    slug: json['slug'],
    name: json['name'],
    header: !exists(json, 'header') ? undefined : json['header'],
    titleTag: !exists(json, 'titleTag') ? undefined : json['titleTag'],
    descriptionTag: !exists(json, 'descriptionTag') ? undefined : json['descriptionTag'],
    canonicalUrl: !exists(json, 'canonicalUrl') ? undefined : json['canonicalUrl'],
    content:
      json['content'] === null ? null : (json['content'] as Array<any>).map(ContentFromJSON),
    serviceId: !exists(json, 'serviceId') ? undefined : json['serviceId'],
    service: StaticPageNestedServiceFromJSON(json['service']),
    faq: (json['faq'] as Array<any>).map(FAQFromJSON),
    calculator: StaticPageNestedCalculatorFromJSON(json['calculator']),
  }
}

export function StaticPageDetailToJSON(value?: StaticPageDetail | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    slug: value.slug,
    name: value.name,
    header: value.header,
    titleTag: value.titleTag,
    descriptionTag: value.descriptionTag,
    canonicalUrl: value.canonicalUrl,
    serviceId: value.serviceId,
  }
}
