/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  EnvelopedCatalogItemsAmountSlugListItemsAmount,
  EnvelopedCategoryCategoryDetailUser,
  EnvelopedCategoryListCategoryUser,
  EnvelopedObjectObjectDetailUser,
  EnvelopedServiceServiceDetailUser,
  EnvelopedSlugSlugList,
} from '../models'
import {
  EnvelopedCatalogItemsAmountSlugListItemsAmountFromJSON,
  EnvelopedCatalogItemsAmountSlugListItemsAmountToJSON,
  EnvelopedCategoryCategoryDetailUserFromJSON,
  EnvelopedCategoryCategoryDetailUserToJSON,
  EnvelopedCategoryListCategoryUserFromJSON,
  EnvelopedCategoryListCategoryUserToJSON,
  EnvelopedObjectObjectDetailUserFromJSON,
  EnvelopedObjectObjectDetailUserToJSON,
  EnvelopedServiceServiceDetailUserFromJSON,
  EnvelopedServiceServiceDetailUserToJSON,
  EnvelopedSlugSlugListFromJSON,
  EnvelopedSlugSlugListToJSON,
} from '../models'

export interface ServiceCatalogCategoryListRequest {
  locationId?: number
}

export interface ServiceCatalogCategoryRetrieveRequest {
  slug: string
  locationId?: number
}

export interface ServiceCatalogObjectRetrieveRequest {
  slug: string
  locationId?: number
}

export interface ServiceCatalogServiceRetrieveRequest {
  slug: string
  gallerySize?: number
}

export interface ServiceCatalogSlugListRequest {
  catalogEntity?: ServiceCatalogSlugListCatalogEntityEnum
  categorySpecialLocation?: boolean
  seoType?: ServiceCatalogSlugListSeoTypeEnum
}

/**
 *
 */
export class ServiceCatalogApi extends runtime.BaseAPI {
  /**
   */
  async serviceCatalogCategoryListRaw(
    requestParameters: ServiceCatalogCategoryListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryListCategoryUser>> {
    const queryParameters: any = {}

    if (requestParameters.locationId !== undefined) {
      queryParameters['locationId'] = requestParameters.locationId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/service_catalog/category/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryListCategoryUserFromJSON(jsonValue),
    )
  }

  /**
   */
  async serviceCatalogCategoryList(
    requestParameters: ServiceCatalogCategoryListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryListCategoryUser> {
    const response = await this.serviceCatalogCategoryListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async serviceCatalogCategoryRetrieveRaw(
    requestParameters: ServiceCatalogCategoryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCategoryCategoryDetailUser>> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
      throw new runtime.RequiredError(
        'slug',
        'Required parameter requestParameters.slug was null or undefined when calling serviceCatalogCategoryRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.locationId !== undefined) {
      queryParameters['locationId'] = requestParameters.locationId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/service_catalog/category/{slug}/`.replace(
          `{${'slug'}}`,
          encodeURIComponent(String(requestParameters.slug)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCategoryCategoryDetailUserFromJSON(jsonValue),
    )
  }

  /**
   */
  async serviceCatalogCategoryRetrieve(
    requestParameters: ServiceCatalogCategoryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCategoryCategoryDetailUser> {
    const response = await this.serviceCatalogCategoryRetrieveRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async serviceCatalogObjectRetrieveRaw(
    requestParameters: ServiceCatalogObjectRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedObjectObjectDetailUser>> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
      throw new runtime.RequiredError(
        'slug',
        'Required parameter requestParameters.slug was null or undefined when calling serviceCatalogObjectRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.locationId !== undefined) {
      queryParameters['locationId'] = requestParameters.locationId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/service_catalog/object/{slug}/`.replace(
          `{${'slug'}}`,
          encodeURIComponent(String(requestParameters.slug)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedObjectObjectDetailUserFromJSON(jsonValue),
    )
  }

  /**
   */
  async serviceCatalogObjectRetrieve(
    requestParameters: ServiceCatalogObjectRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedObjectObjectDetailUser> {
    const response = await this.serviceCatalogObjectRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async serviceCatalogServiceRetrieveRaw(
    requestParameters: ServiceCatalogServiceRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedServiceServiceDetailUser>> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
      throw new runtime.RequiredError(
        'slug',
        'Required parameter requestParameters.slug was null or undefined when calling serviceCatalogServiceRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.gallerySize !== undefined) {
      queryParameters['gallerySize'] = requestParameters.gallerySize
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/service_catalog/service/{slug}/`.replace(
          `{${'slug'}}`,
          encodeURIComponent(String(requestParameters.slug)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedServiceServiceDetailUserFromJSON(jsonValue),
    )
  }

  /**
   */
  async serviceCatalogServiceRetrieve(
    requestParameters: ServiceCatalogServiceRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedServiceServiceDetailUser> {
    const response = await this.serviceCatalogServiceRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async serviceCatalogSlugCatalogItemsAmountRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCatalogItemsAmountSlugListItemsAmount>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/service_catalog/slug/catalog_items_amount/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCatalogItemsAmountSlugListItemsAmountFromJSON(jsonValue),
    )
  }

  /**
   */
  async serviceCatalogSlugCatalogItemsAmountRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCatalogItemsAmountSlugListItemsAmount> {
    const response = await this.serviceCatalogSlugCatalogItemsAmountRetrieveRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  async serviceCatalogSlugListRaw(
    requestParameters: ServiceCatalogSlugListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedSlugSlugList>> {
    const queryParameters: any = {}

    if (requestParameters.catalogEntity !== undefined) {
      queryParameters['catalogEntity'] = requestParameters.catalogEntity
    }

    if (requestParameters.categorySpecialLocation !== undefined) {
      queryParameters['categorySpecialLocation'] = requestParameters.categorySpecialLocation
    }

    if (requestParameters.seoType !== undefined) {
      queryParameters['seoType'] = requestParameters.seoType
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/service_catalog/slug/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedSlugSlugListFromJSON(jsonValue),
    )
  }

  /**
   */
  async serviceCatalogSlugList(
    requestParameters: ServiceCatalogSlugListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedSlugSlugList> {
    const response = await this.serviceCatalogSlugListRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const ServiceCatalogSlugListCatalogEntityEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
} as const
export type ServiceCatalogSlugListCatalogEntityEnum =
  (typeof ServiceCatalogSlugListCatalogEntityEnum)[keyof typeof ServiceCatalogSlugListCatalogEntityEnum]
/**
 * @export
 */
export const ServiceCatalogSlugListSeoTypeEnum = {
  Main: 'main',
  Price: 'price',
  Location: 'location',
} as const
export type ServiceCatalogSlugListSeoTypeEnum =
  (typeof ServiceCatalogSlugListSeoTypeEnum)[keyof typeof ServiceCatalogSlugListSeoTypeEnum]
