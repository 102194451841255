/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedCatalogLocationSeoFieldsRequest
 */
export interface PatchedCatalogLocationSeoFieldsRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  locationSlug?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  titleTag?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  descriptionTag?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  useLocalMeta?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  description?: string | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  useLocalDescription?: boolean
  /**
   *
   * @type {number}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  catalogId?: number
  /**
   *
   * @type {number}
   * @memberof PatchedCatalogLocationSeoFieldsRequest
   */
  locationId?: number
}

/**
 * Check if a given object implements the PatchedCatalogLocationSeoFieldsRequest interface.
 */
export function instanceOfPatchedCatalogLocationSeoFieldsRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedCatalogLocationSeoFieldsRequestFromJSON(
  json: any,
): PatchedCatalogLocationSeoFieldsRequest {
  return PatchedCatalogLocationSeoFieldsRequestFromJSONTyped(json, false)
}

export function PatchedCatalogLocationSeoFieldsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedCatalogLocationSeoFieldsRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    locationSlug: !exists(json, 'locationSlug') ? undefined : json['locationSlug'],
    header: !exists(json, 'header') ? undefined : json['header'],
    titleTag: !exists(json, 'titleTag') ? undefined : json['titleTag'],
    descriptionTag: !exists(json, 'descriptionTag') ? undefined : json['descriptionTag'],
    useLocalMeta: !exists(json, 'useLocalMeta') ? undefined : json['useLocalMeta'],
    description: !exists(json, 'description') ? undefined : json['description'],
    useLocalDescription: !exists(json, 'useLocalDescription')
      ? undefined
      : json['useLocalDescription'],
    catalogId: !exists(json, 'catalogId') ? undefined : json['catalogId'],
    locationId: !exists(json, 'locationId') ? undefined : json['locationId'],
  }
}

export function PatchedCatalogLocationSeoFieldsRequestToJSON(
  value?: PatchedCatalogLocationSeoFieldsRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    locationSlug: value.locationSlug,
    header: value.header,
    titleTag: value.titleTag,
    descriptionTag: value.descriptionTag,
    useLocalMeta: value.useLocalMeta,
    description: value.description,
    useLocalDescription: value.useLocalDescription,
    catalogId: value.catalogId,
    locationId: value.locationId,
  }
}
