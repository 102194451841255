/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { EnvelopedMasterListMasterPublic } from '../models'
import {
  EnvelopedMasterListMasterPublicFromJSON,
  EnvelopedMasterListMasterPublicToJSON,
} from '../models'

export interface MasterListRequest {
  serviceId: number
}

/**
 *
 */
export class MasterApi extends runtime.BaseAPI {
  /**
   */
  async masterListRaw(
    requestParameters: MasterListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedMasterListMasterPublic>> {
    if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
      throw new runtime.RequiredError(
        'serviceId',
        'Required parameter requestParameters.serviceId was null or undefined when calling masterList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.serviceId !== undefined) {
      queryParameters['serviceId'] = requestParameters.serviceId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/master/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedMasterListMasterPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async masterList(
    requestParameters: MasterListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedMasterListMasterPublic> {
    const response = await this.masterListRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
