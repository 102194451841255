/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedCalculatorRequest
 */
export interface PatchedCalculatorRequest {
  /**
   *
   * @type {any}
   * @memberof PatchedCalculatorRequest
   */
  structure?: any | null
  /**
   * * `service` - service
   * * `static_page` - static_page
   * @type {string}
   * @memberof PatchedCalculatorRequest
   */
  type?: PatchedCalculatorRequestTypeEnum
  /**
   *
   * @type {number}
   * @memberof PatchedCalculatorRequest
   */
  entityId?: number | null
  /**
   *
   * @type {number}
   * @memberof PatchedCalculatorRequest
   */
  serviceTemplateType?: number
}

/**
 * @export
 */
export const PatchedCalculatorRequestTypeEnum = {
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type PatchedCalculatorRequestTypeEnum =
  (typeof PatchedCalculatorRequestTypeEnum)[keyof typeof PatchedCalculatorRequestTypeEnum]

/**
 * Check if a given object implements the PatchedCalculatorRequest interface.
 */
export function instanceOfPatchedCalculatorRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedCalculatorRequestFromJSON(json: any): PatchedCalculatorRequest {
  return PatchedCalculatorRequestFromJSONTyped(json, false)
}

export function PatchedCalculatorRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedCalculatorRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    structure: !exists(json, 'structure') ? undefined : json['structure'],
    type: !exists(json, 'type') ? undefined : json['type'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    serviceTemplateType: !exists(json, 'serviceTemplateType')
      ? undefined
      : json['serviceTemplateType'],
  }
}

export function PatchedCalculatorRequestToJSON(value?: PatchedCalculatorRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    structure: value.structure,
    type: value.type,
    entityId: value.entityId,
    serviceTemplateType: value.serviceTemplateType,
  }
}
