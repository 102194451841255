/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PortfolioWorkListPublic } from './PortfolioWorkListPublic'
import {
  PortfolioWorkListPublicFromJSON,
  PortfolioWorkListPublicFromJSONTyped,
  PortfolioWorkListPublicToJSON,
} from './PortfolioWorkListPublic'

/**
 *
 * @export
 * @interface PaginatedPortfolioWorkListPublic
 */
export interface PaginatedPortfolioWorkListPublic {
  /**
   *
   * @type {Array<PortfolioWorkListPublic>}
   * @memberof PaginatedPortfolioWorkListPublic
   */
  results: Array<PortfolioWorkListPublic>
  /**
   *
   * @type {number}
   * @memberof PaginatedPortfolioWorkListPublic
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedPortfolioWorkListPublic
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedPortfolioWorkListPublic
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedPortfolioWorkListPublic interface.
 */
export function instanceOfPaginatedPortfolioWorkListPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedPortfolioWorkListPublicFromJSON(
  json: any,
): PaginatedPortfolioWorkListPublic {
  return PaginatedPortfolioWorkListPublicFromJSONTyped(json, false)
}

export function PaginatedPortfolioWorkListPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPortfolioWorkListPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(PortfolioWorkListPublicFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedPortfolioWorkListPublicToJSON(
  value?: PaginatedPortfolioWorkListPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(PortfolioWorkListPublicToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
