/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CategoryServiceDetailUser } from './CategoryServiceDetailUser'
import {
  CategoryServiceDetailUserFromJSON,
  CategoryServiceDetailUserFromJSONTyped,
  CategoryServiceDetailUserToJSON,
} from './CategoryServiceDetailUser'

/**
 *
 * @export
 * @interface CategoryObject
 */
export interface CategoryObject {
  /**
   *
   * @type {number}
   * @memberof CategoryObject
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof CategoryObject
   */
  readonly slug: string
  /**
   *
   * @type {string}
   * @memberof CategoryObject
   */
  iconImg?: string | null
  /**
   *
   * @type {string}
   * @memberof CategoryObject
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CategoryObject
   */
  readonly servicesAmount: number
  /**
   *
   * @type {Array<CategoryServiceDetailUser>}
   * @memberof CategoryObject
   */
  services: Array<CategoryServiceDetailUser>
}

/**
 * Check if a given object implements the CategoryObject interface.
 */
export function instanceOfCategoryObject(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'slug' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'servicesAmount' in value
  isInstance = isInstance && 'services' in value

  return isInstance
}

export function CategoryObjectFromJSON(json: any): CategoryObject {
  return CategoryObjectFromJSONTyped(json, false)
}

export function CategoryObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategoryObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    slug: json['slug'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    name: json['name'],
    servicesAmount: json['servicesAmount'],
    services: (json['services'] as Array<any>).map(CategoryServiceDetailUserFromJSON),
  }
}

export function CategoryObjectToJSON(value?: CategoryObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    iconImg: value.iconImg,
    name: value.name,
    services: (value.services as Array<any>).map(CategoryServiceDetailUserToJSON),
  }
}
