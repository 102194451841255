/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Master
 */
export interface Master {
  /**
   *
   * @type {number}
   * @memberof Master
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof Master
   */
  fullName: string
  /**
   *
   * @type {number}
   * @memberof Master
   */
  readonly ordersAmount: number
}

/**
 * Check if a given object implements the Master interface.
 */
export function instanceOfMaster(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'fullName' in value
  isInstance = isInstance && 'ordersAmount' in value

  return isInstance
}

export function MasterFromJSON(json: any): Master {
  return MasterFromJSONTyped(json, false)
}

export function MasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Master {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    fullName: json['fullName'],
    ordersAmount: json['ordersAmount'],
  }
}

export function MasterToJSON(value?: Master | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    fullName: value.fullName,
  }
}
