/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  EnvelopedLocationWrapperLocationPublic,
  EnvelopedRegionListRegionPublic,
} from '../models'
import {
  EnvelopedLocationWrapperLocationPublicFromJSON,
  EnvelopedLocationWrapperLocationPublicToJSON,
  EnvelopedRegionListRegionPublicFromJSON,
  EnvelopedRegionListRegionPublicToJSON,
} from '../models'

export interface LocationLocationListRequest {
  serviceSlug: string
}

/**
 *
 */
export class LocationApi extends runtime.BaseAPI {
  /**
   */
  async locationLocationListRaw(
    requestParameters: LocationLocationListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedLocationWrapperLocationPublic>> {
    if (requestParameters.serviceSlug === null || requestParameters.serviceSlug === undefined) {
      throw new runtime.RequiredError(
        'serviceSlug',
        'Required parameter requestParameters.serviceSlug was null or undefined when calling locationLocationList.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.serviceSlug !== undefined) {
      queryParameters['serviceSlug'] = requestParameters.serviceSlug
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/location/location/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedLocationWrapperLocationPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async locationLocationList(
    requestParameters: LocationLocationListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedLocationWrapperLocationPublic> {
    const response = await this.locationLocationListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async locationRegionListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedRegionListRegionPublic>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/location/region/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedRegionListRegionPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async locationRegionList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedRegionListRegionPublic> {
    const response = await this.locationRegionListRaw(initOverrides)
    return await response.value()
  }
}
