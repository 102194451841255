/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { EnvelopedCalculatorListCalculatorListPublic } from '../models'
import {
  EnvelopedCalculatorListCalculatorListPublicFromJSON,
  EnvelopedCalculatorListCalculatorListPublicToJSON,
} from '../models'

export interface CalculatorListRequest {
  entityId?: number
  isGlobal?: boolean
  page?: number
  regionId?: number
  serviceTemplateType?: CalculatorListServiceTemplateTypeEnum
  size?: number
  type?: CalculatorListTypeEnum
}

/**
 *
 */
export class CalculatorApi extends runtime.BaseAPI {
  /**
   */
  async calculatorListRaw(
    requestParameters: CalculatorListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedCalculatorListCalculatorListPublic>> {
    const queryParameters: any = {}

    if (requestParameters.entityId !== undefined) {
      queryParameters['entityId'] = requestParameters.entityId
    }

    if (requestParameters.isGlobal !== undefined) {
      queryParameters['isGlobal'] = requestParameters.isGlobal
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.regionId !== undefined) {
      queryParameters['regionId'] = requestParameters.regionId
    }

    if (requestParameters.serviceTemplateType !== undefined) {
      queryParameters['serviceTemplateType'] = requestParameters.serviceTemplateType
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/calculator/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedCalculatorListCalculatorListPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async calculatorList(
    requestParameters: CalculatorListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedCalculatorListCalculatorListPublic> {
    const response = await this.calculatorListRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const CalculatorListServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type CalculatorListServiceTemplateTypeEnum =
  (typeof CalculatorListServiceTemplateTypeEnum)[keyof typeof CalculatorListServiceTemplateTypeEnum]
/**
 * @export
 */
export const CalculatorListTypeEnum = {
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type CalculatorListTypeEnum =
  (typeof CalculatorListTypeEnum)[keyof typeof CalculatorListTypeEnum]
