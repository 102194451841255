/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RegionListAdmin
 */
export interface RegionListAdmin {
  /**
   *
   * @type {number}
   * @memberof RegionListAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof RegionListAdmin
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof RegionListAdmin
   */
  readonly locationsAmount: number
  /**
   *
   * @type {string}
   * @memberof RegionListAdmin
   */
  subdomain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof RegionListAdmin
   */
  isEnabled?: boolean
  /**
   *
   * @type {string}
   * @memberof RegionListAdmin
   */
  province?: string | null
  /**
   *
   * @type {string}
   * @memberof RegionListAdmin
   */
  declination?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof RegionListAdmin
   */
  cityList?: Array<string>
}

/**
 * Check if a given object implements the RegionListAdmin interface.
 */
export function instanceOfRegionListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'locationsAmount' in value

  return isInstance
}

export function RegionListAdminFromJSON(json: any): RegionListAdmin {
  return RegionListAdminFromJSONTyped(json, false)
}

export function RegionListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RegionListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    locationsAmount: json['locationsAmount'],
    subdomain: !exists(json, 'subdomain') ? undefined : json['subdomain'],
    isEnabled: !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
    province: !exists(json, 'province') ? undefined : json['province'],
    declination: !exists(json, 'declination') ? undefined : json['declination'],
    cityList: !exists(json, 'cityList') ? undefined : json['cityList'],
  }
}

export function RegionListAdminToJSON(value?: RegionListAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    subdomain: value.subdomain,
    isEnabled: value.isEnabled,
    province: value.province,
    declination: value.declination,
    cityList: value.cityList,
  }
}
