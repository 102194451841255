/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedObjectUpdateRequest
 */
export interface PatchedObjectUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedObjectUpdateRequest
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof PatchedObjectUpdateRequest
   */
  categoryId?: number
  /**
   *
   * @type {Blob}
   * @memberof PatchedObjectUpdateRequest
   */
  iconImg?: Blob | null
  /**
   *
   * @type {number}
   * @memberof PatchedObjectUpdateRequest
   */
  regionId?: number
  /**
   *
   * @type {Blob}
   * @memberof PatchedObjectUpdateRequest
   */
  backgroundImg?: Blob | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedObjectUpdateRequest
   */
  isVisible?: boolean | null
  /**
   *
   * @type {string}
   * @memberof PatchedObjectUpdateRequest
   */
  slug?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedObjectUpdateRequest
   */
  additionalCategoriesIds?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedObjectUpdateRequest
   */
  headerMain?: string
  /**
   *
   * @type {string}
   * @memberof PatchedObjectUpdateRequest
   */
  titleTagMain?: string
  /**
   *
   * @type {string}
   * @memberof PatchedObjectUpdateRequest
   */
  descriptionTagMain?: string
  /**
   *
   * @type {boolean}
   * @memberof PatchedObjectUpdateRequest
   */
  useLocalMetaMain?: boolean
}

/**
 * Check if a given object implements the PatchedObjectUpdateRequest interface.
 */
export function instanceOfPatchedObjectUpdateRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedObjectUpdateRequestFromJSON(json: any): PatchedObjectUpdateRequest {
  return PatchedObjectUpdateRequestFromJSONTyped(json, false)
}

export function PatchedObjectUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedObjectUpdateRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    categoryId: !exists(json, 'categoryId') ? undefined : json['categoryId'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    backgroundImg: !exists(json, 'backgroundImg') ? undefined : json['backgroundImg'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    additionalCategoriesIds: !exists(json, 'additionalCategoriesIds')
      ? undefined
      : json['additionalCategoriesIds'],
    headerMain: !exists(json, 'headerMain') ? undefined : json['headerMain'],
    titleTagMain: !exists(json, 'titleTagMain') ? undefined : json['titleTagMain'],
    descriptionTagMain: !exists(json, 'descriptionTagMain')
      ? undefined
      : json['descriptionTagMain'],
    useLocalMetaMain: !exists(json, 'useLocalMetaMain') ? undefined : json['useLocalMetaMain'],
  }
}

export function PatchedObjectUpdateRequestToJSON(value?: PatchedObjectUpdateRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    categoryId: value.categoryId,
    iconImg: value.iconImg,
    regionId: value.regionId,
    backgroundImg: value.backgroundImg,
    isVisible: value.isVisible,
    slug: value.slug,
    additionalCategoriesIds: value.additionalCategoriesIds,
    headerMain: value.headerMain,
    titleTagMain: value.titleTagMain,
    descriptionTagMain: value.descriptionTagMain,
    useLocalMetaMain: value.useLocalMetaMain,
  }
}
