/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ContentCardRequest } from './ContentCardRequest'
import {
  ContentCardRequestFromJSON,
  ContentCardRequestFromJSONTyped,
  ContentCardRequestToJSON,
} from './ContentCardRequest'
import type { CustomContentJSONRequest } from './CustomContentJSONRequest'
import {
  CustomContentJSONRequestFromJSON,
  CustomContentJSONRequestFromJSONTyped,
  CustomContentJSONRequestToJSON,
} from './CustomContentJSONRequest'

/**
 * Adds update nested feature
 * @export
 * @interface PatchedContentRequest
 */
export interface PatchedContentRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedContentRequest
   */
  header?: string | null
  /**
   *
   * @type {CustomContentJSONRequest}
   * @memberof PatchedContentRequest
   */
  otherData?: CustomContentJSONRequest | null
  /**
   *
   * @type {number}
   * @memberof PatchedContentRequest
   */
  entityId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedContentRequest
   */
  usedDefault?: boolean
  /**
   *
   * @type {Array<ContentCardRequest>}
   * @memberof PatchedContentRequest
   */
  cards?: Array<ContentCardRequest>
  /**
   *
   * @type {Blob}
   * @memberof PatchedContentRequest
   */
  iconImg?: Blob | null
}

/**
 * Check if a given object implements the PatchedContentRequest interface.
 */
export function instanceOfPatchedContentRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedContentRequestFromJSON(json: any): PatchedContentRequest {
  return PatchedContentRequestFromJSONTyped(json, false)
}

export function PatchedContentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedContentRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    header: !exists(json, 'header') ? undefined : json['header'],
    otherData: !exists(json, 'otherData')
      ? undefined
      : CustomContentJSONRequestFromJSON(json['otherData']),
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    usedDefault: !exists(json, 'usedDefault') ? undefined : json['usedDefault'],
    cards: !exists(json, 'cards')
      ? undefined
      : (json['cards'] as Array<any>).map(ContentCardRequestFromJSON),
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
  }
}

export function PatchedContentRequestToJSON(value?: PatchedContentRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    header: value.header,
    otherData: CustomContentJSONRequestToJSON(value.otherData),
    entityId: value.entityId,
    usedDefault: value.usedDefault,
    cards:
      value.cards === undefined
        ? undefined
        : (value.cards as Array<any>).map(ContentCardRequestToJSON),
    iconImg: value.iconImg,
  }
}
