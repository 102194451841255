/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CategorySeoFields
 */
export interface CategorySeoFields {
  /**
   *
   * @type {string}
   * @memberof CategorySeoFields
   */
  headerMain?: string | null
  /**
   *
   * @type {string}
   * @memberof CategorySeoFields
   */
  titleTagMain?: string | null
  /**
   *
   * @type {string}
   * @memberof CategorySeoFields
   */
  descriptionTagMain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CategorySeoFields
   */
  useLocalMetaMain?: boolean
  /**
   *
   * @type {string}
   * @memberof CategorySeoFields
   */
  descriptionMain?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CategorySeoFields
   */
  useLocalDescriptionMain?: boolean
}

/**
 * Check if a given object implements the CategorySeoFields interface.
 */
export function instanceOfCategorySeoFields(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CategorySeoFieldsFromJSON(json: any): CategorySeoFields {
  return CategorySeoFieldsFromJSONTyped(json, false)
}

export function CategorySeoFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategorySeoFields {
  if (json === undefined || json === null) {
    return json
  }
  return {
    headerMain: !exists(json, 'headerMain') ? undefined : json['headerMain'],
    titleTagMain: !exists(json, 'titleTagMain') ? undefined : json['titleTagMain'],
    descriptionTagMain: !exists(json, 'descriptionTagMain')
      ? undefined
      : json['descriptionTagMain'],
    useLocalMetaMain: !exists(json, 'useLocalMetaMain') ? undefined : json['useLocalMetaMain'],
    descriptionMain: !exists(json, 'descriptionMain') ? undefined : json['descriptionMain'],
    useLocalDescriptionMain: !exists(json, 'useLocalDescriptionMain')
      ? undefined
      : json['useLocalDescriptionMain'],
  }
}

export function CategorySeoFieldsToJSON(value?: CategorySeoFields | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    headerMain: value.headerMain,
    titleTagMain: value.titleTagMain,
    descriptionTagMain: value.descriptionTagMain,
    useLocalMetaMain: value.useLocalMetaMain,
    descriptionMain: value.descriptionMain,
    useLocalDescriptionMain: value.useLocalDescriptionMain,
  }
}
