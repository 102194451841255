/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CategoryObject } from './CategoryObject'
import {
  CategoryObjectFromJSON,
  CategoryObjectFromJSONTyped,
  CategoryObjectToJSON,
} from './CategoryObject'
import type { CategorySeoFields } from './CategorySeoFields'
import {
  CategorySeoFieldsFromJSON,
  CategorySeoFieldsFromJSONTyped,
  CategorySeoFieldsToJSON,
} from './CategorySeoFields'
import type { MainPageTag } from './MainPageTag'
import { MainPageTagFromJSON, MainPageTagFromJSONTyped, MainPageTagToJSON } from './MainPageTag'

/**
 *
 * @export
 * @interface CategoryDetailAdmin
 */
export interface CategoryDetailAdmin {
  /**
   *
   * @type {number}
   * @memberof CategoryDetailAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof CategoryDetailAdmin
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CategoryDetailAdmin
   */
  iconImg?: string | null
  /**
   *
   * @type {number}
   * @memberof CategoryDetailAdmin
   */
  readonly objectsAmount: number
  /**
   *
   * @type {number}
   * @memberof CategoryDetailAdmin
   */
  readonly servicesAmount: number
  /**
   *
   * @type {string}
   * @memberof CategoryDetailAdmin
   */
  backgroundImg?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CategoryDetailAdmin
   */
  isVisible?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof CategoryDetailAdmin
   */
  readonly fromCrm: boolean
  /**
   *
   * @type {MainPageTag}
   * @memberof CategoryDetailAdmin
   */
  readonly tag: MainPageTag | null
  /**
   *
   * @type {string}
   * @memberof CategoryDetailAdmin
   */
  slug?: string | null
  /**
   *
   * @type {CategorySeoFields}
   * @memberof CategoryDetailAdmin
   */
  readonly seoData: CategorySeoFields | null
  /**
   *
   * @type {Array<CategoryObject>}
   * @memberof CategoryDetailAdmin
   */
  readonly objects: Array<CategoryObject>
  /**
   * * `global` - Global
   * * `local` - Local
   * * `both` - Both
   * @type {string}
   * @memberof CategoryDetailAdmin
   */
  faqUsageType?: CategoryDetailAdminFaqUsageTypeEnum
}

/**
 * @export
 */
export const CategoryDetailAdminFaqUsageTypeEnum = {
  Global: 'global',
  Local: 'local',
  Both: 'both',
} as const
export type CategoryDetailAdminFaqUsageTypeEnum =
  (typeof CategoryDetailAdminFaqUsageTypeEnum)[keyof typeof CategoryDetailAdminFaqUsageTypeEnum]

/**
 * Check if a given object implements the CategoryDetailAdmin interface.
 */
export function instanceOfCategoryDetailAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'objectsAmount' in value
  isInstance = isInstance && 'servicesAmount' in value
  isInstance = isInstance && 'fromCrm' in value
  isInstance = isInstance && 'tag' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'objects' in value

  return isInstance
}

export function CategoryDetailAdminFromJSON(json: any): CategoryDetailAdmin {
  return CategoryDetailAdminFromJSONTyped(json, false)
}

export function CategoryDetailAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategoryDetailAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    objectsAmount: json['objectsAmount'],
    servicesAmount: json['servicesAmount'],
    backgroundImg: !exists(json, 'backgroundImg') ? undefined : json['backgroundImg'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    fromCrm: json['fromCrm'],
    tag: MainPageTagFromJSON(json['tag']),
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    seoData: CategorySeoFieldsFromJSON(json['seoData']),
    objects: (json['objects'] as Array<any>).map(CategoryObjectFromJSON),
    faqUsageType: !exists(json, 'faqUsageType') ? undefined : json['faqUsageType'],
  }
}

export function CategoryDetailAdminToJSON(value?: CategoryDetailAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    backgroundImg: value.backgroundImg,
    isVisible: value.isVisible,
    slug: value.slug,
    faqUsageType: value.faqUsageType,
  }
}
