/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { EnvelopedStaticPageStaticPageDetail } from '../models'
import {
  EnvelopedStaticPageStaticPageDetailFromJSON,
  EnvelopedStaticPageStaticPageDetailToJSON,
} from '../models'

export interface StaticPageRetrieveRequest {
  slug: string
  gallerySize?: number
}

/**
 *
 */
export class StaticPageApi extends runtime.BaseAPI {
  /**
   */
  async staticPageRetrieveRaw(
    requestParameters: StaticPageRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedStaticPageStaticPageDetail>> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
      throw new runtime.RequiredError(
        'slug',
        'Required parameter requestParameters.slug was null or undefined when calling staticPageRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.gallerySize !== undefined) {
      queryParameters['gallerySize'] = requestParameters.gallerySize
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/static_page/{slug}/`.replace(
          `{${'slug'}}`,
          encodeURIComponent(String(requestParameters.slug)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedStaticPageStaticPageDetailFromJSON(jsonValue),
    )
  }

  /**
   */
  async staticPageRetrieve(
    requestParameters: StaticPageRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedStaticPageStaticPageDetail> {
    const response = await this.staticPageRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
