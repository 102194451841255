/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Calculator } from './Calculator'
import { CalculatorFromJSON, CalculatorFromJSONTyped, CalculatorToJSON } from './Calculator'
import type { Content } from './Content'
import { ContentFromJSON, ContentFromJSONTyped, ContentToJSON } from './Content'
import type { FAQ } from './FAQ'
import { FAQFromJSON, FAQFromJSONTyped, FAQToJSON } from './FAQ'
import type { ObjectCategory } from './ObjectCategory'
import {
  ObjectCategoryFromJSON,
  ObjectCategoryFromJSONTyped,
  ObjectCategoryToJSON,
} from './ObjectCategory'
import type { ServiceLocationSeoFields } from './ServiceLocationSeoFields'
import {
  ServiceLocationSeoFieldsFromJSON,
  ServiceLocationSeoFieldsFromJSONTyped,
  ServiceLocationSeoFieldsToJSON,
} from './ServiceLocationSeoFields'
import type { ServiceObject } from './ServiceObject'
import {
  ServiceObjectFromJSON,
  ServiceObjectFromJSONTyped,
  ServiceObjectToJSON,
} from './ServiceObject'
import type { ServiceSeoFields } from './ServiceSeoFields'
import {
  ServiceSeoFieldsFromJSON,
  ServiceSeoFieldsFromJSONTyped,
  ServiceSeoFieldsToJSON,
} from './ServiceSeoFields'
import type { SimpleReview } from './SimpleReview'
import {
  SimpleReviewFromJSON,
  SimpleReviewFromJSONTyped,
  SimpleReviewToJSON,
} from './SimpleReview'

/**
 *
 * @export
 * @interface ServiceDetailUser
 */
export interface ServiceDetailUser {
  /**
   *
   * @type {number}
   * @memberof ServiceDetailUser
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ServiceDetailUser
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof ServiceDetailUser
   */
  price?: number | null
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof ServiceDetailUser
   */
  currentTemplate: ServiceDetailUserCurrentTemplateEnum
  /**
   *
   * @type {string}
   * @memberof ServiceDetailUser
   */
  readonly pageType: string
  /**
   *
   * @type {boolean}
   * @memberof ServiceDetailUser
   */
  isVisible?: boolean | null
  /**
   *
   * @type {string}
   * @memberof ServiceDetailUser
   */
  measureUnit?: string | null
  /**
   *
   * @type {string}
   * @memberof ServiceDetailUser
   */
  slug?: string | null
  /**
   *
   * @type {ObjectCategory}
   * @memberof ServiceDetailUser
   */
  readonly category: ObjectCategory
  /**
   *
   * @type {ServiceObject}
   * @memberof ServiceDetailUser
   */
  readonly object: ServiceObject
  /**
   *
   * @type {Array<Content>}
   * @memberof ServiceDetailUser
   */
  readonly content: Array<Content> | null
  /**
   *
   * @type {ServiceSeoFields}
   * @memberof ServiceDetailUser
   */
  readonly seoData: ServiceSeoFields | null
  /**
   *
   * @type {ServiceLocationSeoFields}
   * @memberof ServiceDetailUser
   */
  readonly locationSeoData: ServiceLocationSeoFields | null
  /**
   *
   * @type {Array<FAQ>}
   * @memberof ServiceDetailUser
   */
  readonly faq: Array<FAQ> | null
  /**
   *
   * @type {Calculator}
   * @memberof ServiceDetailUser
   */
  readonly calculator: Calculator | null
  /**
   *
   * @type {Array<SimpleReview>}
   * @memberof ServiceDetailUser
   */
  readonly review: Array<SimpleReview> | null
}

/**
 * @export
 */
export const ServiceDetailUserCurrentTemplateEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type ServiceDetailUserCurrentTemplateEnum =
  (typeof ServiceDetailUserCurrentTemplateEnum)[keyof typeof ServiceDetailUserCurrentTemplateEnum]

/**
 * Check if a given object implements the ServiceDetailUser interface.
 */
export function instanceOfServiceDetailUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'currentTemplate' in value
  isInstance = isInstance && 'pageType' in value
  isInstance = isInstance && 'category' in value
  isInstance = isInstance && 'object' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'locationSeoData' in value
  isInstance = isInstance && 'faq' in value
  isInstance = isInstance && 'calculator' in value
  isInstance = isInstance && 'review' in value

  return isInstance
}

export function ServiceDetailUserFromJSON(json: any): ServiceDetailUser {
  return ServiceDetailUserFromJSONTyped(json, false)
}

export function ServiceDetailUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ServiceDetailUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    currentTemplate: json['currentTemplate'],
    pageType: json['pageType'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    measureUnit: !exists(json, 'measureUnit') ? undefined : json['measureUnit'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    category: ObjectCategoryFromJSON(json['category']),
    object: ServiceObjectFromJSON(json['object']),
    content:
      json['content'] === null ? null : (json['content'] as Array<any>).map(ContentFromJSON),
    seoData: ServiceSeoFieldsFromJSON(json['seoData']),
    locationSeoData: ServiceLocationSeoFieldsFromJSON(json['locationSeoData']),
    faq: json['faq'] === null ? null : (json['faq'] as Array<any>).map(FAQFromJSON),
    calculator: CalculatorFromJSON(json['calculator']),
    review:
      json['review'] === null ? null : (json['review'] as Array<any>).map(SimpleReviewFromJSON),
  }
}

export function ServiceDetailUserToJSON(value?: ServiceDetailUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    price: value.price,
    currentTemplate: value.currentTemplate,
    isVisible: value.isVisible,
    measureUnit: value.measureUnit,
    slug: value.slug,
  }
}
