/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { CategoryUser } from './CategoryUser'
import {
  CategoryUserFromJSON,
  CategoryUserFromJSONTyped,
  CategoryUserToJSON,
} from './CategoryUser'

/**
 *
 * @export
 * @interface EnvelopedCategoryListCategoryUser
 */
export interface EnvelopedCategoryListCategoryUser {
  /**
   *
   * @type {Array<CategoryUser>}
   * @memberof EnvelopedCategoryListCategoryUser
   */
  body: Array<CategoryUser>
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedCategoryListCategoryUser
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedCategoryListCategoryUser interface.
 */
export function instanceOfEnvelopedCategoryListCategoryUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedCategoryListCategoryUserFromJSON(
  json: any,
): EnvelopedCategoryListCategoryUser {
  return EnvelopedCategoryListCategoryUserFromJSONTyped(json, false)
}

export function EnvelopedCategoryListCategoryUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedCategoryListCategoryUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: (json['body'] as Array<any>).map(CategoryUserFromJSON),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedCategoryListCategoryUserToJSON(
  value?: EnvelopedCategoryListCategoryUser | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: (value.body as Array<any>).map(CategoryUserToJSON),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
