/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  EnvelopedMainPageTagListMainPageTagListAdmin,
  EnvelopedMainPageTagUpdate,
  PatchedMainPageTagRequest,
} from '../models'
import {
  EnvelopedMainPageTagListMainPageTagListAdminFromJSON,
  EnvelopedMainPageTagListMainPageTagListAdminToJSON,
  EnvelopedMainPageTagUpdateFromJSON,
  EnvelopedMainPageTagUpdateToJSON,
  PatchedMainPageTagRequestFromJSON,
  PatchedMainPageTagRequestToJSON,
} from '../models'

export interface TagMainPageTagListRequest {
  page?: number
  size?: number
}

export interface TagMainPageTagPartialUpdateRequest {
  id: number
  patchedMainPageTagRequest?: PatchedMainPageTagRequest
}

/**
 *
 */
export class TagApi extends runtime.BaseAPI {
  /**
   */
  async tagMainPageTagListRaw(
    requestParameters: TagMainPageTagListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedMainPageTagListMainPageTagListAdmin>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/tag/main_page_tag/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedMainPageTagListMainPageTagListAdminFromJSON(jsonValue),
    )
  }

  /**
   */
  async tagMainPageTagList(
    requestParameters: TagMainPageTagListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedMainPageTagListMainPageTagListAdmin> {
    const response = await this.tagMainPageTagListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async tagMainPageTagPartialUpdateRaw(
    requestParameters: TagMainPageTagPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedMainPageTagUpdate>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling tagMainPageTagPartialUpdate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/tag/main_page_tag/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedMainPageTagRequestToJSON(requestParameters.patchedMainPageTagRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedMainPageTagUpdateFromJSON(jsonValue),
    )
  }

  /**
   */
  async tagMainPageTagPartialUpdate(
    requestParameters: TagMainPageTagPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedMainPageTagUpdate> {
    const response = await this.tagMainPageTagPartialUpdateRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
