/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PaginatedContentAdmin } from './PaginatedContentAdmin'
import {
  PaginatedContentAdminFromJSON,
  PaginatedContentAdminFromJSONTyped,
  PaginatedContentAdminToJSON,
} from './PaginatedContentAdmin'

/**
 *
 * @export
 * @interface EnvelopedContentListContentAdmin
 */
export interface EnvelopedContentListContentAdmin {
  /**
   *
   * @type {PaginatedContentAdmin}
   * @memberof EnvelopedContentListContentAdmin
   */
  body: PaginatedContentAdmin
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedContentListContentAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedContentListContentAdmin interface.
 */
export function instanceOfEnvelopedContentListContentAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedContentListContentAdminFromJSON(
  json: any,
): EnvelopedContentListContentAdmin {
  return EnvelopedContentListContentAdminFromJSONTyped(json, false)
}

export function EnvelopedContentListContentAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedContentListContentAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PaginatedContentAdminFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedContentListContentAdminToJSON(
  value?: EnvelopedContentListContentAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PaginatedContentAdminToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
