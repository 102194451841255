/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AuthTokenRequestRequest
 */
export interface AuthTokenRequestRequest {
  /**
   *
   * @type {string}
   * @memberof AuthTokenRequestRequest
   */
  emailOrPhone: string
  /**
   *
   * @type {string}
   * @memberof AuthTokenRequestRequest
   */
  password: string
}

/**
 * Check if a given object implements the AuthTokenRequestRequest interface.
 */
export function instanceOfAuthTokenRequestRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'emailOrPhone' in value
  isInstance = isInstance && 'password' in value

  return isInstance
}

export function AuthTokenRequestRequestFromJSON(json: any): AuthTokenRequestRequest {
  return AuthTokenRequestRequestFromJSONTyped(json, false)
}

export function AuthTokenRequestRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthTokenRequestRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    emailOrPhone: json['emailOrPhone'],
    password: json['password'],
  }
}

export function AuthTokenRequestRequestToJSON(value?: AuthTokenRequestRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    emailOrPhone: value.emailOrPhone,
    password: value.password,
  }
}
