/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { OpenApiCatalog } from './OpenApiCatalog'
import {
  OpenApiCatalogFromJSON,
  OpenApiCatalogFromJSONTyped,
  OpenApiCatalogToJSON,
} from './OpenApiCatalog'

/**
 *
 * @export
 * @interface EnvelopedOpenApiCatalogList
 */
export interface EnvelopedOpenApiCatalogList {
  /**
   *
   * @type {Array<OpenApiCatalog>}
   * @memberof EnvelopedOpenApiCatalogList
   */
  body: Array<OpenApiCatalog>
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedOpenApiCatalogList
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedOpenApiCatalogList interface.
 */
export function instanceOfEnvelopedOpenApiCatalogList(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedOpenApiCatalogListFromJSON(json: any): EnvelopedOpenApiCatalogList {
  return EnvelopedOpenApiCatalogListFromJSONTyped(json, false)
}

export function EnvelopedOpenApiCatalogListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedOpenApiCatalogList {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: (json['body'] as Array<any>).map(OpenApiCatalogFromJSON),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedOpenApiCatalogListToJSON(
  value?: EnvelopedOpenApiCatalogList | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: (value.body as Array<any>).map(OpenApiCatalogToJSON),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
