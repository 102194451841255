/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedFAQRequest
 */
export interface PatchedFAQRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedFAQRequest
   */
  answer?: string
  /**
   *
   * @type {string}
   * @memberof PatchedFAQRequest
   */
  question?: string
  /**
   * * `category` - Category
   * * `object` - Object
   * * `service` - Service
   * * `static_page` - Static Page
   * @type {string}
   * @memberof PatchedFAQRequest
   */
  type?: PatchedFAQRequestTypeEnum
  /**
   *
   * @type {number}
   * @memberof PatchedFAQRequest
   */
  entityId?: number | null
  /**
   *
   * @type {number}
   * @memberof PatchedFAQRequest
   */
  score?: number | null
  /**
   *
   * @type {number}
   * @memberof PatchedFAQRequest
   */
  regionId?: number
  /**
   * * `1` - Template 1
   * * `2` - Template 2
   * * `3` - Template 3
   * @type {number}
   * @memberof PatchedFAQRequest
   */
  serviceTemplateType?: PatchedFAQRequestServiceTemplateTypeEnum
}

/**
 * @export
 */
export const PatchedFAQRequestTypeEnum = {
  Category: 'category',
  Object: 'object',
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type PatchedFAQRequestTypeEnum =
  (typeof PatchedFAQRequestTypeEnum)[keyof typeof PatchedFAQRequestTypeEnum]

/**
 * @export
 */
export const PatchedFAQRequestServiceTemplateTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const
export type PatchedFAQRequestServiceTemplateTypeEnum =
  (typeof PatchedFAQRequestServiceTemplateTypeEnum)[keyof typeof PatchedFAQRequestServiceTemplateTypeEnum]

/**
 * Check if a given object implements the PatchedFAQRequest interface.
 */
export function instanceOfPatchedFAQRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedFAQRequestFromJSON(json: any): PatchedFAQRequest {
  return PatchedFAQRequestFromJSONTyped(json, false)
}

export function PatchedFAQRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedFAQRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    answer: !exists(json, 'answer') ? undefined : json['answer'],
    question: !exists(json, 'question') ? undefined : json['question'],
    type: !exists(json, 'type') ? undefined : json['type'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    score: !exists(json, 'score') ? undefined : json['score'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    serviceTemplateType: !exists(json, 'serviceTemplateType')
      ? undefined
      : json['serviceTemplateType'],
  }
}

export function PatchedFAQRequestToJSON(value?: PatchedFAQRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    answer: value.answer,
    question: value.question,
    type: value.type,
    entityId: value.entityId,
    score: value.score,
    regionId: value.regionId,
    serviceTemplateType: value.serviceTemplateType,
  }
}
