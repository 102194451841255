/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedServiceUpdateRequest
 */
export interface PatchedServiceUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof PatchedServiceUpdateRequest
   */
  price?: number | null
  /**
   *
   * @type {boolean}
   * @memberof PatchedServiceUpdateRequest
   */
  isVisible?: boolean | null
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  measureUnit?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  slug?: string | null
  /**
   *
   * @type {number}
   * @memberof PatchedServiceUpdateRequest
   */
  regionId?: number
  /**
   *
   * @type {number}
   * @memberof PatchedServiceUpdateRequest
   */
  objectId?: number
  /**
   *
   * @type {number}
   * @memberof PatchedServiceUpdateRequest
   */
  currentTemplate?: number
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  headerMain?: string
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  titleTagMain?: string
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  descriptionTagMain?: string
  /**
   *
   * @type {boolean}
   * @memberof PatchedServiceUpdateRequest
   */
  useLocalMetaMain?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  descriptionMain?: string
  /**
   *
   * @type {boolean}
   * @memberof PatchedServiceUpdateRequest
   */
  useLocalDescriptionMain?: boolean
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  headerListing?: string
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  titleTagListing?: string
  /**
   *
   * @type {string}
   * @memberof PatchedServiceUpdateRequest
   */
  descriptionTagListing?: string
  /**
   *
   * @type {boolean}
   * @memberof PatchedServiceUpdateRequest
   */
  useLocalMetaListing?: boolean
}

/**
 * Check if a given object implements the PatchedServiceUpdateRequest interface.
 */
export function instanceOfPatchedServiceUpdateRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedServiceUpdateRequestFromJSON(json: any): PatchedServiceUpdateRequest {
  return PatchedServiceUpdateRequestFromJSONTyped(json, false)
}

export function PatchedServiceUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedServiceUpdateRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    measureUnit: !exists(json, 'measureUnit') ? undefined : json['measureUnit'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    regionId: !exists(json, 'regionId') ? undefined : json['regionId'],
    objectId: !exists(json, 'objectId') ? undefined : json['objectId'],
    currentTemplate: !exists(json, 'currentTemplate') ? undefined : json['currentTemplate'],
    headerMain: !exists(json, 'headerMain') ? undefined : json['headerMain'],
    titleTagMain: !exists(json, 'titleTagMain') ? undefined : json['titleTagMain'],
    descriptionTagMain: !exists(json, 'descriptionTagMain')
      ? undefined
      : json['descriptionTagMain'],
    useLocalMetaMain: !exists(json, 'useLocalMetaMain') ? undefined : json['useLocalMetaMain'],
    descriptionMain: !exists(json, 'descriptionMain') ? undefined : json['descriptionMain'],
    useLocalDescriptionMain: !exists(json, 'useLocalDescriptionMain')
      ? undefined
      : json['useLocalDescriptionMain'],
    headerListing: !exists(json, 'headerListing') ? undefined : json['headerListing'],
    titleTagListing: !exists(json, 'titleTagListing') ? undefined : json['titleTagListing'],
    descriptionTagListing: !exists(json, 'descriptionTagListing')
      ? undefined
      : json['descriptionTagListing'],
    useLocalMetaListing: !exists(json, 'useLocalMetaListing')
      ? undefined
      : json['useLocalMetaListing'],
  }
}

export function PatchedServiceUpdateRequestToJSON(
  value?: PatchedServiceUpdateRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    price: value.price,
    isVisible: value.isVisible,
    measureUnit: value.measureUnit,
    slug: value.slug,
    regionId: value.regionId,
    objectId: value.objectId,
    currentTemplate: value.currentTemplate,
    headerMain: value.headerMain,
    titleTagMain: value.titleTagMain,
    descriptionTagMain: value.descriptionTagMain,
    useLocalMetaMain: value.useLocalMetaMain,
    descriptionMain: value.descriptionMain,
    useLocalDescriptionMain: value.useLocalDescriptionMain,
    headerListing: value.headerListing,
    titleTagListing: value.titleTagListing,
    descriptionTagListing: value.descriptionTagListing,
    useLocalMetaListing: value.useLocalMetaListing,
  }
}
