/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ReviewNestedServiceRequest
 */
export interface ReviewNestedServiceRequest {
  /**
   *
   * @type {string}
   * @memberof ReviewNestedServiceRequest
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ReviewNestedServiceRequest
   */
  slug?: string | null
}

/**
 * Check if a given object implements the ReviewNestedServiceRequest interface.
 */
export function instanceOfReviewNestedServiceRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ReviewNestedServiceRequestFromJSON(json: any): ReviewNestedServiceRequest {
  return ReviewNestedServiceRequestFromJSONTyped(json, false)
}

export function ReviewNestedServiceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReviewNestedServiceRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
  }
}

export function ReviewNestedServiceRequestToJSON(value?: ReviewNestedServiceRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    slug: value.slug,
  }
}
