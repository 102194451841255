/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PortfolioTag } from './PortfolioTag'
import {
  PortfolioTagFromJSON,
  PortfolioTagFromJSONTyped,
  PortfolioTagToJSON,
} from './PortfolioTag'

/**
 *
 * @export
 * @interface EnvelopedPortfolioTagPortfolioTagAdmin
 */
export interface EnvelopedPortfolioTagPortfolioTagAdmin {
  /**
   *
   * @type {PortfolioTag}
   * @memberof EnvelopedPortfolioTagPortfolioTagAdmin
   */
  body: PortfolioTag
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedPortfolioTagPortfolioTagAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedPortfolioTagPortfolioTagAdmin interface.
 */
export function instanceOfEnvelopedPortfolioTagPortfolioTagAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedPortfolioTagPortfolioTagAdminFromJSON(
  json: any,
): EnvelopedPortfolioTagPortfolioTagAdmin {
  return EnvelopedPortfolioTagPortfolioTagAdminFromJSONTyped(json, false)
}

export function EnvelopedPortfolioTagPortfolioTagAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedPortfolioTagPortfolioTagAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PortfolioTagFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedPortfolioTagPortfolioTagAdminToJSON(
  value?: EnvelopedPortfolioTagPortfolioTagAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PortfolioTagToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
