/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PortfolioTag } from './PortfolioTag'
import {
  PortfolioTagFromJSON,
  PortfolioTagFromJSONTyped,
  PortfolioTagToJSON,
} from './PortfolioTag'

/**
 *
 * @export
 * @interface PaginatedPortfolioTagListAdmin
 */
export interface PaginatedPortfolioTagListAdmin {
  /**
   *
   * @type {Array<PortfolioTag>}
   * @memberof PaginatedPortfolioTagListAdmin
   */
  results: Array<PortfolioTag>
  /**
   *
   * @type {number}
   * @memberof PaginatedPortfolioTagListAdmin
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedPortfolioTagListAdmin
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedPortfolioTagListAdmin
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedPortfolioTagListAdmin interface.
 */
export function instanceOfPaginatedPortfolioTagListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedPortfolioTagListAdminFromJSON(
  json: any,
): PaginatedPortfolioTagListAdmin {
  return PaginatedPortfolioTagListAdminFromJSONTyped(json, false)
}

export function PaginatedPortfolioTagListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPortfolioTagListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(PortfolioTagFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedPortfolioTagListAdminToJSON(
  value?: PaginatedPortfolioTagListAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(PortfolioTagToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
