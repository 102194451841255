/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DocumentDetailPublic
 */
export interface DocumentDetailPublic {
  /**
   *
   * @type {number}
   * @memberof DocumentDetailPublic
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof DocumentDetailPublic
   */
  slug?: string | null
  /**
   *
   * @type {string}
   * @memberof DocumentDetailPublic
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof DocumentDetailPublic
   */
  text?: string | null
}

/**
 * Check if a given object implements the DocumentDetailPublic interface.
 */
export function instanceOfDocumentDetailPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function DocumentDetailPublicFromJSON(json: any): DocumentDetailPublic {
  return DocumentDetailPublicFromJSONTyped(json, false)
}

export function DocumentDetailPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DocumentDetailPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    name: !exists(json, 'name') ? undefined : json['name'],
    text: !exists(json, 'text') ? undefined : json['text'],
  }
}

export function DocumentDetailPublicToJSON(value?: DocumentDetailPublic | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    slug: value.slug,
    name: value.name,
    text: value.text,
  }
}
