/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PortfolioWorkGallery } from './PortfolioWorkGallery'
import {
  PortfolioWorkGalleryFromJSON,
  PortfolioWorkGalleryFromJSONTyped,
  PortfolioWorkGalleryToJSON,
} from './PortfolioWorkGallery'

/**
 *
 * @export
 * @interface PaginatedPortfolioWorkGalleryListPublic
 */
export interface PaginatedPortfolioWorkGalleryListPublic {
  /**
   *
   * @type {Array<PortfolioWorkGallery>}
   * @memberof PaginatedPortfolioWorkGalleryListPublic
   */
  results: Array<PortfolioWorkGallery>
  /**
   *
   * @type {number}
   * @memberof PaginatedPortfolioWorkGalleryListPublic
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedPortfolioWorkGalleryListPublic
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedPortfolioWorkGalleryListPublic
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedPortfolioWorkGalleryListPublic interface.
 */
export function instanceOfPaginatedPortfolioWorkGalleryListPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedPortfolioWorkGalleryListPublicFromJSON(
  json: any,
): PaginatedPortfolioWorkGalleryListPublic {
  return PaginatedPortfolioWorkGalleryListPublicFromJSONTyped(json, false)
}

export function PaginatedPortfolioWorkGalleryListPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPortfolioWorkGalleryListPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(PortfolioWorkGalleryFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedPortfolioWorkGalleryListPublicToJSON(
  value?: PaginatedPortfolioWorkGalleryListPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(PortfolioWorkGalleryToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
