/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PaginatedReviewPublic } from './PaginatedReviewPublic'
import {
  PaginatedReviewPublicFromJSON,
  PaginatedReviewPublicFromJSONTyped,
  PaginatedReviewPublicToJSON,
} from './PaginatedReviewPublic'

/**
 *
 * @export
 * @interface EnvelopedReviewListReviewPublic
 */
export interface EnvelopedReviewListReviewPublic {
  /**
   *
   * @type {PaginatedReviewPublic}
   * @memberof EnvelopedReviewListReviewPublic
   */
  body: PaginatedReviewPublic
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedReviewListReviewPublic
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedReviewListReviewPublic interface.
 */
export function instanceOfEnvelopedReviewListReviewPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedReviewListReviewPublicFromJSON(
  json: any,
): EnvelopedReviewListReviewPublic {
  return EnvelopedReviewListReviewPublicFromJSONTyped(json, false)
}

export function EnvelopedReviewListReviewPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedReviewListReviewPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PaginatedReviewPublicFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedReviewListReviewPublicToJSON(
  value?: EnvelopedReviewListReviewPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PaginatedReviewPublicToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
