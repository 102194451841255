/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { PortfolioWorkAdmin } from './PortfolioWorkAdmin'
import {
  PortfolioWorkAdminFromJSON,
  PortfolioWorkAdminFromJSONTyped,
  PortfolioWorkAdminToJSON,
} from './PortfolioWorkAdmin'

/**
 *
 * @export
 * @interface EnvelopedPortfolioWorkPortfolioWorkAdmin
 */
export interface EnvelopedPortfolioWorkPortfolioWorkAdmin {
  /**
   *
   * @type {PortfolioWorkAdmin}
   * @memberof EnvelopedPortfolioWorkPortfolioWorkAdmin
   */
  body: PortfolioWorkAdmin
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedPortfolioWorkPortfolioWorkAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedPortfolioWorkPortfolioWorkAdmin interface.
 */
export function instanceOfEnvelopedPortfolioWorkPortfolioWorkAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedPortfolioWorkPortfolioWorkAdminFromJSON(
  json: any,
): EnvelopedPortfolioWorkPortfolioWorkAdmin {
  return EnvelopedPortfolioWorkPortfolioWorkAdminFromJSONTyped(json, false)
}

export function EnvelopedPortfolioWorkPortfolioWorkAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedPortfolioWorkPortfolioWorkAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: PortfolioWorkAdminFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedPortfolioWorkPortfolioWorkAdminToJSON(
  value?: EnvelopedPortfolioWorkPortfolioWorkAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: PortfolioWorkAdminToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
