/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ArchivedCatalogElement
 */
export interface ArchivedCatalogElement {
  /**
   *
   * @type {number}
   * @memberof ArchivedCatalogElement
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ArchivedCatalogElement
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ArchivedCatalogElement
   */
  slug: string
}

/**
 * Check if a given object implements the ArchivedCatalogElement interface.
 */
export function instanceOfArchivedCatalogElement(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'slug' in value

  return isInstance
}

export function ArchivedCatalogElementFromJSON(json: any): ArchivedCatalogElement {
  return ArchivedCatalogElementFromJSONTyped(json, false)
}

export function ArchivedCatalogElementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ArchivedCatalogElement {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    slug: json['slug'],
  }
}

export function ArchivedCatalogElementToJSON(value?: ArchivedCatalogElement | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    slug: value.slug,
  }
}
