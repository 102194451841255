/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CalculatorListPublic
 */
export interface CalculatorListPublic {
  /**
   *
   * @type {number}
   * @memberof CalculatorListPublic
   */
  readonly id: number
  /**
   *
   * @type {any}
   * @memberof CalculatorListPublic
   */
  structure?: any | null
  /**
   * * `service` - service
   * * `static_page` - static_page
   * @type {string}
   * @memberof CalculatorListPublic
   */
  type: CalculatorListPublicTypeEnum
  /**
   *
   * @type {number}
   * @memberof CalculatorListPublic
   */
  entityId?: number | null
  /**
   *
   * @type {number}
   * @memberof CalculatorListPublic
   */
  serviceTemplateType?: number
}

/**
 * @export
 */
export const CalculatorListPublicTypeEnum = {
  Service: 'service',
  StaticPage: 'static_page',
} as const
export type CalculatorListPublicTypeEnum =
  (typeof CalculatorListPublicTypeEnum)[keyof typeof CalculatorListPublicTypeEnum]

/**
 * Check if a given object implements the CalculatorListPublic interface.
 */
export function instanceOfCalculatorListPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function CalculatorListPublicFromJSON(json: any): CalculatorListPublic {
  return CalculatorListPublicFromJSONTyped(json, false)
}

export function CalculatorListPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CalculatorListPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    structure: !exists(json, 'structure') ? undefined : json['structure'],
    type: json['type'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
    serviceTemplateType: !exists(json, 'serviceTemplateType')
      ? undefined
      : json['serviceTemplateType'],
  }
}

export function CalculatorListPublicToJSON(value?: CalculatorListPublic | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    structure: value.structure,
    type: value.type,
    entityId: value.entityId,
    serviceTemplateType: value.serviceTemplateType,
  }
}
