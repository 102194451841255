/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { SeoDefaults } from './SeoDefaults'
import { SeoDefaultsFromJSON, SeoDefaultsFromJSONTyped, SeoDefaultsToJSON } from './SeoDefaults'

/**
 *
 * @export
 * @interface EnvelopedSeoDefaultsSeoDefaultsAdmin
 */
export interface EnvelopedSeoDefaultsSeoDefaultsAdmin {
  /**
   *
   * @type {SeoDefaults}
   * @memberof EnvelopedSeoDefaultsSeoDefaultsAdmin
   */
  body: SeoDefaults
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedSeoDefaultsSeoDefaultsAdmin
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedSeoDefaultsSeoDefaultsAdmin interface.
 */
export function instanceOfEnvelopedSeoDefaultsSeoDefaultsAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedSeoDefaultsSeoDefaultsAdminFromJSON(
  json: any,
): EnvelopedSeoDefaultsSeoDefaultsAdmin {
  return EnvelopedSeoDefaultsSeoDefaultsAdminFromJSONTyped(json, false)
}

export function EnvelopedSeoDefaultsSeoDefaultsAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedSeoDefaultsSeoDefaultsAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: SeoDefaultsFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedSeoDefaultsSeoDefaultsAdminToJSON(
  value?: EnvelopedSeoDefaultsSeoDefaultsAdmin | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: SeoDefaultsToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
