/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedDocumentRequest
 */
export interface PatchedDocumentRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedDocumentRequest
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedDocumentRequest
   */
  text?: string | null
}

/**
 * Check if a given object implements the PatchedDocumentRequest interface.
 */
export function instanceOfPatchedDocumentRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedDocumentRequestFromJSON(json: any): PatchedDocumentRequest {
  return PatchedDocumentRequestFromJSONTyped(json, false)
}

export function PatchedDocumentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedDocumentRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    text: !exists(json, 'text') ? undefined : json['text'],
  }
}

export function PatchedDocumentRequestToJSON(value?: PatchedDocumentRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    text: value.text,
  }
}
