/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  EnvelopedRegionListRegionSearchPublic,
  EnvelopedSearchCatalogCatalogSearchPublic,
} from '../models'
import {
  EnvelopedRegionListRegionSearchPublicFromJSON,
  EnvelopedRegionListRegionSearchPublicToJSON,
  EnvelopedSearchCatalogCatalogSearchPublicFromJSON,
  EnvelopedSearchCatalogCatalogSearchPublicToJSON,
} from '../models'

export interface SearchCatalogRetrieveRequest {
  query: string
}

export interface SearchRegionListRequest {
  query?: string
}

/**
 *
 */
export class SearchApi extends runtime.BaseAPI {
  /**
   */
  async searchCatalogRetrieveRaw(
    requestParameters: SearchCatalogRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedSearchCatalogCatalogSearchPublic>> {
    if (requestParameters.query === null || requestParameters.query === undefined) {
      throw new runtime.RequiredError(
        'query',
        'Required parameter requestParameters.query was null or undefined when calling searchCatalogRetrieve.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/search/catalog/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedSearchCatalogCatalogSearchPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async searchCatalogRetrieve(
    requestParameters: SearchCatalogRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedSearchCatalogCatalogSearchPublic> {
    const response = await this.searchCatalogRetrieveRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async searchRegionListRaw(
    requestParameters: SearchRegionListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnvelopedRegionListRegionSearchPublic>> {
    const queryParameters: any = {}

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('jwtAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/api/search/region/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnvelopedRegionListRegionSearchPublicFromJSON(jsonValue),
    )
  }

  /**
   */
  async searchRegionList(
    requestParameters: SearchRegionListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnvelopedRegionListRegionSearchPublic> {
    const response = await this.searchRegionListRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
