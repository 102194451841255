/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { RegionPublic } from './RegionPublic'
import {
  RegionPublicFromJSON,
  RegionPublicFromJSONTyped,
  RegionPublicToJSON,
} from './RegionPublic'

/**
 *
 * @export
 * @interface EnvelopedRegionListRegionPublic
 */
export interface EnvelopedRegionListRegionPublic {
  /**
   *
   * @type {Array<RegionPublic>}
   * @memberof EnvelopedRegionListRegionPublic
   */
  body: Array<RegionPublic>
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedRegionListRegionPublic
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedRegionListRegionPublic interface.
 */
export function instanceOfEnvelopedRegionListRegionPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedRegionListRegionPublicFromJSON(
  json: any,
): EnvelopedRegionListRegionPublic {
  return EnvelopedRegionListRegionPublicFromJSONTyped(json, false)
}

export function EnvelopedRegionListRegionPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedRegionListRegionPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: (json['body'] as Array<any>).map(RegionPublicFromJSON),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedRegionListRegionPublicToJSON(
  value?: EnvelopedRegionListRegionPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: (value.body as Array<any>).map(RegionPublicToJSON),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
