/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ServiceListAdmin } from './ServiceListAdmin'
import {
  ServiceListAdminFromJSON,
  ServiceListAdminFromJSONTyped,
  ServiceListAdminToJSON,
} from './ServiceListAdmin'

/**
 *
 * @export
 * @interface PaginatedServiceListAdmin
 */
export interface PaginatedServiceListAdmin {
  /**
   *
   * @type {Array<ServiceListAdmin>}
   * @memberof PaginatedServiceListAdmin
   */
  results: Array<ServiceListAdmin>
  /**
   *
   * @type {number}
   * @memberof PaginatedServiceListAdmin
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedServiceListAdmin
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedServiceListAdmin
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedServiceListAdmin interface.
 */
export function instanceOfPaginatedServiceListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedServiceListAdminFromJSON(json: any): PaginatedServiceListAdmin {
  return PaginatedServiceListAdminFromJSONTyped(json, false)
}

export function PaginatedServiceListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedServiceListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(ServiceListAdminFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedServiceListAdminToJSON(value?: PaginatedServiceListAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(ServiceListAdminToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
