/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CatalogItemsAmount
 */
export interface CatalogItemsAmount {
  /**
   *
   * @type {number}
   * @memberof CatalogItemsAmount
   */
  itemsAmount: number
}

/**
 * Check if a given object implements the CatalogItemsAmount interface.
 */
export function instanceOfCatalogItemsAmount(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'itemsAmount' in value

  return isInstance
}

export function CatalogItemsAmountFromJSON(json: any): CatalogItemsAmount {
  return CatalogItemsAmountFromJSONTyped(json, false)
}

export function CatalogItemsAmountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogItemsAmount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    itemsAmount: json['itemsAmount'],
  }
}

export function CatalogItemsAmountToJSON(value?: CatalogItemsAmount | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    itemsAmount: value.itemsAmount,
  }
}
