/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Menu } from './Menu'
import { MenuFromJSON, MenuFromJSONTyped, MenuToJSON } from './Menu'

/**
 *
 * @export
 * @interface ApiMenu
 */
export interface ApiMenu {
  /**
   *
   * @type {Array<Menu>}
   * @memberof ApiMenu
   */
  menu: Array<Menu>
}

/**
 * Check if a given object implements the ApiMenu interface.
 */
export function instanceOfApiMenu(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'menu' in value

  return isInstance
}

export function ApiMenuFromJSON(json: any): ApiMenu {
  return ApiMenuFromJSONTyped(json, false)
}

export function ApiMenuFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMenu {
  if (json === undefined || json === null) {
    return json
  }
  return {
    menu: (json['menu'] as Array<any>).map(MenuFromJSON),
  }
}

export function ApiMenuToJSON(value?: ApiMenu | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    menu: (value.menu as Array<any>).map(MenuToJSON),
  }
}
