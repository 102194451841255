/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PatchedSeoDefaultsRequest
 */
export interface PatchedSeoDefaultsRequest {
  /**
   *
   * @type {string}
   * @memberof PatchedSeoDefaultsRequest
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedSeoDefaultsRequest
   */
  titleTag?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedSeoDefaultsRequest
   */
  descriptionTag?: string | null
  /**
   *
   * @type {string}
   * @memberof PatchedSeoDefaultsRequest
   */
  description?: string | null
}

/**
 * Check if a given object implements the PatchedSeoDefaultsRequest interface.
 */
export function instanceOfPatchedSeoDefaultsRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PatchedSeoDefaultsRequestFromJSON(json: any): PatchedSeoDefaultsRequest {
  return PatchedSeoDefaultsRequestFromJSONTyped(json, false)
}

export function PatchedSeoDefaultsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedSeoDefaultsRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    header: !exists(json, 'header') ? undefined : json['header'],
    titleTag: !exists(json, 'titleTag') ? undefined : json['titleTag'],
    descriptionTag: !exists(json, 'descriptionTag') ? undefined : json['descriptionTag'],
    description: !exists(json, 'description') ? undefined : json['description'],
  }
}

export function PatchedSeoDefaultsRequestToJSON(value?: PatchedSeoDefaultsRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    header: value.header,
    titleTag: value.titleTag,
    descriptionTag: value.descriptionTag,
    description: value.description,
  }
}
