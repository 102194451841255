/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DocumentAdmin } from './DocumentAdmin'
import {
  DocumentAdminFromJSON,
  DocumentAdminFromJSONTyped,
  DocumentAdminToJSON,
} from './DocumentAdmin'

/**
 *
 * @export
 * @interface PaginatedDocumentAdmin
 */
export interface PaginatedDocumentAdmin {
  /**
   *
   * @type {Array<DocumentAdmin>}
   * @memberof PaginatedDocumentAdmin
   */
  results: Array<DocumentAdmin>
  /**
   *
   * @type {number}
   * @memberof PaginatedDocumentAdmin
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PaginatedDocumentAdmin
   */
  next: string | null
  /**
   *
   * @type {string}
   * @memberof PaginatedDocumentAdmin
   */
  previous: string | null
}

/**
 * Check if a given object implements the PaginatedDocumentAdmin interface.
 */
export function instanceOfPaginatedDocumentAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'results' in value
  isInstance = isInstance && 'count' in value
  isInstance = isInstance && 'next' in value
  isInstance = isInstance && 'previous' in value

  return isInstance
}

export function PaginatedDocumentAdminFromJSON(json: any): PaginatedDocumentAdmin {
  return PaginatedDocumentAdminFromJSONTyped(json, false)
}

export function PaginatedDocumentAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedDocumentAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: (json['results'] as Array<any>).map(DocumentAdminFromJSON),
    count: json['count'],
    next: json['next'],
    previous: json['previous'],
  }
}

export function PaginatedDocumentAdminToJSON(value?: PaginatedDocumentAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results: (value.results as Array<any>).map(DocumentAdminToJSON),
    count: value.count,
    next: value.next,
    previous: value.previous,
  }
}
