/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiError } from './ApiError'
import { ApiErrorFromJSON, ApiErrorFromJSONTyped, ApiErrorToJSON } from './ApiError'
import type { ApiMenu } from './ApiMenu'
import { ApiMenuFromJSON, ApiMenuFromJSONTyped, ApiMenuToJSON } from './ApiMenu'

/**
 *
 * @export
 * @interface EnvelopedApiMenuApiMenuPublic
 */
export interface EnvelopedApiMenuApiMenuPublic {
  /**
   *
   * @type {ApiMenu}
   * @memberof EnvelopedApiMenuApiMenuPublic
   */
  body: ApiMenu
  /**
   *
   * @type {Array<ApiError>}
   * @memberof EnvelopedApiMenuApiMenuPublic
   */
  errors: Array<ApiError>
}

/**
 * Check if a given object implements the EnvelopedApiMenuApiMenuPublic interface.
 */
export function instanceOfEnvelopedApiMenuApiMenuPublic(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'body' in value
  isInstance = isInstance && 'errors' in value

  return isInstance
}

export function EnvelopedApiMenuApiMenuPublicFromJSON(json: any): EnvelopedApiMenuApiMenuPublic {
  return EnvelopedApiMenuApiMenuPublicFromJSONTyped(json, false)
}

export function EnvelopedApiMenuApiMenuPublicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnvelopedApiMenuApiMenuPublic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    body: ApiMenuFromJSON(json['body']),
    errors: (json['errors'] as Array<any>).map(ApiErrorFromJSON),
  }
}

export function EnvelopedApiMenuApiMenuPublicToJSON(
  value?: EnvelopedApiMenuApiMenuPublic | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    body: ApiMenuToJSON(value.body),
    errors: (value.errors as Array<any>).map(ApiErrorToJSON),
  }
}
