/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdditionalCategory } from './AdditionalCategory'
import {
  AdditionalCategoryFromJSON,
  AdditionalCategoryFromJSONTyped,
  AdditionalCategoryToJSON,
} from './AdditionalCategory'
import type { ObjectCategory } from './ObjectCategory'
import {
  ObjectCategoryFromJSON,
  ObjectCategoryFromJSONTyped,
  ObjectCategoryToJSON,
} from './ObjectCategory'
import type { ObjectSeoFields } from './ObjectSeoFields'
import {
  ObjectSeoFieldsFromJSON,
  ObjectSeoFieldsFromJSONTyped,
  ObjectSeoFieldsToJSON,
} from './ObjectSeoFields'
import type { ObjectServices } from './ObjectServices'
import {
  ObjectServicesFromJSON,
  ObjectServicesFromJSONTyped,
  ObjectServicesToJSON,
} from './ObjectServices'

/**
 *
 * @export
 * @interface ObjectDetailAdmin
 */
export interface ObjectDetailAdmin {
  /**
   *
   * @type {number}
   * @memberof ObjectDetailAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof ObjectDetailAdmin
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ObjectDetailAdmin
   */
  iconImg?: string | null
  /**
   *
   * @type {number}
   * @memberof ObjectDetailAdmin
   */
  readonly servicesAmount: number
  /**
   *
   * @type {boolean}
   * @memberof ObjectDetailAdmin
   */
  isVisible?: boolean | null
  /**
   *
   * @type {Array<AdditionalCategory>}
   * @memberof ObjectDetailAdmin
   */
  readonly additionalCategories: Array<AdditionalCategory>
  /**
   *
   * @type {string}
   * @memberof ObjectDetailAdmin
   */
  slug?: string | null
  /**
   *
   * @type {ObjectSeoFields}
   * @memberof ObjectDetailAdmin
   */
  readonly seoData: ObjectSeoFields | null
  /**
   *
   * @type {ObjectCategory}
   * @memberof ObjectDetailAdmin
   */
  readonly category: ObjectCategory
  /**
   *
   * @type {Array<ObjectServices>}
   * @memberof ObjectDetailAdmin
   */
  readonly services: Array<ObjectServices>
  /**
   * * `global` - Global
   * * `local` - Local
   * * `both` - Both
   * @type {string}
   * @memberof ObjectDetailAdmin
   */
  faqUsageType?: ObjectDetailAdminFaqUsageTypeEnum
}

/**
 * @export
 */
export const ObjectDetailAdminFaqUsageTypeEnum = {
  Global: 'global',
  Local: 'local',
  Both: 'both',
} as const
export type ObjectDetailAdminFaqUsageTypeEnum =
  (typeof ObjectDetailAdminFaqUsageTypeEnum)[keyof typeof ObjectDetailAdminFaqUsageTypeEnum]

/**
 * Check if a given object implements the ObjectDetailAdmin interface.
 */
export function instanceOfObjectDetailAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'servicesAmount' in value
  isInstance = isInstance && 'additionalCategories' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'category' in value
  isInstance = isInstance && 'services' in value

  return isInstance
}

export function ObjectDetailAdminFromJSON(json: any): ObjectDetailAdmin {
  return ObjectDetailAdminFromJSONTyped(json, false)
}

export function ObjectDetailAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ObjectDetailAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    iconImg: !exists(json, 'iconImg') ? undefined : json['iconImg'],
    servicesAmount: json['servicesAmount'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    additionalCategories: (json['additionalCategories'] as Array<any>).map(
      AdditionalCategoryFromJSON,
    ),
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    seoData: ObjectSeoFieldsFromJSON(json['seoData']),
    category: ObjectCategoryFromJSON(json['category']),
    services: (json['services'] as Array<any>).map(ObjectServicesFromJSON),
    faqUsageType: !exists(json, 'faqUsageType') ? undefined : json['faqUsageType'],
  }
}

export function ObjectDetailAdminToJSON(value?: ObjectDetailAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    iconImg: value.iconImg,
    isVisible: value.isVisible,
    slug: value.slug,
    faqUsageType: value.faqUsageType,
  }
}
