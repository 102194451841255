/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CategoryLocationSeoDataNested } from './CategoryLocationSeoDataNested'
import {
  CategoryLocationSeoDataNestedFromJSON,
  CategoryLocationSeoDataNestedFromJSONTyped,
  CategoryLocationSeoDataNestedToJSON,
} from './CategoryLocationSeoDataNested'
import type { CategoryObject } from './CategoryObject'
import {
  CategoryObjectFromJSON,
  CategoryObjectFromJSONTyped,
  CategoryObjectToJSON,
} from './CategoryObject'
import type { CategorySeoFields } from './CategorySeoFields'
import {
  CategorySeoFieldsFromJSON,
  CategorySeoFieldsFromJSONTyped,
  CategorySeoFieldsToJSON,
} from './CategorySeoFields'
import type { Content } from './Content'
import { ContentFromJSON, ContentFromJSONTyped, ContentToJSON } from './Content'
import type { FAQ } from './FAQ'
import { FAQFromJSON, FAQFromJSONTyped, FAQToJSON } from './FAQ'

/**
 *
 * @export
 * @interface CategoryDetailUser
 */
export interface CategoryDetailUser {
  /**
   *
   * @type {number}
   * @memberof CategoryDetailUser
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof CategoryDetailUser
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CategoryDetailUser
   */
  readonly pageType: string
  /**
   *
   * @type {boolean}
   * @memberof CategoryDetailUser
   */
  isVisible?: boolean | null
  /**
   *
   * @type {string}
   * @memberof CategoryDetailUser
   */
  slug?: string | null
  /**
   *
   * @type {CategorySeoFields}
   * @memberof CategoryDetailUser
   */
  readonly seoData: CategorySeoFields | null
  /**
   *
   * @type {CategoryLocationSeoDataNested}
   * @memberof CategoryDetailUser
   */
  readonly locationSeoData: CategoryLocationSeoDataNested | null
  /**
   *
   * @type {boolean}
   * @memberof CategoryDetailUser
   */
  readonly hasLocations: boolean | null
  /**
   *
   * @type {Array<CategoryObject>}
   * @memberof CategoryDetailUser
   */
  readonly objects: Array<CategoryObject>
  /**
   *
   * @type {Array<Content>}
   * @memberof CategoryDetailUser
   */
  readonly content: Array<Content> | null
  /**
   *
   * @type {Array<FAQ>}
   * @memberof CategoryDetailUser
   */
  readonly faq: Array<FAQ> | null
}

/**
 * Check if a given object implements the CategoryDetailUser interface.
 */
export function instanceOfCategoryDetailUser(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'pageType' in value
  isInstance = isInstance && 'seoData' in value
  isInstance = isInstance && 'locationSeoData' in value
  isInstance = isInstance && 'hasLocations' in value
  isInstance = isInstance && 'objects' in value
  isInstance = isInstance && 'content' in value
  isInstance = isInstance && 'faq' in value

  return isInstance
}

export function CategoryDetailUserFromJSON(json: any): CategoryDetailUser {
  return CategoryDetailUserFromJSONTyped(json, false)
}

export function CategoryDetailUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategoryDetailUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    pageType: json['pageType'],
    isVisible: !exists(json, 'isVisible') ? undefined : json['isVisible'],
    slug: !exists(json, 'slug') ? undefined : json['slug'],
    seoData: CategorySeoFieldsFromJSON(json['seoData']),
    locationSeoData: CategoryLocationSeoDataNestedFromJSON(json['locationSeoData']),
    hasLocations: json['hasLocations'],
    objects: (json['objects'] as Array<any>).map(CategoryObjectFromJSON),
    content:
      json['content'] === null ? null : (json['content'] as Array<any>).map(ContentFromJSON),
    faq: json['faq'] === null ? null : (json['faq'] as Array<any>).map(FAQFromJSON),
  }
}

export function CategoryDetailUserToJSON(value?: CategoryDetailUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    isVisible: value.isVisible,
    slug: value.slug,
  }
}
