/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LocationAdminSerializerInner } from './LocationAdminSerializerInner'
import {
  LocationAdminSerializerInnerFromJSON,
  LocationAdminSerializerInnerFromJSONTyped,
  LocationAdminSerializerInnerToJSON,
} from './LocationAdminSerializerInner'
import type { LocationTag } from './LocationTag'
import { LocationTagFromJSON, LocationTagFromJSONTyped, LocationTagToJSON } from './LocationTag'

/**
 *
 * @export
 * @interface LocationListAdmin
 */
export interface LocationListAdmin {
  /**
   *
   * @type {number}
   * @memberof LocationListAdmin
   */
  readonly id: number
  /**
   *
   * @type {string}
   * @memberof LocationListAdmin
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LocationListAdmin
   */
  declination?: string
  /**
   *
   * @type {LocationAdminSerializerInner}
   * @memberof LocationListAdmin
   */
  readonly region: LocationAdminSerializerInner
  /**
   *
   * @type {LocationTag}
   * @memberof LocationListAdmin
   */
  readonly tag: LocationTag
}

/**
 * Check if a given object implements the LocationListAdmin interface.
 */
export function instanceOfLocationListAdmin(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'region' in value
  isInstance = isInstance && 'tag' in value

  return isInstance
}

export function LocationListAdminFromJSON(json: any): LocationListAdmin {
  return LocationListAdminFromJSONTyped(json, false)
}

export function LocationListAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LocationListAdmin {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    declination: !exists(json, 'declination') ? undefined : json['declination'],
    region: LocationAdminSerializerInnerFromJSON(json['region']),
    tag: LocationTagFromJSON(json['tag']),
  }
}

export function LocationListAdminToJSON(value?: LocationListAdmin | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    declination: value.declination,
  }
}
