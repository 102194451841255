/* tslint:disable */
/* eslint-disable */
/**
 * Remaster API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Category } from './Category'
import { CategoryFromJSON, CategoryFromJSONTyped, CategoryToJSON } from './Category'
import type { ModelObject } from './ModelObject'
import { ModelObjectFromJSON, ModelObjectFromJSONTyped, ModelObjectToJSON } from './ModelObject'
import type { Service } from './Service'
import { ServiceFromJSON, ServiceFromJSONTyped, ServiceToJSON } from './Service'

/**
 *
 * @export
 * @interface SearchCatalog
 */
export interface SearchCatalog {
  /**
   *
   * @type {Array<Category>}
   * @memberof SearchCatalog
   */
  categories: Array<Category>
  /**
   *
   * @type {Array<ModelObject>}
   * @memberof SearchCatalog
   */
  objects: Array<ModelObject>
  /**
   *
   * @type {Array<Service>}
   * @memberof SearchCatalog
   */
  services: Array<Service>
}

/**
 * Check if a given object implements the SearchCatalog interface.
 */
export function instanceOfSearchCatalog(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'categories' in value
  isInstance = isInstance && 'objects' in value
  isInstance = isInstance && 'services' in value

  return isInstance
}

export function SearchCatalogFromJSON(json: any): SearchCatalog {
  return SearchCatalogFromJSONTyped(json, false)
}

export function SearchCatalogFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchCatalog {
  if (json === undefined || json === null) {
    return json
  }
  return {
    categories: (json['categories'] as Array<any>).map(CategoryFromJSON),
    objects: (json['objects'] as Array<any>).map(ModelObjectFromJSON),
    services: (json['services'] as Array<any>).map(ServiceFromJSON),
  }
}

export function SearchCatalogToJSON(value?: SearchCatalog | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    categories: (value.categories as Array<any>).map(CategoryToJSON),
    objects: (value.objects as Array<any>).map(ModelObjectToJSON),
    services: (value.services as Array<any>).map(ServiceToJSON),
  }
}
